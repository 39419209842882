import React from "react"
import { observer } from "mobx-react"
import { ServiceDocumentCellProps } from "./types"
import ServiceDocumentsTooltip from "../ServiceDocumentsTooltip"
import SquareFileBillet from "../../../../../../../../components/common/SquareFileBillet"

const ServiceDocumentCell = ({ service }: ServiceDocumentCellProps) => {
  if (service.invoice && service.paymentOrder)
    return <ServiceDocumentsTooltip service={service} />

  if (!service.invoice && !service.paymentOrder) return <span>-</span>

  const name = service.invoice?.name || service.paymentOrder?.name
  const link = service.invoice?.url || service.paymentOrder?.url

  const handleDelete = () => {
    if (service.invoice) service.deleteDocument("invoice")
    service.deleteDocument("paymentOrder")
  }

  return <SquareFileBillet name={name!} link={link!} onDelete={handleDelete} />
}

export default observer(ServiceDocumentCell)
