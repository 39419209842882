import { ResourceTypes } from "kui-crm/types"
import requests from "./index"
import { PostMeterRequest, PutMeterRequest } from "../types/api/meters"

const MeterAgent = {
  getById: (id: number, mater: ResourceTypes) =>
    requests.get(`/meters/${mater}/${id}`),
  getHistoryById: (id: number, mater: ResourceTypes) =>
    requests.get(`/meters/${mater}/${id}/history`),
  patch: (
    id: number,
    data: Partial<PostMeterRequest>,
    mater: ResourceTypes,
    year: number,
    month: number
  ) =>
    requests.patch(`/meters/${mater}/${id}?year=${year}&month=${month}`, data),
  putValue: (id: number, data: PutMeterRequest, mater: ResourceTypes) =>
    requests.post(`/meters/${mater}/${id}/values`, data),
  delete: (id: number, mater: ResourceTypes) =>
    requests.delete(`/meters/${mater}/${id}`),
  getDocuments: (id: number, mater: ResourceTypes) =>
    requests.get(`/meters/${mater}/${id}/documents`),
  postDocument: (data: FormData, id: number, mater: ResourceTypes) =>
    requests.post(`/meters/${mater}/${id}/documents`, data),
  patchDocument: (
    id: number,
    data: FormData,
    tariffId: number,
    mater: ResourceTypes
  ) => requests.patch(`/meters/${mater}/${tariffId}/documents/${id}`, data),
  deleteDocument: (id: number, tariffId: number, mater: ResourceTypes) =>
    requests.delete(`/meters/${mater}/${tariffId}/documents/${id}`),
}

export default MeterAgent
