export const MeterTypeLabel = {
  T1: "One-part",
  T2: "Two-part",
  T3: "Three-part",
  cold: "Cold",
  hot: "Hot",
}

export const PaymentStatusLabel = {
  paid: "Yes",
  unpaid: "No",
  overdue: "Overdue",
}

export const InspectionEditConditionReasons = {
  already_signed: "Inspection signed and accepted",
  invoice_already_fixed_for_impairments:
    "An invoice with this expense has already been created",
}

export const meterUnitLabel = {
  water: "м3",
  gas: "м3",
  heating: "Гкал",
  electricity: "кВт",
}

export const checkDataLabel = {
  phone: "Phone number",
  passport: "Passport data",
}

export const closingConditionLabel = {
  have_closing_transfer_inspection: "Transfer inspection",
  have_closing_inspection_date_eq_contract_end_date: "Date of termination",
  have_closing_impairments: "Impairments inspection",
  have_last_meters_filled: "Meters&expenses data",
  have_calculated_deposit: "Deposit calculation",
}
