import * as React from "react"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import { Modal } from "kui-crm"
import { CreateClientModalProps, ClientCreationFields } from "./types"
import { Routes } from "../../../types/route"
import MultistepForm from "../../common/MultistepForm"
import ClientTypeStep from "../../forms/client/ClientTypeStep"
import ClientInfoStep from "../../forms/client/ClientInfoStep"
import ClientDocumentsStep from "../../forms/client/ClientDocumentsStep"
import { ClientDocumentsStepFields } from "../../forms/client/ClientDocumentsStep/types"

function ClientCreationModal(props: CreateClientModalProps) {
  const { open, handleClose, withRoute, handleSubmit, creationForm } = props
  const navigate = useNavigate()

  const handleFormSubmit = async (data: ClientDocumentsStepFields) => {
    handleClose()
    const clientId = await handleSubmit({
      ...creationForm.fields,
      ...data,
    } as ClientCreationFields)
    if (clientId && withRoute) {
      navigate(`${Routes.clients}/${clientId}`)
    }
    creationForm.resetForm()
  }

  return (
    <Modal title="New client" open={open} handleClose={handleClose}>
      <MultistepForm form={creationForm}>
        <ClientTypeStep formStore={creationForm} />
        <ClientInfoStep formStore={creationForm} />
        <ClientDocumentsStep
          formStore={creationForm}
          handleSubmit={handleFormSubmit}
        />
      </MultistepForm>
    </Modal>
  )
}

ClientCreationModal.defaultProps = {
  withRoute: true,
}

export default observer(ClientCreationModal)
