import React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { StatusBilletProps, StatusStylesProps } from "./types"
import { StatusBilletVariants } from "./variants"

function StatusBillet({ label, variant, ...otherProps }: StatusBilletProps) {
  return (
    <StyledWrapper variant={StatusBilletVariants[variant]} {...otherProps}>
      <Caption size="xs" weight={600}>
        {label.toUpperCase()}
      </Caption>
    </StyledWrapper>
  )
}

export default StatusBillet

const StyledWrapper = styled.span<StatusStylesProps>`
  border-radius: 8px;
  padding: 5px 16px;
  background: ${({ variant }) => variant.background};
  display: inline-block;
  p {
    color: ${({ variant }) => variant.color};
    white-space: nowrap;
  }
`
