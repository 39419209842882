import * as React from "react"
import { observer } from "mobx-react"
import RCApartmentForm from "../RCApartmentForm"
import useRCsStore from "../../store"
import MultistepForm from "../../../../components/common/MultistepForm"
import { RentalContractTermsFields } from "./types"
import RCInfoForm from "../RCInfoForm"
import RCTermsForm from "../RCTermsForm"

function CreateRentalContractForm({ handleSubmit }: any) {
  const { creationForm } = useRCsStore()
  const { updateFormFields, fields, resetForm } = creationForm

  const formSubmit = (data: RentalContractTermsFields) => {
    updateFormFields(data)
    handleSubmit({ ...fields, ...data })
    resetForm()
  }

  return (
    <MultistepForm form={creationForm}>
      <RCApartmentForm />
      <RCInfoForm />
      <RCTermsForm handleSubmit={formSubmit} />
    </MultistepForm>
  )
}

export default observer(CreateRentalContractForm)
