import React, { useState } from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import ApartmentPhotoModal from "../ApartmentPhotoModal"
import ApartmentPhotoDragCard from "../ApartmentPhotoDragCard"
import ApartmentPhotoSlider from "../ApartmentPhotoSlider"
import ApartmentImageStore from "../../../../../store/content/photo/ApartmentImage"
import PhotoContainer from "../../../../../../../../../components/common/PhotoContainer"
import usePromotionStore from "../../../../../store"

function ApartmentPublicPhoto() {
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()
  const [selectedImage, setSelectedImage] = useState(0)
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle(false)
  const [isSliderOpen, handleSliderOpen, handleSliderClose] = useToggle()

  const handleImageClick = (index: number) => {
    setSelectedImage(Number(index))
    handleSliderOpen()
  }

  const handleImageDelete = (image: ApartmentImageStore) => {
    image.makeNotPublic()
  }

  return (
    <>
      <PhotoContainer label="Public photo" handleClick={handleModalOpen}>
        <Grid container spacing={3}>
          {imagesStore.publicImages.map((image, i: number) => (
            <ApartmentPhotoDragCard
              key={image.id}
              image={image}
              index={i}
              onClick={handleImageClick}
              onDelete={handleImageDelete}
            />
          ))}
        </Grid>
      </PhotoContainer>
      {isSliderOpen && (
        <ApartmentPhotoSlider
          active={selectedImage}
          handleClose={handleSliderClose}
          isOpen={isSliderOpen}
          images={imagesStore.publicImages}
        />
      )}
      <ApartmentPhotoModal
        variant="public"
        open={isModalOpen}
        handleClose={handleModalClose}
        images={imagesStore.nonPublicImages ?? []}
      />
    </>
  )
}

export default observer(ApartmentPublicPhoto)
