import React from "react"
import { observer } from "mobx-react"
import ApartmentInspectionsInfo from "./components/ApartmentInspectionsInfo"
import InspectionPage from "../../../pages/InspectionPage"
import useQueryParam from "../../../../../hooks/useQueryParam"

function ApartmentInspectionsTab() {
  const displayInspection = useQueryParam("id")

  return displayInspection ? <InspectionPage /> : <ApartmentInspectionsInfo />
}

export default observer(ApartmentInspectionsTab)
