import React, { SyntheticEvent, useRef } from "react"
import { Button, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { QRIcon } from "kui-icon"
import useCompanyStore from "../../../store"
import { CompanyParseQRButtonProps } from "./types"
import CompanyBankStore from "../../../store/CompanyBankStore"

const CompanyParseQRButton = (props: CompanyParseQRButtonProps) => {
  const { form, disabled } = props
  const { bankInfoStore } = useCompanyStore()
  const inputRef = useRef<HTMLInputElement>(null)

  const updateForm = (bank: CompanyBankStore) => {
    form.setValue("BIC", bank.BIC)
    form.setValue("bankName", bank.bankName)
    form.setValue("account", bank.account)
    form.setValue("correspondentAccount", bank.correspondentAccount)
    form.setValue("SWIFT", bank.SWIFT)
  }

  const handleClick = () => {
    inputRef.current?.click()
  }

  const handleChange = async (e: SyntheticEvent<HTMLInputElement>) => {
    const fileUploaded = (e.target as HTMLInputElement).files?.[0]
    if (fileUploaded) {
      const bankInfo = await bankInfoStore.parseCompanyRequisites(fileUploaded)
      if (bankInfo) updateForm(bankInfo)
    }
  }

  return (
    <Grid container>
      <StyledInput type="file" onChange={handleChange} ref={inputRef} />
      <StyledButton
        isCircle
        type="button"
        variant="whiteWithGray"
        onClick={handleClick}
        disabled={disabled || bankInfoStore.actionLoader.isLoading}
      >
        <QRIcon width={26} height={26} />
      </StyledButton>
    </Grid>
  )
}

export default observer(CompanyParseQRButton)

const StyledInput = styled.input`
  display: none;
`

const StyledButton = styled(Button)`
  width: 56px;
  height: 56px;
  border-radius: 8px;
`
