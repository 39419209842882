import React, { useState } from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import { LoaderState } from "kui-crm"
import MetersTable from "../MetersTable"
import ComplexMeterRow from "../ComplexMeterRow"
import EditHeatingMeterModal from "../EditHeatingMeterModal"
import CreatingHeatingMeterModal from "../CreatingHeatingMeterModal"
import useExpensesStore from "../../../../store"

function ApartmentHeatingMetersTable() {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { metersStore, periodStore } = useExpensesStore()
  const { heatingMetersStore } = metersStore
  const heatingMeters = heatingMetersStore?.activeHeatingMeters
  const disabled = !periodStore.canBeEdited

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleEditModalOpen = async (counterId: number) =>
    heatingMetersStore?.getHeatingMeterById(counterId)

  return (
    <>
      <Box mb={3}>
        <MetersTable
          handleAdd={handleOpenModal}
          title="Heating"
          metersCount={heatingMeters?.length}
          maxMetersCount={heatingMetersStore?.maxCountersCount}
          total={heatingMetersStore?.total}
        >
          {heatingMeters?.map((counter) => (
            <ComplexMeterRow
              counter={counter}
              tariff={counter.tariffValues}
              disabled={disabled}
              onModalOpen={handleEditModalOpen}
              ModalComponent={EditHeatingMeterModal}
            />
          ))}
        </MetersTable>
      </Box>
      <CreatingHeatingMeterModal
        open={isOpenModal}
        handleClose={handleCloseModal}
      />

      <LoaderState loader={heatingMetersStore.loader} onlyError />
      <LoaderState loader={heatingMetersStore.actionLoader} />
    </>
  )
}

export default observer(ApartmentHeatingMetersTable)
