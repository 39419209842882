import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import _ from "lodash"
import {
  ApartmentLiteModel,
  RentalStatuses,
  RentalTypes,
  ResourceTypes,
  PaymentStatuses,
} from "kui-crm/types"
import {
  matchesApartmentStatuses,
  matchesAPIPeriodStatuses,
} from "../../../utils/content/matches"
import { LatLngParams } from "../../../components/common/MapOverlay/types"
import UserLiteStore from "../../../store/templates/UserLite"
import { MaxCountMetersParams } from "../../../types/store/apartments"

class ApartmentStore {
  id: number

  folderNumber: string

  isServicesApproved: boolean

  tenant: UserLiteStore | null

  landlord: UserLiteStore | null

  address: string

  apartmentNumber: string | number

  rentalStatus: RentalStatuses

  rentalType: RentalTypes

  rentalCost?: number

  paymentStatus: PaymentStatuses

  previewImage: string | null

  serviceContractNumber: string | null

  serviceContractStartDate: DateTime | null

  rentalContractsCount: number

  isImported: boolean

  geolocation: LatLngParams | null

  roomsNumber: number

  resources: ResourceTypes[]

  maxMetersCount: MaxCountMetersParams

  periodStatus: string

  constructor(apartment: ApartmentLiteModel) {
    this.id = apartment.id
    this.folderNumber = apartment.folder_number
    this.address = apartment.object_name || apartment.address
    this.apartmentNumber = apartment.apartment_number || ""
    this.isServicesApproved = apartment.is_approved_service_expenses
    this.rentalStatus = apartment.rental_status
    this.rentalType = apartment.rental_type
    this.paymentStatus = apartment.renter_payment_status
    this.isImported = !!apartment.is_imported_from_old_crm
    this.previewImage =
      apartment.main_image?.image_small_url ||
      apartment.main_image?.image_url ||
      null
    this.serviceContractNumber = apartment.service_contract?.number ?? null
    this.serviceContractStartDate = apartment.service_contract?.start_date
      ? DateTime.fromISO(apartment.service_contract.start_date)
      : null
    this.rentalContractsCount = Number(apartment.rental_contracts_count)
    this.tenant = apartment.renter
      ? UserLiteStore.initFromLiteUserModel(apartment.renter, "tenant")
      : null
    this.landlord = apartment.owner
      ? UserLiteStore.initFromLiteUserModel(apartment.owner, "landlord")
      : null
    this.geolocation = null
    this.roomsNumber = 0
    this.rentalCost = 0
    this.resources = []
    this.maxMetersCount = {
      ...(apartment.max_gas_counters && { gas: apartment.max_gas_counters }),
      ...(apartment.max_electricity_counters && {
        electricity: apartment.max_electricity_counters,
      }),
      ...(apartment.max_heating_counters && {
        heating: apartment.max_heating_counters,
      }),
      ...(apartment.max_water_counters && {
        water: apartment.max_water_counters,
      }),
    }
    this.periodStatus = apartment.period_status
      ? matchesAPIPeriodStatuses[apartment.period_status]
      : ""
    makeAutoObservable(this)
  }

  get status() {
    return matchesApartmentStatuses[this.rentalStatus] || ""
  }

  get formattedRentalType() {
    return this.rentalType ? _.capitalize(this.rentalType) : "-"
  }

  get servicesStatus() {
    if (typeof this.isServicesApproved === "boolean") {
      return this.isServicesApproved ? "Yes" : "No"
    }
    return ""
  }
}

export default ApartmentStore
