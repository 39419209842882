export const getPredictor = {
  area: 50,
  count_rooms: 2,
  id_metro: 2,
  dist_metro_walk: 15,
  time_metro_walk: 5,
  time_metro_car: 2,
  dist_metro_center: 30,
  first_floor: false,
  last_floor: false,
  floor_count: 5,
  amount_of_commission: 50,
  built_year: 2001,
  id_floor_type: 1,
  id_wall_material: 1,
  id_gas_type: 1,
}

export const predictorReject = {
  non_field_errors: ["Failed to create user."],
}
