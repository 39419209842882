import { InputFile } from "kui-complex"
import { InputParams } from "../../../../../../common/InputsGroupWithTitle/types"
import { ComparableObjectFormFields } from "./types"

const comparableObjectFields: InputParams<ComparableObjectFormFields>[] = [
  { label: "Link to advertisement", name: "link" },
  {
    label: "Advertisement screenshot",
    name: "screenshot",
    variant: "custom",
    CustomInput: InputFile,
  },
  {
    label: "Object price",
    name: "price",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
  },
  { label: "Key object differences", name: "differences", isTextArea: true },
]

export default comparableObjectFields
