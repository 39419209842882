import React from "react"
import { addToArrayByCondition } from "kui-utils"
import { CheckboxWithController } from "kui-complex"
import { Caption } from "kui-basic"

const getClosingPeriodFields = (
  withoutLandlordPayOut: boolean,
  withoutTenantPayIn: boolean,
  isLastPeriod: boolean
) => [
  ...addToArrayByCondition(withoutLandlordPayOut, {
    variant: "custom",
    CustomInput: CheckboxWithController,
    label: (
      <Caption size="s" weight={500}>
        Without payments to landlord
      </Caption>
    ),
    name: "withoutLandlordPayOut",
  }),
  ...addToArrayByCondition(withoutTenantPayIn, {
    variant: "custom",
    CustomInput: CheckboxWithController,
    label: (
      <Caption size="s" weight={500}>
        Without payments from tenant
      </Caption>
    ),
    name: "withoutTenantPayIn",
  }),
  {
    variant: "file",
    label: "Payment order",
    name: "paymentOrder",
    isRequired: !isLastPeriod && !withoutLandlordPayOut,
  },
]

export default getClosingPeriodFields
