export const matchesAPISubjectRoles = {
  maroom: "maroom",
  tenant: "renter",
  landlord: "owner",
}

export const matchesSubjectRoles = {
  maroom: "maroom",
  renter: "tenant",
  tenant: "tenant",
  owner: "landlord",
}

export const matchesApartmentStatuses = {
  "Rented out": "Rented",
  Closed: "Closed",
  Open: "Free",
  New: "New",
  "Free soon": "Free soon",
}

export const matchesAPICompanyTypes = {
  administrative_provider: "administrative",
  service_provider: "service",
  resource_provider: "resource",
}

export const matchesCompanyTypes = {
  administrative: "administrative_provider",
  service: "service_provider",
  resource: "resource_provider",
}

export const matchesFillingTypes = {
  finish: "finish",
  furniture: "furniture",
  electronics: "appliance",
  accessories: "accessorie",
}

export const matchesAPIFillingTypes = {
  finish: "finish",
  furniture: "furniture",
  appliance: "electronics",
  accessorie: "accessories",
}

export const matchesVisibilityVariants = {
  everybody: "everybody",
  renter: "tenant",
  owner: "landlord",
  nobody: "nobody",
}

export const matchesAPIVisibilityVariants = {
  everybody: "everybody",
  tenant: "renter",
  landlord: "owner",
  nobody: "nobody",
}

export const genderMatches = {
  M: "male",
  F: "female",
}

export const matchesServiceTypes = {
  open_ended: "openEnded",
  period: "period",
  once: "once",
  operating: "own",
  auto: "auto",
  pending: "pending",
}

export const matchesDocumentsStatuses = {
  active: "active",
  expires: "expire_soon",
  expired: "expired",
  archived: "archived",
}

export const matchesAPIDocumentsStatuses = {
  active: "active",
  expire_soon: "expires",
  expired: "expired",
  archived: "archived",
}

export const matchesAPIPeriodStatuses = {
  new: "Open",
  closed: "Closed",
  overdue: "Overdue",
}
