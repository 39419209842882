import * as React from "react"
import { observer } from "mobx-react"
import { useEffect } from "react"
import useExpensesStore from "../../../store"
import ExpensesMetersTables from "./ExpensesMetersTables"

function ExpensesMetersTab() {
  const { metersStore, date, overviewStore, selectedContract, periodStore } =
    useExpensesStore()

  useEffect(() => {
    if (overviewStore.id && date) {
      metersStore.initCountersPage(
        overviewStore.id,
        date!,
        selectedContract?.id
      )
    } else {
      metersStore.cleanMetersTab()
    }
    return () => {
      metersStore.loader.startLoading()
      periodStore.cleanPeriodId()
    }
  }, [date, selectedContract])

  return <ExpensesMetersTables />
}

export default observer(ExpensesMetersTab)
