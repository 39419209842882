import {
  CountryAutocomplete,
  RegionAutocomplete,
  CityAutocomplete,
} from "kui-crm"
import { InputParams } from "../../../common/InputsGroupWithTitle/types"
import { NewApartmentFormParams } from "./types"
import InputWithAddressAutocomplete from "../../../ui/InputWithAddressAutocomplete"
import ClientAutocomplete from "../../../entityInputs/ClientAutocomplete"

const getApartmentFields = (
  handlePlaceSelect: (coords: google.maps.LatLngLiteral) => void
): InputParams<NewApartmentFormParams>[] => [
  {
    name: "country",
    label: "Country",
    variant: "custom",
    CustomInput: CountryAutocomplete,
    size: 12,
  },
  {
    name: "region",
    label: "Region",
    variant: "custom",
    CustomInput: RegionAutocomplete,
  },
  {
    name: "city",
    label: "City",
    variant: "custom",
    CustomInput: CityAutocomplete,
  },
  {
    name: "apartment",
    variant: "custom",
    CustomInput: InputWithAddressAutocomplete,
    handlePlaceSelect,
    size: 12,
    placeholder: "Street name, house number",
    isRequired: true,
  },
  {
    label: "Apartment",
    name: "apartmentNumber",
    "data-testid": "apartment_number",
  },
  {
    label: "Zip code",
    name: "zipCode",
  },
  {
    label: "Floor",
    name: "floor",
    "data-testid": "floor",
    type: "number",
    step: 1,
  },
  {
    label: "Folder number",
    name: "folderNumber",
  },
  {
    label: "Owner",
    name: "owner",
    "data-testid": "user",
    variant: "custom",
    CustomInput: ClientAutocomplete,
    size: 12,
    isRequired: true,
  },
]

export default getApartmentFields
