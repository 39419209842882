import { CompanyBankInfoFormFields } from "../../../../forms/CompanyBanksInfoForm/types"
import { FieldGroupsParams } from "../../../../../../components/common/UpdatedFieldsGroup/types"
import CompanyParseQRButton from "../../../common/CompanyParseQRButton"

const getBankInfoFields = (
  index: number
): FieldGroupsParams<CompanyBankInfoFormFields> => {
  const prefix = `banks.${index}.`

  return {
    title: "Bank account",
    modalProps: {
      updatedTitle: "Change a company bank acc",
      addingTitle: "Add a company bank acc",
    },
    customReasons: [{ label: "Copy" }],
    fields: [
      {
        label: "Name",
        name: `${prefix}name`,
        size: 6,
        withGlobalEditor: true,
      },
      {
        name: `${prefix}parseQR`,
        variant: "custom",
        CustomInput: CompanyParseQRButton,
        size: 6,
        withGlobalEditor: true,
      },
      {
        label: "Coresspondent account",
        name: `${prefix}correspondentAccount`,
        placeholder: "XXXXXXXXXXXXXXXXXXXX",
        size: 6,
      },
      {
        label: "Account",
        name: `${prefix}account`,
        placeholder: "XXXXXXXXXXXXXXXXXXXX",
        size: 6,
      },
      {
        label: "Bank name",
        name: `${prefix}bankName`,
        size: 4,
      },
      {
        label: "SWIFT",
        name: `${prefix}SWIFT`,
        placeholder: "XXXXXXXX",
        size: 4,
      },
      {
        label: "Bank Identification Code",
        name: `${prefix}BIC`,
        placeholder: "XXXXXXXXX",
        size: 4,
      },
    ],
  }
}

export default getBankInfoFields
