const filterItemsByQuery = (
  items: Array<any>,
  param: string,
  keys: Array<any>
) =>
  items.filter((item) => {
    for (let i = 0; i < keys.length; i += 1) {
      if (
        item[keys[i]] &&
        String(item[keys[i]]).toLowerCase().includes(param.toLowerCase())
      ) {
        return true
      }
    }
    return false
  })

export default filterItemsByQuery
