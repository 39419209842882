import * as React from "react"
import { observer } from "mobx-react"
import { useState } from "react"
import ApartmentOverviewTab from "../../tabs/ApartmentOverviewTab"
import ApartmentExpenses from "../../../pages/ExpensesPage"
import ApartmentOwnershipTab from "../../tabs/ApartmentOwnershipTab"
import ApartmentDescriptionTab from "../../tabs/ApartmentDescriptionTab"
import ApartmentDocumentsTab from "../../tabs/ApartmentDocumentsTab"
import ApartmentInspectionsTab from "../../tabs/ApartmentInspectionsTab"
import useTabIndex from "../../../../../hooks/useTabIndex"
import useApartmentStore from "../../../store"
import ApartmentPromotion from "../../../pages/PromotionPage"
import ApartmentTabsSettings from "../ApartmentTabsSettings"
import PageTabsPanel from "../../../../../components/ui/PageTabsPanel"

const tabs = [
  "Overview",
  "Ownership",
  "Inspections",
  "Descriptions",
  "Expenses",
  "Promotion",
  "Documents",
]

const paths = [
  `?tab=overview`,
  `?tab=ownership`,
  `?tab=inspections`,
  `?tab=descriptions`,
  `?tab=expenses`,
  `?tab=promotion`,
  `?tab=documents`,
]

const tabPanels = [
  <ApartmentOverviewTab />,
  <ApartmentOwnershipTab />,
  <ApartmentInspectionsTab />,
  <ApartmentDescriptionTab />,
  <ApartmentExpenses />,
  <ApartmentPromotion />,
  <ApartmentDocumentsTab />,
]

function ApartmentTabsPanel() {
  const { editor } = useApartmentStore()
  const activeTab = useTabIndex(paths)
  const [currentTab, setCurrentTab] = useState(activeTab)

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTab(tabIndex)
    editor.endEditing()
  }

  return (
    <PageTabsPanel
      activeTab={Number(activeTab)}
      paths={paths}
      tabs={tabs}
      tabPanels={tabPanels}
      onChange={handleTabChange}
      endComponent={<ApartmentTabsSettings activeTab={currentTab} />}
    />
  )
}

export default observer(ApartmentTabsPanel)
