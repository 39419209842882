import React from "react"
import { BedIcon, DoorIcon, DropIcon } from "kui-icon"
import { InputParams } from "../../components/common/InputsGroupWithTitle/types"
import { ApartmentDescriptionFields } from "../../pages/ApartmentPage/forms/description/ApartmentDescriptionForm/types"
import {
  RenovationStyleValues,
  RenovationTypeValues,
  RoomTypeValues,
} from "../content/listsOptions"

const inputProps = {
  step: 0.1,
}

export const getApartmentDetailInfoFields = (
  roomsCount?: number | null,
  bedroomsCount?: number | null
): InputParams<ApartmentDescriptionFields>[] => [
  {
    name: "area",
    label: "Total area",
    size: 2,
    type: "number",
    cornerLabel: "sq.m.",
    ...inputProps,
  },
  {
    name: "livingArea",
    label: "Living area",
    size: 2,
    type: "number",
    cornerLabel: "sq.m.",
    ...inputProps,
  },
  {
    name: "kitchenArea",
    label: "Kitchen area",
    size: 2,
    type: "number",
    cornerLabel: "sq.m.",
    ...inputProps,
  },
  {
    name: "roomsNumber",
    label: "Rooms",
    size: 2,
    type: "number",
    endIcon: <DoorIcon />,
  },
  {
    name: "bedroomsNumber",
    label: "Bedrooms",
    size: 2,
    type: "number",
    endIcon: <BedIcon />,
  },
  {
    name: "bathroomsNumber",
    label: "Bathrooms",
    size: 2,
    type: "number",
    endIcon: <DropIcon />,
  },
  {
    name: "restroomsNumber",
    label: "Toilets",
    size: 2,
    type: "number",
    endIcon: <DropIcon />,
  },
  {
    name: "combinedBathroomsNumber",
    label: "Combined bathroom",
    size: 2,
    type: "number",
    endIcon: <DropIcon />,
  },
  ...(roomsCount
    ? new Array(Number(roomsCount))
        .fill(0)
        .map((_, key) => {
          const label =
            bedroomsCount && key < bedroomsCount
              ? `Bedroom ${key + 1}`
              : `Room ${key + 1}`

          return [
            {
              name: `rooms.${key}.area`,
              label: `${label} area`,
              size: 2,
              type: "number",
              cornerLabel: "sq.m.",
              ...inputProps,
            },
            {
              name: `rooms.${key}.type`,
              label: `${label} type`,
              size: 2,
              variant: "select",
              options: RoomTypeValues,
            },
          ]
        })
        .flat()
    : []),
  {
    name: "ceilingHeight",
    label: "Ceiling height",
    size: 2,
    type: "number",
    cornerLabel: "m.",
    ...inputProps,
  },
  { name: "numberOfWindows", label: "Windows", size: 2, type: "number" },
  {
    name: "renovation.year",
    label: "Year of last renovation",
    size: 2,
    type: "number",
  },
  {
    name: "renovation.type",
    label: "Type of renovation",
    size: 2,
    variant: "select",
    options: RenovationTypeValues,
  },
  {
    name: "renovation.style",
    label: "Renovation style",
    size: 2,
    variant: "select",
    options: RenovationStyleValues,
  },
]
