import { DependencyList, useEffect } from "react"
import { Paginator, SortingFilter, useDebounce } from "kui-utils"
import useScrollWithBottomSpacing from "./useScrollWithBottomSpacing"
import useFetchPage from "./useFetchPage"

const usePaginationPage = (
  paginator?: Paginator,
  fetchAll?: () => Promise<any>,
  filter?: SortingFilter | null,
  deps?: DependencyList
) => {
  useFetchPage(paginator, fetchAll, deps)

  useDebounce(filter?.searchQuery || "", 500, () => {
    if (filter) {
      paginator?.clearOffset()
      paginator?.startFetching()
    }
  })

  useScrollWithBottomSpacing(
    10,
    200,
    () => {
      paginator?.startFetching()
    },
    null,
    [paginator, ...(deps || [])]
  )

  useEffect(
    () => () => {
      paginator?.clearOffset()
      filter?.clearFilter()
    },
    []
  )
}

export default usePaginationPage
