import { rest } from "msw"
import { RentalContractModel } from "kui-crm/types"
import {
  FetchRentalContracts,
  PatchRentalContractParams,
} from "../../types/api/rental_contract"
import { fetchListRentalContracts, rentalContract } from "./data"

const rentalContractsHandlers = [
  rest.get<FetchRentalContracts | Error>(
    `${process.env.REACT_APP_API_URL}/rental-contracts/`,
    (req, resp, ctx) => {
      const data = ctx.json(fetchListRentalContracts)
      return resp(data)
    }
  ),
  rest.get<RentalContractModel | Error>(
    `${process.env.REACT_APP_API_URL}/rental-contracts/:id`,
    (req, resp, ctx) => {
      const data = ctx.json(rentalContract)
      return resp(data)
    }
  ),
  rest.post<PatchRentalContractParams, RentalContractModel | Error>(
    `${process.env.REACT_APP_API_URL}/rental-contracts`,
    (req, resp, ctx) => {
      if (
        req.body.number &&
        req.body.sign_date &&
        req.body.start_date &&
        req.body.month_payment &&
        req.body.initial_commission &&
        req.body.termination_penalty
      ) {
        const data = ctx.json(rentalContract)
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
  rest.patch<PatchRentalContractParams, RentalContractModel | Error>(
    `${process.env.REACT_APP_API_URL}/rental-contracts/:id`,
    (req, resp, ctx) => {
      if (
        req.body.number &&
        req.body.sign_date &&
        req.body.start_date &&
        req.body.month_payment &&
        req.body.initial_commission &&
        req.body.termination_penalty
      ) {
        const data = ctx.json(rentalContract)
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
]

export default rentalContractsHandlers
