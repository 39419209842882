import React from "react"
import { useWatch } from "react-hook-form"
import { observer } from "mobx-react"
import { InitialInspectionInfoFieldsProps } from "./types"
import getInitialInspectionInfoFieldGroup from "./fields"
import MapWrapper from "../../../../../../../components/common/MapWrapper"
import InputsGroupWithTitle from "../../../../../../../components/common/InputsGroupWithTitle"
import useInspectionStore from "../../../store"
import Container from "../../../../../../../components/ui/Container"

const InitialInspectionInfoFields = ({
  form,
}: InitialInspectionInfoFieldsProps) => {
  const { editor } = useInspectionStore()
  const address = useWatch({
    control: form.control,
    name: "apartment.address",
  })
  const [roomsCount, bedroomsCount] = useWatch({
    control: form.control,
    name: ["roomsNumber", "bedroomsNumber"],
  })

  const initialInspectionFieldGroups = getInitialInspectionInfoFieldGroup(
    address,
    roomsCount,
    bedroomsCount
  )

  return (
    <Container>
      <MapWrapper>
        {initialInspectionFieldGroups.map((fieldGroup) => (
          <InputsGroupWithTitle
            key={fieldGroup.title}
            form={form}
            isEditing={editor.isEditing}
            {...fieldGroup}
          />
        ))}
      </MapWrapper>
    </Container>
  )
}

export default observer(InitialInspectionInfoFields)
