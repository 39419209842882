import React, { useState } from "react"
import { Accordion, AccordionSummary } from "kui-basic"
import styled from "@emotion/styled"
import ChangeLineSummary from "./ChangeLineSummary"
import { ChangeLineProps } from "./types"
import ChangeLineDetails from "./ChangeLineDetails"

const ChangeLine = (props: ChangeLineProps) => {
  const { changesGroup, variant, onDelete } = props
  const [expanded, setExpanded] = useState(variant === "pending")

  return (
    <StyledAccordion expanded={expanded} onChange={setExpanded}>
      <StyledSummary>
        <ChangeLineSummary
          variant={variant}
          expanded={expanded}
          changesGroup={changesGroup}
          onDelete={onDelete}
        />
      </StyledSummary>
      <ChangeLineDetails changesGroup={changesGroup} />
    </StyledAccordion>
  )
}

export default ChangeLine

const StyledSummary = styled(AccordionSummary)`
  .KUI-Accordion_Summary-Content {
    margin: 0;
  }
`

const StyledAccordion = styled(Accordion)`
  &:not(:last-of-type) {
    border-bottom: 1px solid
      ${({ theme, expanded }) =>
        expanded ? "transparent" : theme.palette.grey.fifteenB};
  }
`
