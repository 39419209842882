import { rest } from "msw"
import { PredictorParamsData } from "../../pages/ApartmentPage/types/api/apartmentPredictorAPI"
import { getPredictor } from "./data"

const predictorHandlers = [
  rest.get<PredictorParamsData | Error>(
    `${process.env.REACT_APP_API_URL_PREDICT}/internal_predictor?apartment_id=:id`,
    (req, resp, ctx) => {
      const data = ctx.json(getPredictor)
      return resp(data)
    }
  ),
  rest.patch<PredictorParamsData, PredictorParamsData | Error>(
    `${process.env.REACT_APP_API_URL_PREDICT}/predictor`,
    (req, resp, ctx) => {
      if (
        req.body.area &&
        req.body.count_rooms &&
        req.body.id_metro &&
        req.body.dist_metro_walk &&
        req.body.time_metro_walk &&
        req.body.time_metro_car &&
        req.body.dist_metro_center &&
        req.body.floor_count &&
        req.body.amount_of_commission &&
        req.body.built_year &&
        req.body.id_floor_type &&
        req.body.id_wall_material &&
        req.body.id_gas_type
      ) {
        const data = ctx.json(getPredictor)
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
]

export default predictorHandlers
