import { DateTime } from "luxon"
import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import _ from "lodash"
import { Loader, resHandler, toNumber } from "kui-utils"
import {
  RentalContractModel,
  ServiceContractModel,
  FileBodyRequest,
} from "kui-crm/types"
import { ClosingRentalContractFields, PartialPayerFields } from "kui-crm"
import ApartmentSimpleStore from "../../../store/templates/Apartment"
import UserLiteStore from "../../../store/templates/UserLite"
import { CheckParams, ContractPeriodTypes } from "../../../types/common"
import { clearNotValidFields } from "../../../utils/service/mapper"
import RentalContractAgent from "../../../agent/RentalContract"
import {
  CloseRentalContractRequest,
  DateOfLeavingModel,
  PatchRentalContractParams,
  PatchRentalContractResponse,
} from "../../../types/api/rental_contract"
import RentalContractsAgent from "../../../agent/RentalContracts"
import ServiceContractLiteStore from "../../../store/templates/ServiceContractLite"
import { ContractStatuses, RentalTypes } from "../../../types/store/contract"
import { RentalContractInfoValues } from "../types/store/rcInfoStore"
import { RCInfoFormFields } from "../components/RCInfoFields/types"
import { uploadNewFile } from "../../../utils/agent/uploadFiles"
import { getUtilitiesPayerParams } from "../../../utils/service/apiMapper"
import { CheckoutDateFormFields } from "../../../components/forms/contacts/ContractCheckoutDateForm/types"
import { ContractClosingFormFields } from "../../../components/forms/contacts/ContractClosingForm/types"

class RentalContractInfoStore {
  id: number | null = null

  apartment: ApartmentSimpleStore | null = null

  number: string = ""

  startDate: DateTime | null = null

  signDate: DateTime | null = null

  endDate: DateTime | null = null

  closeDate: DateTime | null = null

  landlord: UserLiteStore | null = null

  tenant: UserLiteStore | null = null

  type: RentalTypes | null = null

  initialCommission: number | null = null

  rentPrice: number | null = null

  securityPayment: number | null = null

  installmentPeriod: number | null = null

  paymentDay: number | null = null

  showingDays: number | null = null

  penalty: number | null = null

  autoProlongate: boolean = false

  contractPeriodType: ContractPeriodTypes | null = null

  isPending: boolean = false

  dateOfLeaving: DateTime | null = null

  serviceContract: ServiceContractLiteStore | null = null

  loader: Loader

  status: ContractStatuses | null = null

  contractInfoValues: RentalContractInfoValues

  inspectionIntervalMonth: number | null = null

  nextInspectionDate: DateTime | null = null

  allowablePriceDeviation: number | null = null

  petsAllowed: boolean = false

  childrenAllowed: boolean = false

  notifyPeriodMonth: number | null = null

  utilityPayers: PartialPayerFields[] = []

  closingProgress: CheckParams[] = []

  firstAppraisalPeriod: number | null = null

  constructor() {
    this.loader = new Loader()
    this.contractInfoValues = _.omitBy(
      this,
      _.isFunction
    ) as RentalContractInfoValues
    makeAutoObservable(this)
  }

  patchRentalContractInfo = async (data: RCInfoFormFields) => {
    const file = await uploadNewFile(this.loader, data.changesFile)
    const body = RentalContractInfoStore.getBodyForPatchContract(data, file)

    const [err, res] = await to<PatchRentalContractResponse>(
      RentalContractAgent.patch(this.id!, body)
    )
    if (!err && res) {
      this.updateRentalContract(res.changes)
    } else {
      this.loader.setError(`patch rental contract`, err)
    }
  }

  closeRentalContract = async (data: ContractClosingFormFields) => {
    this.loader.startLoading("closing contract")

    const file = await uploadNewFile(this.loader, data.terminationAgreement)

    const body = RentalContractInfoStore.getCloseContractBody(data, file)

    const response = await to<CloseRentalContractRequest>(
      RentalContractsAgent.close(this.id!, body)
    )

    resHandler(response, this.loader, (res) => {
      this.closeDate = res.end_date ? DateTime.fromISO(res.end_date) : null
      this.status = "Closed"
    })
  }

  saveDateOfLeavingApartment = async (data: CheckoutDateFormFields) => {
    this.loader.startLoading()
    const document = await uploadNewFile(this.loader, data.reason)

    const body = {
      document,
      planned_leave_date: data.checkoutDate?.toISODate() || "",
    }

    const [err, res] = await to<DateOfLeavingModel>(
      RentalContractsAgent.saveDateOfLeaving(this.id!, body)
    )

    runInAction(() => {
      if (!err && res) {
        this.dateOfLeaving = DateTime.fromISO(res.planned_leave_date)
      } else {
        this.loader.setError("save date of leaving")
      }
      this.loader.endLoading()
    })
  }

  updateRentalContract = (contract: Partial<RentalContractModel>) => {
    if (contract.id) this.id = contract.id
    if (contract.apartment)
      this.apartment = new ApartmentSimpleStore(contract.apartment)
    if (contract.number) this.number = contract.number
    if (contract.owner)
      this.landlord = UserLiteStore.initFromLiteUserModel(contract.owner)
    if (contract.renter)
      this.tenant = UserLiteStore.initFromLiteUserModel(contract.renter)
    if (contract.start_date)
      this.startDate = DateTime.fromISO(contract.start_date)
    if (contract.rental_type) this.type = contract.rental_type as RentalTypes
    if (contract.sign_date) this.signDate = DateTime.fromISO(contract.sign_date)
    if (contract.end_date) this.endDate = DateTime.fromISO(contract.end_date)
    if (contract.close_date)
      this.closeDate = DateTime.fromISO(contract.close_date)
    if (contract.initial_commission)
      this.initialCommission = Number(contract.initial_commission)
    if (contract.month_payment) this.rentPrice = Number(contract.month_payment)
    if (contract.security_payment)
      this.securityPayment = Number(contract.security_payment)
    if (contract.installment)
      this.installmentPeriod = Number(contract.installment)
    if (contract.day_of_payment)
      this.paymentDay = Number(contract.day_of_payment)
    if (contract.termination_showing_days)
      this.showingDays = Number(contract.termination_showing_days)
    if (contract.termination_penalty)
      this.penalty = Number(contract.termination_penalty)
    if (typeof contract.auto_prolongate === "boolean")
      this.autoProlongate = contract.auto_prolongate
    if (contract.end_date)
      this.contractPeriodType = contract.end_date ? "fixed" : "openEnded"
    if (contract.planned_leave_date)
      this.dateOfLeaving = DateTime.fromISO(contract.planned_leave_date)
    if (contract.status)
      this.status = contract.status === "Closed" ? "Closed" : "Open"
    if (contract.inspection_interval_month)
      this.inspectionIntervalMonth = contract.inspection_interval_month
    if (contract.allowable_price_deviation)
      this.allowablePriceDeviation = contract.allowable_price_deviation || null
    if (contract.notify_period_month)
      this.notifyPeriodMonth = contract.notify_period_month
    if (typeof contract.allowed_children !== "undefined")
      this.childrenAllowed = !!contract.allowed_children
    if (typeof contract.allowed_pets !== "undefined")
      this.petsAllowed = !!contract.allowed_pets
    if (contract.utility_payers)
      this.utilityPayers =
        contract.utility_payers?.map((utilityPayer) =>
          getUtilitiesPayerParams(utilityPayer)
        ) || []
    if (contract.first_appraisal_interval_month)
      this.firstAppraisalPeriod = Number(
        contract.first_appraisal_interval_month
      )
    this.isPending = !(Number(this.startDate?.diffNow("days").days) < 0)
    if (contract.closing_conditions)
      this.closingProgress = RentalContractInfoStore.getClosingProgress(
        contract.closing_conditions
      )
    this.contractInfoValues = _.omitBy(
      this,
      _.isFunction
    ) as RentalContractInfoValues
  }

  updateServiceContract = (contract: ServiceContractModel) => {
    this.serviceContract = new ServiceContractLiteStore(contract)
  }

  updateAutoprolongate = (value: boolean) => {
    this.autoProlongate = value
  }

  get isClosed() {
    return this.status === "Closed"
  }

  get canBeClosed() {
    return this.closingProgress.filter((step) => !step.checked).length === 0
  }

  static getBodyForPatchContract = (
    data: Partial<RCInfoFormFields>,
    file: FileBodyRequest | null
  ) => ({
    apply_from: data.changesDate ? data.changesDate.toISODate() : null,
    reason_document: file,
    changes: RentalContractInfoStore.getPatchContractFields(data),
  })

  static getPatchContractFields = (data: Partial<RentalContractInfoStore>) =>
    clearNotValidFields({
      number: data.number,
      sign_date: data.signDate?.toISODate(),
      start_date: data.startDate?.toISODate(),
      end_date: data.endDate ? data.endDate.toISODate() : null,
      initial_commission: data.initialCommission,
      auto_prolongate: data.autoProlongate,
      rental_type: data.type,
      month_payment: toNumber(data.rentPrice),
      security_payment: data.securityPayment,
      installment: data.installmentPeriod,
      day_of_payment: data.paymentDay,
      termination_showing_days: data.showingDays,
      termination_penalty: data.penalty,
    }) as PatchRentalContractParams

  static getCloseContractBody = (
    data: ContractClosingFormFields,
    file: FileBodyRequest | null
  ) => ({
    end_date: data.closeDate?.toISODate(),
    termination_agreement: file,
  })

  static getClosingProgress = (fields: ClosingRentalContractFields) => [
    {
      label: "Transfer inspection",
      checked: !!fields.have_closing_transfer_inspection,
    },
    {
      label: "Date of termination",
      checked: !!fields.have_closing_inspection_date_eq_contract_end_date,
    },
    {
      label: "Impairments inspection",
      checked: !!fields.have_closing_impairments,
    },
    {
      label: "Meters&expenses data",
      checked: !!fields.have_last_meters_filled,
    },
    {
      label: "Deposit calculation",
      checked: !!fields.have_calculated_deposit,
    },
    { label: "Closed last period", checked: !!fields.have_closed_last_period },
  ]
}

export default RentalContractInfoStore
