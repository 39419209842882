import * as yup from "yup"

const SCAdditionalTermsFormSchema = yup.object().shape({
  penaltyInPreparation: yup
    .string()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  penaltyWithRental: yup
    .number()
    .min(0)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  moratorium: yup
    .number()
    .min(0)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
})

export default SCAdditionalTermsFormSchema
