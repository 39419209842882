import * as React from "react"
import styled from "@emotion/styled"
import { Heading, Button, Box, Grid } from "kui-basic"
import { PlusIcon } from "kui-icon"
import InputSearch from "../../ui/InputSearch"
import Container from "../../ui/Container"
import { SearchBlockProps } from "./types"

function SearchBlock<TFormValues extends object>({
  label,
  buttonHandler,
  startComponent,
  className,
  ...inputProps
}: SearchBlockProps<TFormValues>) {
  return (
    <StyledContainer className={className}>
      <Box pb={1} pt={1}>
        <Grid container alignItems="center" justify="space-between">
          <Heading size="h3">{label}</Heading>

          <Grid item>
            <Grid container wrap="nowrap">
              {startComponent}
              <InputSearch {...inputProps} />
              <StyledAddButton
                isCircle
                data-testid="open_modal"
                onClick={buttonHandler}
                size="s"
                variant="orange"
              >
                <PlusIcon />
              </StyledAddButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyledContainer>
  )
}

export default SearchBlock

const StyledContainer = styled(Container)`
  position: sticky;
  top: 64px;
  background-color: ${({ theme }) => theme.palette.grey.zero};
  z-index: 4;
`

const StyledAddButton = styled(Button)`
  margin-left: 12px;
`
