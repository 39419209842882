import * as yup from "yup"
import { ApartmentFillingVariants } from "../../../types/store/apartmentFilling"
import { defaultRequiredMessage } from "../../../../../utils/content/constants"

const FillingInfoFieldsSchema = yup.object().shape({
  type: yup.string(),
  tag: yup.object({
    name: yup.string().required("This is a required field"),
  }),
  name: yup.string().required("This is a required field"),
  serialNumber: yup.string().when("type", {
    is: (value: ApartmentFillingVariants) => value === "electronics",
    then: (rule) => rule.required(defaultRequiredMessage),
  }),
})

export default FillingInfoFieldsSchema
