import React from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import useApartmentLiteStore from "../../../../../store/shared/apartment"
import ApartmentStep from "./ApartmentStep"
import InspectionCommonStep from "../../../../../components/forms/apartment/InspectionCreationForm/InspectionCommonStep"
import { inspectionsSteps } from "../../../../../components/forms/apartment/InspectionCreationForm/content"
import { DynamicRoutes } from "../../../../../types/route"
import { InspectionCreationFormProps } from "./types"

const InspectionCreationForm = (props: InspectionCreationFormProps) => {
  const { handleClose, type } = props
  const { inspectionsStore, overviewStore } = useApartmentLiteStore()
  const navigate = useNavigate()
  const { creationForm } = inspectionsStore

  const handleCreate = (inspectionId?: number | null) => {
    if (inspectionId) {
      const inspectionLink = DynamicRoutes.inspection(
        overviewStore.id!,
        inspectionId
      )
      navigate(inspectionLink)
    }
    creationForm.resetForm()
  }

  if (creationForm.step === 1) {
    return <ApartmentStep />
  }

  if (creationForm.step === 2) {
    return <InspectionCommonStep type={type} onlyInspections={!type} />
  }

  const InspectionSteps =
    inspectionsSteps[
      (creationForm.fields?.type || "initial") as keyof typeof inspectionsSteps
    ]

  return (
    <InspectionSteps
      handleClose={handleClose}
      handleCreate={handleCreate}
      shift={2}
    />
  )
}

export default observer(InspectionCreationForm)
