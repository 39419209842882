import React from "react"
import { useToggle } from "kui-utils"
import AllPhotoContainer from "../AllPhotoContainer"
import UploadPhotoModal from "../UploadPhotoModal"
import PhotoContainer from "../../../../../../../../../components/common/PhotoContainer"

function ApartmentAllPhoto() {
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle(false)

  return (
    <>
      <PhotoContainer label="All photo" handleClick={handleModalOpen}>
        <AllPhotoContainer />
      </PhotoContainer>
      <UploadPhotoModal open={isModalOpen} handleClose={handleModalClose} />
    </>
  )
}

export default ApartmentAllPhoto
