import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import { Loader, Paginator, MultistepForm, callPromises } from "kui-utils"
import {
  InitialInspectionFormFields,
  inspectionsRequests,
  InventoryInspectionFormFields,
  RegularInspectionFormFields,
  TransferInspectionFormFields,
} from "kui-crm_actions"
import { uploadFilesWithoutFilter } from "kui-crm"
import ApartmentPageStore from "../ApartmentPageStore"
import { AppraisalInspectionFormFields } from "../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/types"
import { ImpairmentsInspectionFormFields } from "../../../../components/forms/apartment/InspectionCreationForm/variations/ImpairmentsInspectionForm/types"
import { uploadFiles, uploadImages } from "../../../../utils/agent/uploadFiles"
import ApartmentInspectionsLiteStore from "../../../../store/lites/ApartmentInspectionsLiteStore"
import ApartmentInspectionsListStore from "./ApartmentInspectionsListStore"

class ApartmentInspectionsStore {
  nextInspectionDate: DateTime | null

  frequency: number

  inspectionsStore: ApartmentInspectionsListStore

  appraisalsStore: ApartmentInspectionsListStore

  impairmentsStore: ApartmentInspectionsListStore

  actionLoader: Loader

  paginator: Paginator

  creationForm: MultistepForm<any>

  apartmentStore: ApartmentPageStore

  constructor(apartmentStore: ApartmentPageStore) {
    this.nextInspectionDate =
      apartmentStore.overviewStore.inspection?.nextInspectionDate || null
    this.frequency =
      apartmentStore.overviewStore.inspection?.inspectionsInterval || 0
    this.inspectionsStore = new ApartmentInspectionsListStore(
      apartmentStore,
      "inspections"
    )
    this.appraisalsStore = new ApartmentInspectionsListStore(
      apartmentStore,
      "appraisals"
    )
    this.impairmentsStore = new ApartmentInspectionsListStore(
      apartmentStore,
      "impairments"
    )
    this.actionLoader = new Loader()
    this.paginator = new Paginator(5)
    this.creationForm = new MultistepForm<any>()
    this.apartmentStore = apartmentStore
    makeAutoObservable(this, { apartmentStore: false })
  }

  createInspection = async (apartmentId: number, body: any) =>
    inspectionsRequests.createInspection(apartmentId, body, this.actionLoader)

  createInventoryInspection = async (data: InventoryInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createInventoryInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createRegularInspection = async (data: RegularInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createRegularInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createInitialInspection = async (data: InitialInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createInitialInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createTransferInspection = async (data: TransferInspectionFormFields) => {
    if (this.apartmentId) {
      return inspectionsRequests.createTransferInspection(
        data,
        this.apartmentId,
        this.actionLoader
      )
    }
    return null
  }

  createAppraisalInspection = async (data: AppraisalInspectionFormFields) => {
    const apartmentId = this.apartmentStore.overviewStore.id

    if (apartmentId) {
      this.actionLoader.startLoading("appraisal creation")
      const uploadedObjectsFiles = data.comparableObjects.map(
        (object) => object.screenshot
      )
      const uploadedValuationsFiles = data.statisticalValuations.map(
        (object) => object.screenshot
      )

      const files = await callPromises([
        uploadFiles(this.actionLoader, uploadedObjectsFiles),
        uploadFiles(this.actionLoader, uploadedValuationsFiles),
      ])
      const body = ApartmentInspectionsLiteStore.getPostBodyAppraisalInspection(
        data,
        files[0],
        files[1]
      )
      return this.createInspection(apartmentId, body)
    }
    return null
  }

  createImpairmentsInspection = async (
    data: ImpairmentsInspectionFormFields
  ) => {
    const apartmentId = this.apartmentStore.overviewStore.id

    if (apartmentId) {
      this.actionLoader.startLoading("impairments creation")
      const uploadedFiles =
        data.impairments?.map((impairment) => impairment.file) || []
      const uploadedImages =
        data.impairments?.map((impairment) =>
          uploadImages(this.actionLoader, impairment.images)
        ) || []

      const files = await callPromises([
        uploadFilesWithoutFilter(this.actionLoader, uploadedFiles),
        ...uploadedImages,
      ])
      const body =
        ApartmentInspectionsLiteStore.getPostBodyImpairmentsInspection(
          data,
          files[0],
          files.slice(1)
        )
      return this.createInspection(apartmentId, body)
    }
    return null
  }

  updateInspectionInfo = () => {
    this.nextInspectionDate =
      this.apartmentStore.overviewStore.inspection?.nextInspectionDate || null
    this.frequency =
      this.apartmentStore.overviewStore.inspection?.inspectionsInterval || 0
  }

  get inspectionsContracts() {
    return this.apartmentStore.overviewStore.rentalContracts
  }

  get allApartmentContracts() {
    return this.apartmentStore.overviewStore.rentalContracts
  }

  get apartmentId() {
    return this.apartmentStore.overviewStore.id
  }
}

export default ApartmentInspectionsStore
