import React from "react"
import { Button } from "kui-basic"
import { PlusIcon } from "kui-icon"
import { useToggle } from "kui-utils"
import { observer } from "mobx-react"
import { ImpairmentsInspectionHeaderProps } from "./types"
import InspectionTitle from "../../../components/InspectionTitle"
import ImpairmentModal from "../ImpairmentModal"
import useInspectionStore from "../../../store"

const ImpairmentsInspectionHeader = (
  props: ImpairmentsInspectionHeaderProps
) => {
  const { handleSubmit, form } = props
  const { editor } = useInspectionStore()
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const disabled = !editor.isEditing

  return (
    <>
      <InspectionTitle
        form={form}
        endComponent={
          <Button
            isCircle
            size="xs"
            onClick={handleModalOpen}
            disabled={disabled}
          >
            <PlusIcon width={12} height={12} />
          </Button>
        }
      />
      <ImpairmentModal
        open={isModalOpen}
        handleClose={handleModalClose}
        handleSubmit={handleSubmit}
      />
    </>
  )
}

export default observer(ImpairmentsInspectionHeader)
