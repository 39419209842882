import { InputFile } from "kui-complex"
import { LinkField } from "kui-crm"
import { InputParams } from "../../../../../../../components/common/InputsGroupWithTitle/types"
import { ComparableObjectFormFields } from "../../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/ComparableObjectStep/types"

const getComparableObjectFields = (
  index: number
): InputParams<ComparableObjectFormFields>[] => [
  {
    label: "Link to advertisement",
    name: `comparableObjects.${index}.link`,
    variant: "custom",
    CustomInput: LinkField,
  },
  {
    label: "Advertisement screenshot",
    name: `comparableObjects.${index}.screenshot`,
    variant: "custom",
    CustomInput: InputFile,
  },
  {
    label: "Object price",
    name: `comparableObjects.${index}.price`,
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    cornerLabel: "₽",
  },
  {
    label: "Key object differences",
    name: `comparableObjects.${index}.differences`,
    isTextArea: true,
  },
]

export default getComparableObjectFields
