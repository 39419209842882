import React from "react"
import { Link } from "kui-crm"
import { AppraisalRegistryRowProps } from "./types"
import TableRow from "../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../components/ui/TableCell"
import ApartmentLinkCell from "../../../../../../components/common/ApartmentLinkCell"
import { DynamicRoutes } from "../../../../../../types/route"
import AppraisalStatus from "../../../common/AppraisalStatus"

const TenantAppraisalRegistryRow = (props: AppraisalRegistryRowProps) => {
  const { appraisal, isDark } = props
  const contractLink = DynamicRoutes.contract(
    appraisal.contract?.id || 0,
    "service"
  )

  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst textColor="gray">
        {appraisal.id}
      </TableCell>
      <TableCell>{appraisal.apartment?.folderNumber}</TableCell>
      <TableCell>
        <ApartmentLinkCell apartment={appraisal.apartment} />
      </TableCell>
      <TableCell>
        {appraisal.contract ? (
          <Link href={contractLink}>#{appraisal.contract.number}</Link>
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell>{appraisal.creationDate?.toFormat("dd.MM.yyyy")}</TableCell>
      <TableCell>
        <AppraisalStatus appraisal={appraisal} />
      </TableCell>
      <TableCell>
        {appraisal.offer?.date?.toFormat("dd.MM.yyyy") || "-"}
      </TableCell>
      <TableCell>
        {appraisal.offer?.deltaPrice
          ? `${appraisal.offer.deltaPrice.toLocaleString()} ₽`
          : "-"}
      </TableCell>
      <TableCell isLast>
        {appraisal.offer?.deltaPricePercent
          ? `${appraisal.offer.deltaPricePercent.toLocaleString()}%`
          : "-"}
      </TableCell>
    </TableRow>
  )
}

export default TenantAppraisalRegistryRow
