import { createContext, useContext } from "react"
import hostStore from "../../../store"
import RentalContractPageStore from "./RentalContractPageStore"

const rentalContractStore = new RentalContractPageStore(hostStore)
const RentalContractContext = createContext(rentalContractStore)

const useRCStore = () => useContext(RentalContractContext)

export default useRCStore
