import React from "react"
import { Box, Caption } from "kui-basic"
import { Tooltip } from "kui-crm"
import { ServiceDocumentsTooltipProps } from "./types"
import FileItem from "../../../../../../../../components/common/UploadFiles/FileItem"

const ServiceDocumentsTooltip = ({ service }: ServiceDocumentsTooltipProps) => {
  const handleInvoiceDelete = () => service.deleteDocument("invoice")

  const handleOrderDelete = () => service.deleteDocument("paymentOrder")

  return (
    <Tooltip
      placement="bottom"
      content={
        <div>
          <Box mb={2}>
            <FileItem
              file={service.invoice!}
              label="Invoice"
              onDelete={handleInvoiceDelete}
            />
          </Box>
          <FileItem
            file={service.paymentOrder!}
            label="Payment order"
            onDelete={handleOrderDelete}
          />
        </div>
      }
    >
      <Caption size="s">2 files</Caption>
    </Tooltip>
  )
}

export default ServiceDocumentsTooltip
