import React from "react"
import { Box, Button, Caption, Grid, Heading } from "kui-basic"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import { PlusIcon } from "kui-icon"
import Container from "../../../../../../../components/ui/Container"
import InspectionCreationModal from "../InspectionCreationModal"
import useApartmentStore from "../../../../../store"

function ApartmentInspectionsHeader() {
  const { inspectionsStore } = useApartmentStore()
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const formattedDate =
    inspectionsStore?.nextInspectionDate?.toFormat("dd MMMM")

  return (
    <Container>
      <Box pt={2}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Heading size="h3" weight={700}>
              Inspections
            </Heading>
            <Caption color="fourty" weight={500} size="xs">
              Every {inspectionsStore?.frequency} month
            </Caption>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              {formattedDate && (
                <Caption size="s" weight={500}>
                  <StyledAccent>Next:</StyledAccent> {formattedDate}
                </Caption>
              )}
              <Box ml={2}>
                <Button isCircle onClick={handleModalOpen} size="xs">
                  <PlusIcon width={10} height={10} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <InspectionCreationModal
        open={isModalOpen}
        handleClose={handleModalClose}
      />
    </Container>
  )
}

export default observer(ApartmentInspectionsHeader)

const StyledAccent = styled.span`
  color: ${({ theme }) => theme.palette.grey.fourty};
`
