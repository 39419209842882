import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import {
  getPhoneNumberFromPhoneParams,
  getPhoneParamsFromString,
  Loader,
  resHandler,
} from "kui-utils"
import { GenderAPIVariants } from "kui-crm"
import { UserPersonalInfoAgent } from "../../../agent/Users"
import DocumentsStore from "../../../store/templates/DocumentsStore"
import { GenderVariants, PhoneParams } from "../../../types/common"
import {
  ClientPassportFormFields,
  UserFullPersonalInfoFields,
  UserPassportInfoFields,
} from "../forms/ClientPersonalInfoForm/types"
import { clearNotValidFields } from "../../../utils/service/mapper"
import ClientPageStore from "./ClientPageStore"
import {
  ClientNamesModel,
  ParsedPassportInfoModel,
  UserPassportModel,
  UserPersonalInfoModel,
} from "../types/api/clientAPI"
import { genderMatches } from "../../../utils/content/matches"
import { ClientPersonalInfoParams } from "../types/store/clientInfo"
import { ClientPassportFilesFormFields } from "../forms/ClientPassportFilesFields/types"
import { getBodyForPostFile } from "../../../utils/agent/uploadFiles"
import UploadAgent from "../../../agent/Upload"

const requiredPassportFieldsLength = 3

class ClientPersonalInfoStore {
  loader: Loader

  actionLoader: Loader

  documentsStore: DocumentsStore

  fields: ClientPersonalInfoParams | null = null

  clientStore: ClientPageStore

  constructor(clientStore: ClientPageStore) {
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.documentsStore = new DocumentsStore(UserPersonalInfoAgent)
    this.clientStore = clientStore
    makeAutoObservable(this)
  }

  fetchClientPersonalInfo = async (userId: number) => {
    this.loader.startLoading()

    const [err, res] = await to<UserPersonalInfoModel>(
      UserPersonalInfoAgent.getById(userId)
    )

    if (res && !err) {
      this.updateInfoFields(res)
    } else {
      this.loader.setError("fetch client personal info", err)
    }
    this.loader.endLoading()
  }

  patchClientPersonalInfo = async (
    userId: number,
    data: Partial<UserFullPersonalInfoFields>
  ) => {
    this.actionLoader.startLoading("client info changes")

    await Promise.allSettled([
      this.editClientPersonalInfo(userId, data),
      this.documentsStore.patchDocuments(data.documents!, userId),
    ])

    this.actionLoader.endLoading()
  }

  editClientPersonalInfo = async (
    userId: number,
    data: Partial<UserFullPersonalInfoFields>
  ) => {
    const body = ClientPersonalInfoStore.getPatchPersonalInfoBody(data)

    const [err, res] = await to(
      UserPersonalInfoAgent.patchPersonalInfo(userId, body)
    )
    if (res && !err) {
      this.updateInfoFields(res)
      this.clientStore.overviewStore?.updateClientOverviewInfo(res)
    } else {
      this.actionLoader.setError("patch client personal info")
    }
  }

  updatePassport = async (passportInfo: ClientPassportFormFields) => {
    const userId = this.clientStore.overviewStore?.id

    if (userId) {
      this.actionLoader.startLoading("client passport changes")

      const body = { passportInfo }
      await this.editClientPersonalInfo(userId, body)

      this.actionLoader.endLoading()
    }
  }

  parsePassport = async (data: ClientPassportFilesFormFields) => {
    if (
      data.firstSpread &&
      "file" in data.firstSpread &&
      data.firstSpread.file instanceof File
    ) {
      this.actionLoader.startLoading("parsing passport")

      const body = getBodyForPostFile(data.firstSpread.file)
      const response = await to(UploadAgent.parsePassport(body))

      resHandler(response, this.actionLoader, this.updatePassportForm)

      this.actionLoader.endLoading()
    }
  }

  updatePassportForm = (data: ParsedPassportInfoModel) => {
    const passportInfo = ClientPersonalInfoStore.getPassportInfo(
      data.passport,
      data
    )
    this.fields = { ...this.fields, passportInfo }
  }

  updateInfoFields = (client: Partial<UserPersonalInfoModel>) => {
    this.fields = ClientPersonalInfoStore.getPersonalInfo(client)
  }

  static getPersonalInfo = (
    client: Partial<UserPersonalInfoModel>
  ): ClientPersonalInfoParams => {
    const userPhone = client.phone
      ? getPhoneParamsFromString(client.phone)
      : null
    const secondaryPhone = client.secondary_phone
      ? getPhoneParamsFromString(client.secondary_phone)
      : null

    return {
      passportInfo: ClientPersonalInfoStore.getPassportInfo(
        client.passport_info,
        client
      ),
      emails: [client.email, client.secondary_email].filter(
        (email) => email
      ) as string[],
      phoneNumbers: [userPhone, secondaryPhone].filter(
        (phone) => phone
      ) as PhoneParams[],
      countryOfStay: client.country_of_stay || null,
      address: client.address || "",
      cars:
        client.cars?.map((car) => ({
          mark: car.brand,
          number: car.number,
        })) || [],
    }
  }

  static getPassportInfo = (
    passport: Partial<UserPassportModel> | null | undefined,
    client: Partial<ClientNamesModel>
  ) => ({
    firstName: client.first_name || "",
    lastName: client.last_name || "",
    middleName: client.middle_name || "",
    gender: passport?.gender
      ? (genderMatches[passport.gender] as GenderVariants)
      : null,
    birthday: passport?.birthday ? DateTime.fromISO(passport?.birthday) : null,
    placeOfBirth: passport?.birthday_place || "",
    passportSeries: passport?.series_passport || "",
    passportNumber: passport?.number_passport || "",
    unitCode: passport?.passport_id_of_authority || "",
    dateOfIssue: passport?.issue_date
      ? DateTime.fromISO(passport?.issue_date)
      : null,
    nameOfAuthority: passport?.passport_name_of_authority || "",
    placeOfRegistration: passport?.registration_address || "",
    noRegistration: !passport?.with_registration,
  })

  static getPatchPersonalInfoBody = (
    data: Partial<UserFullPersonalInfoFields>
  ) => {
    const passportData = ClientPersonalInfoStore.getPassportBody(
      data.passportInfo
    )

    return {
      email: data.emails?.[0] || undefined,
      phone: data.phoneNumbers?.[0]
        ? getPhoneNumberFromPhoneParams(data.phoneNumbers[0])
        : undefined,
      country_of_stay_id: data.countryOfStay?.id,
      address: data.actualAddress,
      cars: data.cars?.map((car) => ({
        brand: car.mark,
        number: car.number,
      })),
      secondary_email: data.emails?.[1],
      secondary_phone: data.phoneNumbers?.[1]
        ? getPhoneNumberFromPhoneParams(data.phoneNumbers[1])
        : undefined,
      middle_name: data.passportInfo?.middleName,
      first_name: data.passportInfo?.firstName,
      last_name: data.passportInfo?.lastName,
      ...(Object.keys(passportData).length >= requiredPassportFieldsLength && {
        passport_info: passportData as UserPassportModel,
      }),
    }
  }

  static getPassportBody = (passportInfo?: Partial<UserPassportInfoFields>) =>
    clearNotValidFields({
      gender: passportInfo?.gender
        ? ((passportInfo?.gender === "male" ? "M" : "F") as GenderAPIVariants)
        : null,
      birthday: passportInfo?.birthday?.toISODate() || null,
      birthday_place: passportInfo?.placeOfBirth || null,
      series_passport: passportInfo?.passportSeries || null,
      number_passport: passportInfo?.passportNumber || null,
      passport_id_of_authority:
        passportInfo?.unitCode?.replace(/\s+/g, "") || null,
      passport_name_of_authority: passportInfo?.nameOfAuthority || null,
      issue_date: passportInfo?.dateOfIssue?.toISODate() || null,
      registration_address: passportInfo?.placeOfRegistration || null,
      registration_date: passportInfo?.dateOfRegistration?.toISODate() || null,
      with_registration: !passportInfo?.noRegistration || null,
    })
}

export default ClientPersonalInfoStore
