import React, { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { observer } from "mobx-react"
import { FormWrapper, InputByType, MapComponent } from "kui-crm"
import {
  getBasicApartmentInfoFields,
  BasicApartmentInfoStepSchema,
  BasicApartmentInfoStepFields,
} from "kui-crm_actions"
import MapWrapper from "../../../../../../common/MapWrapper"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"

const BasicApartmentInfoStep = () => {
  const { inspectionsStore, descriptionStore } = useApartmentLiteStore()
  const [coords, setCoords] = useState<google.maps.LatLngLiteral>({
    lat: -1,
    lng: -1,
  })
  const formStore = inspectionsStore.creationForm
  const form = useForm<BasicApartmentInfoStepFields>({
    defaultValues: {
      ...descriptionStore.fields,
      ...formStore.fields,
      apartment: {
        address:
          formStore.fields?.apartment?.address ||
          descriptionStore.fields?.address,
      },
    },
    resolver: yupResolver(BasicApartmentInfoStepSchema),
  })

  const fields = getBasicApartmentInfoFields(setCoords)

  const handleSubmit = (data: BasicApartmentInfoStepFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <StyledFormWrapper
      onSubmit={form.handleSubmit(handleSubmit)}
      form={form}
      withPrevStep
      handlePrevClick={formStore.prevStep}
      variant="next"
      label="Next"
    >
      <Grid container>
        <StyledMapWrapper item xs={12}>
          <MapWrapper>
            <MapComponent
              center={
                coords.lat > 0 ? coords : { lat: 55.752626, lng: 37.614985 }
              }
              withMarker={coords.lat > 0}
              zoom={13}
            />
          </MapWrapper>
        </StyledMapWrapper>
        <StyledContainer item xs={12} container spacing={1}>
          {fields.map(({ size, ...field }) => (
            <Grid item xs={size || 6} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </StyledContainer>
      </Grid>
    </StyledFormWrapper>
  )
}

export default observer(BasicApartmentInfoStep)

const StyledContainer = styled(Grid)`
  position: relative;
  top: -27px;
  padding: 0 40px;
`

const StyledMapWrapper = styled(Grid)`
  overflow: hidden;
  min-height: 108px;
`

const StyledFormWrapper = styled(FormWrapper)`
  padding: 0;
  margin-bottom: 0;
`
