import * as React from "react"
import { observer } from "mobx-react"
import SCApartmentForm from "../SCApartmentForm"
import useSCsStore from "../../store"
import MultistepForm from "../../../../components/common/MultistepForm"
import SCBasicInfoForm from "../SCBasicInfoForm"
import SCCommonTermsForm from "../SCCommonTermsForm"
import SCAdditionalTermsForm from "../SCAdditionalTermsForm"
import SCPayersForm from "../SCPayersForm"
import { ServiceContractPayersFields } from "./types"
import SCFixedCostsForm from "../SCFixedCostsForm"

function CreateServiceContractsForm({ handleSubmit }: any) {
  const { creationForm } = useSCsStore()
  const { updateFormFields, fields, resetForm } = creationForm

  const formSubmit = (data: ServiceContractPayersFields) => {
    updateFormFields(data)
    handleSubmit({ ...fields, ...data })
    resetForm()
  }

  return (
    <MultistepForm form={creationForm}>
      <SCApartmentForm />
      <SCBasicInfoForm />
      <SCCommonTermsForm />
      <SCAdditionalTermsForm />
      <SCPayersForm />
      <SCFixedCostsForm handleSubmit={formSubmit} />
    </MultistepForm>
  )
}

export default observer(CreateServiceContractsForm)
