import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import { Loader, Editor } from "kui-utils"
import { ResourceTypes } from "kui-crm/types"
import HostStore from "../../../store/Root"
import { MeterOverviewFormFields } from "../forms/MeterOverviewInfoForm/types"
import MeterInfoStore from "./MeterInfoStore"
import NotesStore from "../../../store/notes/Notes"
import DocumentsStore from "../../../store/templates/DocumentsStore"
import MeterAgent from "../../../agent/Meter"
import {
  ElectricityMeterValues,
  MeterSingleValue,
} from "../types/store/meterInfo"
import { MeterHistoryModel } from "../types/api/meterAPI"
import { NotesEntities } from "../../../types/api/notes"
import { PostMeterRequest } from "../../../types/api/meters"
import { MeterReadingFormFields } from "../../../components/forms/meters/MeterReadingForm/types"
import MetersReadingStore from "../../../store/shared/meters/MetersReadingStore"

class MeterPageStore {
  loader: Loader

  actionLoader: Loader

  editor: Editor

  meterInfoStore: MeterInfoStore

  notesStore: NotesStore | null

  documentsStore: DocumentsStore

  replacements: MeterInfoStore[]

  resource: ResourceTypes | null

  private hostStore: HostStore

  constructor(hostStore: HostStore) {
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.notesStore = null
    this.meterInfoStore = new MeterInfoStore()
    this.documentsStore = new DocumentsStore(MeterAgent)
    this.hostStore = hostStore
    this.resource = null
    this.replacements = []
    makeAutoObservable(this)
  }

  fetchMeterById = async (id: number, resource: ResourceTypes) => {
    this.resource = resource

    this.loader.startLoading()

    const [err, res] = await to(MeterAgent.getById(id, resource))

    if (res && !err) {
      this.meterInfoStore.updateMeterInfo(res, resource)
      this.notesStore = new NotesStore(
        `meters/${resource}` as NotesEntities,
        res.id
      )
      await this.fetchMeterHistory(res.id, resource)
    } else {
      this.loader.setError("fetch meter", err)
    }
    this.loader.endLoading()
  }

  fetchMeterHistory = async (meterId: number, resource: ResourceTypes) => {
    const [err, res] = await to<MeterHistoryModel>(
      MeterAgent.getHistoryById(meterId, resource)
    )

    if (res && !err) {
      this.replacements = res.map(
        (meter) => new MeterInfoStore(meter, resource)
      )
    } else {
      this.loader.setError("fetch meter history", err)
    }
  }

  patchMeterInfo = async (
    meterId: number,
    resource: ResourceTypes,
    data: MeterOverviewFormFields
  ) => {
    this.actionLoader.startLoading("meter ingo changes")

    await Promise.allSettled([
      this.patchMeterOverview(meterId, resource, data),
      this.documentsStore.patchDocuments(data.documents, meterId, resource),
    ])

    this.actionLoader.endLoading()
  }

  patchMeterOverview = async (
    meterId: number,
    resource: ResourceTypes,
    data: MeterOverviewFormFields
  ) => {
    const body: Partial<PostMeterRequest> =
      MeterPageStore.getPatchMeterBody(data)
    const currentDate = DateTime.now()

    const [err, res] = await to(
      MeterAgent.patch(
        meterId,
        body,
        resource,
        currentDate.year,
        currentDate.month
      )
    )

    if (res && !err) {
      this.meterInfoStore.updateMeterInfo(res, resource)
    } else {
      this.actionLoader.setError(`patch meter`, err)
    }
  }

  createMeterReading = async (data: MeterReadingFormFields) => {
    await MetersReadingStore.createMeterReading(
      this.actionLoader,
      data,
      (res) => {
        this.meterInfoStore.updateNextCheckDate(data.nextCheck)
      }
    )
  }

  deleteMeter = async (id: number) => {
    this.actionLoader.startLoading("meter removal")

    const [err] = await to(MeterAgent.delete(id, this.resource!))

    if (err) {
      this.actionLoader.setError("meter removal", err)
    }

    this.actionLoader.endLoading()
  }

  updateMeterPage = () => {
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.notesStore = null
    this.meterInfoStore = new MeterInfoStore()
    this.documentsStore = new DocumentsStore(MeterAgent)
    this.resource = null
    this.replacements = []
  }

  static getPatchMeterBody = (data: MeterOverviewFormFields) =>
    ({
      passport_number: data.passport,
      tariff: data.tariffGroup?.id,
      activation_date: data.startDate?.toISODate() || null,
      apartment: data.apartment?.id,
      company: data.company?.id,
      auto_sending: data.withAutoSending,
      no_tariff: data.noTariff,
      ...(data.nextCheck && { check_date: data.nextCheck.toISODate() }),
      ...(data.type && {
        type: data.type,
      }),
      operating_account_number: data.operationalAccountNumber,
      ...((data.initialValues as MeterSingleValue).value && {
        initial_value: (data.initialValues as MeterSingleValue).value,
      }),
      ...((data.initialValues as ElectricityMeterValues).T1 && {
        values: {
          T1: (data.initialValues as ElectricityMeterValues).T1,
          T2: (data.initialValues as ElectricityMeterValues).T2,
          T3: (data.initialValues as ElectricityMeterValues).T3,
        },
      }),
    } as Partial<PostMeterRequest>)
}

export default MeterPageStore
