import { PaymentStatuses, RentalTypes } from "kui-crm/types"
import { ServiceContractListModel } from "kui-crm"
import {
  ContractInterface,
  ContractStatuses,
} from "../../../types/store/contract"
import UserLiteStore from "../../../store/templates/UserLite"
import ApartmentSimpleStore from "../../../store/templates/Apartment"
import NotesStore from "../../../store/notes/Notes"

class ServiceContractStore implements ContractInterface {
  id: number

  apartment: ApartmentSimpleStore | null

  number: string

  status: ContractStatuses

  landlord: UserLiteStore

  tenant: UserLiteStore | null

  type: RentalTypes

  paidStatus: PaymentStatuses

  notesStore: NotesStore

  constructor(contract: ServiceContractListModel) {
    this.id = contract.id
    this.apartment = contract.apartment
      ? new ApartmentSimpleStore(contract.apartment)
      : null
    this.number = contract.number
    this.landlord = UserLiteStore.initFromLiteUserModel(contract.owner)
    this.tenant = contract.renter
      ? UserLiteStore.initFromLiteUserModel(contract.renter)
      : null
    this.type = contract.rental_type
    this.paidStatus = contract.agent_report_payment_status
    this.status = contract.status

    this.notesStore = new NotesStore("service-contracts", contract.id)
  }
}

export default ServiceContractStore
