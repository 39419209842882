import React from "react"
import { Box } from "kui-basic"
import { observer } from "mobx-react"
import ApartmentAutocomplete from "../../../../components/entityInputs/ApartmentAutocomplete"
import DotsContainer from "../../../../components/common/DotsContainerForFormSteps"
import useRCsStore from "../../store"
import { RCCommonFieldsProps } from "./types"

function RCCommonFields<T extends object>(props: RCCommonFieldsProps<T>) {
  const { form, onSelect } = props
  const { creationForm } = useRCsStore()

  return (
    <>
      <Box pr={5} pl={5}>
        <ApartmentAutocomplete
          testId="apartment_autocomplete"
          form={form}
          defaultFilter="&with_rental_contract=false"
          disabled={creationForm.step !== 1}
          onSelect={onSelect}
        />
      </Box>
      {creationForm.step !== 1 && (
        <DotsContainer active={creationForm.step} dotsCount={4} />
      )}
    </>
  )
}

export default observer(RCCommonFields)
