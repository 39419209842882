export const defaultUserName = "Unknown user"

export const defaultApartmentImageURL = "/images/LivingAreaSmall.svg"

export const authFlag = "maroomCRMisAuth"

export const averageWalkMetersPerHour = 5000

export const minutesPerHour = 60

export const defaultRequiredMessage = "This field is required"

export const imageExtensions = [
  "png",
  "svg",
  "jpg",
  "jpeg",
  "webp",
  "gif",
  "avif",
  "apng",
]

export const maroomAvatar = "/images/logo/circleLogo.png"

export const meterAutoSendingHint =
  "Meter data is automatically transferred to the management company"

export const meterAverageValueHint = "Meter readings are set by average value"
