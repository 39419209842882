import React, { useState } from "react"
import { observer } from "mobx-react"
import MultistepForm from "../../../../../common/MultistepForm"
import ComparableObjectStep from "./ComparableObjectStep"
import StatisticalValuationStep from "./StatisticalValuationStep"
import ManagerValuationStep from "./ManagerValuationStep"
import { AppraisalInspectionFormFields } from "./types"
import { InspectionFormProps } from "../../../../../../pages/ApartmentPage/forms/inspections/InspectionCreationForm/types"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"
import TenantOfferStep from "./TenantOfferStep"

const AppraisalInspectionForm = (props: InspectionFormProps) => {
  const { handleCreate, shift, handleClose } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const [comparableObjects, setComparableObjects] = useState([0])
  const [statisticalValuationS, setStatisticalValuationS] = useState([0])
  const clientRole = formStore.fields?.clientRole

  const addComparableObject = () => {
    setComparableObjects((prev) => [...prev, prev.length])
    formStore.nextStep()
  }

  const addStatisticalValuation = () => {
    setStatisticalValuationS((prev) => [...prev, prev.length])
    formStore.nextStep()
  }

  const handleSubmit = async () => {
    handleClose()
    const inspectionId = await inspectionsStore.createAppraisalInspection(
      formStore.fields as AppraisalInspectionFormFields
    )

    if (inspectionId) handleCreate(inspectionId)
  }

  return (
    <MultistepForm stepShift={shift} form={formStore}>
      {comparableObjects.map((object) => (
        <ComparableObjectStep
          key={object}
          index={object}
          addStep={addComparableObject}
        />
      ))}
      {statisticalValuationS.map((valuation) => (
        <StatisticalValuationStep
          key={valuation}
          index={valuation}
          addStep={addStatisticalValuation}
        />
      ))}
      {clientRole === "tenant" ? (
        <TenantOfferStep handleSubmit={handleSubmit} />
      ) : (
        <ManagerValuationStep handleSubmit={handleSubmit} />
      )}
    </MultistepForm>
  )
}

export default observer(AppraisalInspectionForm)
