import React, { useState } from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import { LoaderState } from "kui-crm"
import MetersTable from "../MetersTable"
import ComplexMeterRow from "../ComplexMeterRow"
import EditWaterMeterModal from "../EditWaterMeterModal"
import CreatingWaterMeterModal from "../CreatingWaterMeterModal"
import useExpensesStore from "../../../../store"
import SewerageRow from "../SewerageRow"

function ApartmentWaterMetersTable() {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { metersStore, periodStore } = useExpensesStore()
  const { waterMetersStore } = metersStore
  const waterMeters = waterMetersStore?.activeWaterMeters
  const disabled = !periodStore.canBeEdited
  const waterMetersCount = waterMetersStore?.withSewerage
    ? Number(waterMeters?.length) - 1
    : waterMeters?.length

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleEditModalOpen = async (counterId: number) =>
    waterMetersStore?.getWaterMeterById(counterId)

  return (
    <>
      <Box mb={3}>
        <MetersTable
          handleAdd={handleOpenModal}
          title="Water"
          metersCount={waterMetersCount}
          maxMetersCount={waterMetersStore?.maxMetersCount}
          total={waterMetersStore?.total}
        >
          {waterMeters?.map((counter) =>
            counter.type === "sewerage" ? (
              <SewerageRow sewerage={counter} />
            ) : (
              <ComplexMeterRow
                counter={counter}
                tariff={counter.tariffValues}
                disabled={disabled}
                onModalOpen={handleEditModalOpen}
                ModalComponent={EditWaterMeterModal}
              />
            )
          )}
        </MetersTable>
      </Box>
      <CreatingWaterMeterModal
        open={isOpenModal}
        handleClose={handleCloseModal}
      />

      <LoaderState loader={waterMetersStore.loader} onlyError />
      <LoaderState loader={waterMetersStore.actionLoader} />
    </>
  )
}

export default observer(ApartmentWaterMetersTable)
