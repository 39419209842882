import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import EditValueField from "../../../../../../../components/common/EditValueField"
import { EditUtilityValueFields, EditUtilityValueFormProps } from "./types"
import useExpensesStore from "../../../store"

function EditUtilityValueForm({ utility }: EditUtilityValueFormProps) {
  const { periodStore } = useExpensesStore()

  const form = useForm<EditUtilityValueFields>({
    defaultValues: {
      price: utility.value,
    },
  })

  const handleSubmit = async (data: EditUtilityValueFields) => {
    await utility.updateValue(Number(data?.price))
  }

  useEffect(() => {
    form.setValue("price", utility.value)
  }, [utility.value])

  return (
    <EditValueField
      placeholder={String(utility.value)}
      onSave={form.handleSubmit(handleSubmit)}
      disabled={!periodStore.canBeEdited}
      data-testid="utility_value"
      {...form.register("price")}
    />
  )
}

export default observer(EditUtilityValueForm)
