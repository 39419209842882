import React from "react"
import { Grid } from "kui-basic"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormWrapper, InputByType } from "kui-crm"
import impairmentInfoFields from "./fields"
import { ImpairmentInfoStepFields } from "../ImpairmentInfoStep/types"
import { ImpairmentInfoFormProps } from "./types"
import ImpairmentInfoFormSchema from "./schema"

const ImpairmentInfoForm = (props: ImpairmentInfoFormProps) => {
  const { handleSubmit, formStore, defaultValues, ...other } = props
  const form = useForm<ImpairmentInfoStepFields>({
    defaultValues,
    resolver: yupResolver(ImpairmentInfoFormSchema),
  })

  const handleFormSubmit = (data: ImpairmentInfoStepFields) => {
    if (handleSubmit) handleSubmit(data)
    else {
      formStore.updateFormFields(data)
      formStore.nextStep()
    }
  }

  return (
    <FormWrapper
      form={form}
      onSubmit={form.handleSubmit(handleFormSubmit)}
      variant="next"
      label="Next"
      {...other}
    >
      <Grid container spacing={2}>
        {impairmentInfoFields.map((field) => (
          <Grid item xs={12} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default ImpairmentInfoForm
