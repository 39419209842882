import { FormWrapper } from "kui-crm"
import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { observer } from "mobx-react"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"
import ApartmentStepSchema from "./schema"
import { ApartmentStepFields } from "./types"
import ApartmentAutocomplete from "../../../../../../components/entityInputs/ApartmentAutocomplete"

const ApartmentStep = () => {
  const { inspectionsStore, fetchApartmentById, loader } =
    useApartmentLiteStore()
  const { creationForm } = inspectionsStore
  const form = useForm<ApartmentStepFields>({
    defaultValues: { ...creationForm.fields },
    resolver: yupResolver(ApartmentStepSchema),
  })

  const handleSubmit = async (data: ApartmentStepFields) => {
    if (data.apartment.id) {
      await fetchApartmentById(data.apartment.id)
      creationForm.updateFormFields(data)
      creationForm.nextStep()
    }
  }

  return (
    <FormWrapper
      variant="next"
      label="Next"
      isLoading={loader.isLoading}
      form={form}
      onSubmit={form.handleSubmit(handleSubmit)}
    >
      <ApartmentAutocomplete label="Object" form={form} />
    </FormWrapper>
  )
}

export default observer(ApartmentStep)
