import { InputsModalGroupFields } from "../../../../components/common/InputsModalGroupWithTitle/types"
import CheckboxWithController from "../../../../components/ui/CheckboxWithController"

const checkboxLabelProps = {
  size: "s",
}

const scAdditionalTermsGroupFields: InputsModalGroupFields[] = [
  {
    title: "Additional terms",
    fields: [
      {
        label: "Pets allowed",
        name: "petsAllowed",
        variant: "custom",
        CustomInput: CheckboxWithController,
        size: 12,
        labelProps: checkboxLabelProps,
      },
      {
        label: "Children allowed",
        name: "childrenAllowed",
        variant: "custom",
        CustomInput: CheckboxWithController,
        size: 12,
        labelProps: checkboxLabelProps,
      },
      {
        isTextArea: true,
        placeholder: "text",
        name: "additionalTerms",
        size: 12,
      },
    ],
  },
  {
    title: "Termination terms",
    fields: [
      {
        label: "Penalty in preparation",
        name: "penaltyInPreparation",
        cornerLabel: "₽",
        variant: "mask",
        mask: Number,
        thousandsSeparator: " ",
      },
      {
        label: "Penalty with rental",
        name: "penaltyWithRental",
        type: "number",
        cornerLabel: "%",
      },
      {
        label: "Moratorium",
        name: "moratorium",
        type: "number",
        cornerLabel: "month",
        size: 12,
      },
    ],
  },
]

export default scAdditionalTermsGroupFields
