import React from "react"
import styled from "@emotion/styled"
import { Button, Grid, GridSizing, Divider } from "kui-basic"
import { CheckIcon, PlusIcon } from "kui-icon"
import { UploadFilesFooterProps } from "./types"
import { maxWidth } from "../../../../utils/service/theme"

function UploadFilesFooter({
  showAddButton,
  disabledSubmit,
  submitTitle,
  leftButton,
  rightButton,
  submitButtonProps,
  ...otherProps
}: UploadFilesFooterProps) {
  return (
    <StyledFooter>
      <Divider />
      <ButtonsWrapper
        container
        spacing={(showAddButton ? 2 : 0) as GridSizing}
        wrap="nowrap"
        justify="space-between"
      >
        {showAddButton ? (
          <Grid item>
            <Button
              fullWidth
              data-testid="add_file_btn"
              variant="orange"
              size="s"
              endIcon={<PlusIcon />}
              {...otherProps}
            >
              Add
            </Button>
          </Grid>
        ) : (
          leftButton && <LeftButtonWrapper item>{leftButton}</LeftButtonWrapper>
        )}
        <Grid item className="UploadSubmitButton">
          <Grid container alignItems="center" justify="flex-end" wrap="nowrap">
            {rightButton && (
              <StyledEndButtonWrapper>{rightButton}</StyledEndButtonWrapper>
            )}
            <StyledSubmitButton
              disabled={disabledSubmit}
              data-testid="submit_btn"
              size="s"
              type="submit"
              endIcon={<CheckIcon />}
              {...submitButtonProps}
              fullWidth={showAddButton}
            >
              {submitTitle}
            </StyledSubmitButton>
          </Grid>
        </Grid>
      </ButtonsWrapper>
    </StyledFooter>
  )
}

export default UploadFilesFooter

UploadFilesFooter.defaultProps = {
  submitTitle: "Create",
  submitButtonProps: {
    fullWidth: true,
  },
}

const StyledFooter = styled.div`
  background: ${({ theme }) => theme.palette.grey.zero};
  border-radius: 0 0 12px 12px;
  z-index: 10;
  position: sticky;
  bottom: 0;
  left: 0;
`

const ButtonsWrapper = styled(Grid)`
  padding: 12px 40px;
  .UploadSubmitButton {
    margin-left: auto;
  }
  ${maxWidth("xs")} {
    padding: 12px 16px;
  }
`

const LeftButtonWrapper = styled(Grid)`
  padding-right: 24px;
  margin-right: auto;
`

const StyledSubmitButton = styled(Button)`
  min-width: 106px !important;
`

const StyledEndButtonWrapper = styled.div`
  margin-right: 20px;
`
