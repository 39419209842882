import React from "react"
import { observer } from "mobx-react"
import { Grid, Heading } from "kui-basic"
import _ from "lodash"
import styled from "@emotion/styled"
import { LinkField, Link } from "kui-crm"

import useInspectionStore from "../../store"
import Container from "../../../../../../components/ui/Container"
import { InspectionTitleProps } from "./types"
import { DynamicRoutes } from "../../../../../../types/route"
import InspectionLinks from "../InspectionLinks"
import InspectionFeedback from "../InspectionFeedback"

const InspectionTitle = (props: InspectionTitleProps) => {
  const { endComponent, statusComponent, label, form } = props
  const { type, inspectionInfo, apartmentStore, editor } = useInspectionStore()
  const date = inspectionInfo?.date?.toFormat("dd.MM.yyyy")
  const apartmentId = apartmentStore.overviewStore.id
  const withLinks = ["regular", "inventory", "transfer", "initial"].includes(
    type!
  )
  const basedOn = inspectionInfo &&
    "basedOn" in inspectionInfo &&
    inspectionInfo.basedOn && (
      <Link
        href={DynamicRoutes.inspection(apartmentId!, inspectionInfo.basedOn.id)}
      >
        {" "}
        based {inspectionInfo.basedOn?.type}
      </Link>
    )

  return (
    <Container>
      <Grid container justify="space-between" alignItems="center" wrap="nowrap">
        <Grid container alignItems="center">
          <Heading size="h3" weight={700}>
            {_.capitalize(type || "")} {label || ""}
            {date}
            {basedOn} inspection
          </Heading>
          {withLinks && <InspectionLinks />}
        </Grid>
        {endComponent}
      </Grid>
      <InspectionFeedback />
      {statusComponent}
      <StyledLinkWrapper item xs={4}>
        <LinkField
          label="Dropbox link"
          form={form}
          name="dropboxLink"
          disabled={!editor.isEditing}
        />
      </StyledLinkWrapper>
    </Container>
  )
}

export default observer(InspectionTitle)

const StyledLinkWrapper = styled(Grid)`
  margin-top: 24px;
`
