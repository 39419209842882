import * as React from "react"
import { observer } from "mobx-react"
import { useEffect } from "react"
import styled from "@emotion/styled"
import { LoaderState } from "kui-crm"
import ServicesTable from "./ServicesTable"
import Loading from "../../../../../../../components/common/Loading"
import Container from "../../../../../../../components/ui/Container"
import ServicesConfirmationButton from "./ServicesConfirmationButton"
import useExpensesStore from "../../../store"
import PendingServices from "./PendingServices"

function ExpensesServicesTab() {
  const { overviewStore, servicesStore, periodStore, selectedContract } =
    useExpensesStore()

  useEffect(() => {
    if (overviewStore.id && periodStore.id) {
      servicesStore.initServicesTab(
        overviewStore.id,
        selectedContract?.id,
        periodStore.id
      )
    } else {
      servicesStore.cleanServicesTab()
    }
    return () => {
      servicesStore.loader.startLoading()
      periodStore.cleanPeriodId()
    }
  }, [periodStore.id, overviewStore.id, selectedContract?.id])

  if (servicesStore.loader.isLoading) {
    return <Loading height="60vh" />
  }

  return (
    <StyledContainer>
      <ServicesTable
        type="openEnded"
        services={servicesStore?.openEndedServices}
      />
      <ServicesTable type="period" services={servicesStore?.periodServices} />
      <ServicesTable type="once" services={servicesStore?.onceServices} />
      <ServicesTable type="auto" services={servicesStore?.autoServices} />
      <ServicesTable type="own" services={servicesStore?.ownServices} />
      <ServicesConfirmationButton />

      <PendingServices />

      <LoaderState loader={servicesStore.actionLoader} />
      <LoaderState loader={servicesStore.loader} onlyError />
    </StyledContainer>
  )
}

export default observer(ExpensesServicesTab)

const StyledContainer = styled(Container)`
  padding-top: 24px;
  @media (min-width: 1200px) {
    max-width: 1396px;
  }
`
