import React from "react"
import { Grid } from "kui-basic"
import { ApartmentDocumentRowProps } from "./types"
import EmptyApartmentDocumentRow from "../EmptyApartmentDocumentRow"
import FilledApartmentDocumentRow from "../FilledApartmentDocumentRow"

const ApartmentDocumentRow = (props: ApartmentDocumentRowProps) => {
  const { variant, document } = props

  return (
    <Grid item xs={6}>
      {document ? (
        <FilledApartmentDocumentRow document={document} variant={variant} />
      ) : (
        <EmptyApartmentDocumentRow variant={variant} />
      )}
    </Grid>
  )
}

export default ApartmentDocumentRow
