import React from "react"
import { observer } from "mobx-react"
import {
  ModalProps,
  Modal,
  MeterFeaturesFormFields,
  ChangeReasonFormFields,
} from "kui-crm"
import ElectricityMeterForm from "../../../../forms/meters/ElectricityMeterForm"
import ChangingMeterReasonForm from "../../../../../../../../components/forms/meters/ChangingMeterReasonForm"
import useExpensesStore from "../../../../store"
import MultistepForm from "../../../../../../../../components/common/MultistepForm"

function CreatingElectricityMeterModal({ open, handleClose }: ModalProps) {
  const { metersStore, overviewStore } = useExpensesStore()
  const { electricityMetersStore } = metersStore
  const isNewResource =
    electricityMetersStore?.activeElectricityMeters?.length === 0
  const form = electricityMetersStore?.creationForm

  const handleSubmit = async (
    data: MeterFeaturesFormFields | ChangeReasonFormFields
  ) => {
    const fullData = { ...form?.fields, ...data } as MeterFeaturesFormFields
    if (overviewStore.id) {
      handleClose()
      await electricityMetersStore?.postMeter(overviewStore.id, fullData)
      if (fullData.replaceableCounter) {
        electricityMetersStore?.archiveById(Number(fullData.replaceableCounter))
      }
      form?.resetForm()
    }
  }

  return (
    <Modal
      title="New electricity meter"
      data-testid="electricity_modal"
      open={open}
      handleClose={handleClose}
    >
      <MultistepForm form={form}>
        <ElectricityMeterForm
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
        {!isNewResource && (
          <ChangingMeterReasonForm handleSubmit={handleSubmit} />
        )}
      </MultistepForm>
    </Modal>
  )
}

export default observer(CreatingElectricityMeterModal)
