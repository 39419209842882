import React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import AboutPageHeader from "../../../../../components/common/AboutPageHeader"
import ApartmentPreviewImage from "../../../pages/PromotionPage/components/tabs/PromotionContentTab/PromotionPhoto/ApartmentPreviewImage"
import StatusBillet from "../../../../../components/common/StatusBillet"
import useApartmentStore from "../../../store"

function ApartmentOverviewHeader() {
  const { overviewStore } = useApartmentStore()

  const apartmentInfo = [
    {
      title: overviewStore.entrance,
      subtitle: "Entrance",
    },
    {
      title: overviewStore.doorCode,
      subtitle: "Doorcode",
    },
    {
      title: `${overviewStore.floor || "-"} / ${
        overviewStore.floorCount || "-"
      }`,
      subtitle: "Floor",
    },
  ]

  return (
    <AboutPageHeader
      icon={<ApartmentPreviewImage image={overviewStore.previewImage} />}
      title={overviewStore.objectName}
      status={
        overviewStore.isImported && (
          <Box pl={2} pt={1}>
            <StatusBillet variant="warning" label="imported" />
          </Box>
        )
      }
      subtitle={`Folder number: ${overviewStore.folderNumber || "-"}`}
      info={apartmentInfo}
    />
  )
}

export default observer(ApartmentOverviewHeader)
