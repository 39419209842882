import * as yup from "yup"
import { defaultRequiredMessage } from "kui-crm"
import { isValidWithMaskExp } from "../../../../utils/content/regexp"

const ClientPersonalInfoSchema = yup.object().shape(
  {
    phoneNumbers: yup
      .array()
      .of(
        yup.object({
          phoneCode: yup.number(),
          phoneNumber: yup
            .string()
            .matches(isValidWithMaskExp, "Enter the correct value")
            .required("This field is required"),
        })
      )
      .min(1),
    emails: yup
      .array()
      .of(yup.string().email().required("This field is required"))
      .min(1),
    passportInfo: yup.object({
      // passportSeries: yup.string().required("This field is required"),
      // passportNumber: yup.string().required("This field is required"),
      firstName: yup.string().trim().required(defaultRequiredMessage),
      unitCode: yup.string().when("passportInfo.unitCode", {
        is: (value: string) => value?.length,
        then: (rule) =>
          rule.matches(isValidWithMaskExp, "Enter the correct value"),
      }),
      noRegistration: yup.boolean(),
      placeOfRegistration: yup.string().when("noRegistration", {
        is: (value: boolean) => !value,
        then: (rule) => rule.required(defaultRequiredMessage),
      }),
    }),
  },
  [["unitCode", "unitCode"]]
)

export default ClientPersonalInfoSchema
