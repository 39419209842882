import { setupWorker } from "msw"
import apartmentHandlers from "./apartment"
import imageHandlers from "./apartment_image"
import authHandlers from "./auth"
import metroHandlers from "./metro"
import predictorHandlers from "./predictor"
import rentalContractsHandlers from "./rental_contracts"
import serviceContractsHandlers from "./service_contracts"
import userHandlers, { userTabsHandlers } from "./user"
import apartmentCountersHandlers from "./apartment/apartmentCounters"
import apartmentFilingHandlers from "./apartment_filling"
import apartmentInspectionsHandlers from "./apartment/aprtmentInspections"
import apartmentServicesHandlers from "./apartment/apartmentServices"
import apartmentPaymentsHandlers from "./apartment/apartmentSummary/apartmentPayments"
import apartmentPaymentsSummaryHandlers from "./apartment/apartmentSummary/apartmentPaymentsSummary"
import apartmentPayoutsHandlers from "./apartment/apartmentSummary/apartmentPayouts"
import apartmentPeriodHandlers from "./apartment/apartmentSummary/apartmentPeriod"

export const mockServer = setupWorker(
  ...apartmentHandlers,
  ...apartmentCountersHandlers,
  ...imageHandlers,
  ...apartmentServicesHandlers,
  ...authHandlers,
  ...metroHandlers,
  ...predictorHandlers,
  ...rentalContractsHandlers,
  ...serviceContractsHandlers,
  ...userHandlers,
  ...userTabsHandlers,
  ...apartmentFilingHandlers,
  ...apartmentInspectionsHandlers,
  ...apartmentPaymentsHandlers,
  ...apartmentPaymentsSummaryHandlers,
  ...apartmentPayoutsHandlers,
  ...apartmentPeriodHandlers
)

export const partialMockServer = setupWorker()
