import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { InputWithMask } from "kui-complex"
import { Button, Grid } from "kui-basic"
import { CloseIcon } from "kui-icon"
import MetroAutocomplete from "../../../../../components/entityInputs/MetroAutocomplete"
import useStore from "../../../../../hooks/useStore"
import { MetroItemProps } from "./types"

function MetroItem<TFormValues extends object>(
  props: MetroItemProps<TFormValues>
) {
  const { form, isEditing, onDelete, apartmentCoords, index, onChange } = props
  const {
    metroStore: { getMetroParams },
  } = useStore()

  const handleChange = async (name: string, id: number) => {
    const metroFields = await getMetroParams(apartmentCoords, name, id)
    onChange(index, metroFields)
  }

  const handleDelete = () => {
    onDelete(index)
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <MetroAutocomplete
            hideName={`metroStations.${index}.id`}
            name={`metroStations.${index}.name`}
            label="Metro"
            form={form}
            disabled={!isEditing}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <InputWithMask
            name={`metroStations.${index}.walkDistance`}
            label="Walk distance"
            form={form}
            disabled={!isEditing}
            cornerLabel="m"
            mask={Number}
            thousandsSeparator=" "
          />
        </Grid>
        <Grid item xs={2}>
          <InputWithMask
            name={`metroStations.${index}.carDistance`}
            label="Car distance"
            form={form}
            disabled={!isEditing}
            cornerLabel="m"
            mask={Number}
            thousandsSeparator=" "
          />
        </Grid>
        <Grid item xs={2}>
          <Grid container alignItems="center" justify="space-between">
            <StyledInput
              name={`metroStations.${index}.carTime`}
              label="Car time"
              form={form}
              disabled={!isEditing}
              cornerLabel="min"
              mask={Number}
              thousandsSeparator=" "
            />
            <Button
              isCircle
              size="xs"
              variant="backgroundLight"
              onClick={handleDelete}
              disabled={!isEditing}
            >
              <CloseIcon width={8} height={8} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(MetroItem)

const StyledInput = styled(InputWithMask)`
  max-width: calc(100% - 32px - 16px);
`
