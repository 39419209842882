import { DateTime } from "luxon"
import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import _ from "lodash"
import { Loader, resHandler, toNumber } from "kui-utils"
import { ClosingServiceContractFields, PartialPayerFields } from "kui-crm"
import {
  FileBodyRequest,
  TaxResidenceType,
  PaymentStatuses,
  TaxesTypes,
  ServiceContractModel,
  RentalTypes,
  RentPeriodTypes,
} from "kui-crm/types"
import ApartmentSimpleStore from "../../../store/templates/Apartment"
import { ContractStatuses } from "../../../types/store/contract"
import UserLiteStore from "../../../store/templates/UserLite"
import {
  CheckParams,
  ContractPeriodTypes,
  FixedCostParams,
  SubjectRoles,
} from "../../../types/common"
import {
  CloseServiceContractRequest,
  PatchServiceContractParams,
  PatchServiceContractResponse,
  PlannedEndDateModel,
} from "../../../types/api/service_contracts"
import {
  matchesAPISubjectRoles,
  matchesSubjectRoles,
} from "../../../utils/content/matches"
import { clearNotValidFields } from "../../../utils/service/mapper"
import ServiceContractAgent from "../../../agent/ServiceContract"
import { ServiceContractInfoValues } from "../types/store/scInfoStore"
import { SCInfoFormFields } from "../components/SCInfoFields/types"
import RentalContractLiteStore from "../../../store/templates/RentalContractLite"
import {
  getApiUtilitiesPayerParams,
  getUtilitiesPayerParams,
} from "../../../utils/service/apiMapper"
import { uploadNewFile } from "../../../utils/agent/uploadFiles"
import { getChangedValue } from "../../../utils/agent/reqBodyFormat"
import { normalizePayers } from "../utils/scUtilityPayers"
import { ContractClosingFormFields } from "../../../components/forms/contacts/ContractClosingForm/types"
import { CheckoutDateFormFields } from "../../../components/forms/contacts/ContractCheckoutDateForm/types"

class ServiceContractInfoStore {
  id: number | null = null

  apartment: ApartmentSimpleStore | null = null

  number: string = ""

  startDate: DateTime | null = null

  signDate: DateTime | null = null

  endDate: DateTime | null = null

  closeDate: DateTime | null = null

  status: ContractStatuses | null = null

  landlord: UserLiteStore | null = null

  tenant: UserLiteStore | null = null

  type: RentalTypes | null = null

  advertisingPeriod: number | null = null

  maintenanceCommission: number | null = null

  initialCommission: number | null = null

  utilityPayers: PartialPayerFields[] = []

  inspectionIntervalMonth: number | null = null

  notifyPeriodMonth: number | null = null

  penaltyWithRental: number | null = null

  penaltyInPreparation: number | null = null

  minRentPrice: number | null = null

  maxRentPrice: number | null = null

  agentType: TaxesTypes | null = null

  autoProlongate: boolean = false

  paidStatus: PaymentStatuses | null = null

  contractPeriodType: ContractPeriodTypes | null = null

  rentPeriodType: RentPeriodTypes[] = []

  allowablePriceDeviation: number | null = null

  petsAllowed: boolean = false

  childrenAllowed: boolean = false

  additionalTerms: string = ""

  isPending: boolean = false

  loader: Loader

  contractInfoValues: ServiceContractInfoValues

  fixedCosts: FixedCostParams[] = []

  taxResidence: TaxResidenceType | null = null

  rentalContracts: RentalContractLiteStore[] = []

  firstAppraisalPeriod: number | null = null

  tentativeCloseDate: DateTime | null = null

  closingProgress: CheckParams[] = []

  constructor() {
    this.loader = new Loader()
    this.contractInfoValues = _.omitBy(
      this,
      _.isFunction
    ) as ServiceContractInfoValues
    makeAutoObservable(this)
  }

  patchServiceContractInfo = async (data: SCInfoFormFields) => {
    const file = await uploadNewFile(this.loader, data.changesFile)
    const body = ServiceContractInfoStore.getBodyForPatchContract(
      data,
      file,
      this
    )

    const [err, res] = await to<PatchServiceContractResponse>(
      ServiceContractAgent.patch(this.id!, body)
    )
    if (!err && res) {
      this.updateServiceContract(res.changes)
    } else {
      this.loader.setError(`patch service contract`, err)
    }
  }

  closeServiceContract = async (data: ContractClosingFormFields) => {
    this.loader.startLoading("closing contract")

    const file = await uploadNewFile(this.loader, data.terminationAgreement)

    const body = ServiceContractInfoStore.getCloseContractBody(data, file)

    const response = await to<CloseServiceContractRequest>(
      ServiceContractAgent.close(this.id!, body)
    )

    resHandler(response, this.loader, (res) => {
      this.closeDate = res.end_date ? DateTime.fromISO(res.end_date) : null
      this.status = "Closed"
    })
  }

  saveTentativeCloseDate = async (data: CheckoutDateFormFields) => {
    this.loader.startLoading()
    const document = await uploadNewFile(this.loader, data.reason)

    const body: PlannedEndDateModel = {
      document,
      planned_end_date: data.checkoutDate?.toISODate() || "",
    }

    const [err, res] = await to<PlannedEndDateModel>(
      ServiceContractAgent.saveTentativeCloseDate(this.id!, body)
    )

    runInAction(() => {
      if (!err && res) {
        this.tentativeCloseDate = DateTime.fromISO(res.planned_end_date)
      } else {
        this.loader.setError("save close date")
      }
      this.loader.endLoading()
    })
  }

  updateServiceContract = (contract: Partial<ServiceContractModel>) => {
    if (contract.id) this.id = contract.id
    if (contract.apartment)
      this.apartment = new ApartmentSimpleStore(contract.apartment)
    if (contract.number) this.number = contract.number
    if (contract.owner)
      this.landlord = UserLiteStore.initFromLiteUserModel(contract.owner)
    if (contract.renter)
      this.tenant = UserLiteStore.initFromLiteUserModel(contract.renter)
    if (contract.start_date)
      this.startDate = DateTime.fromISO(contract.start_date)
    if (contract.rental_type) this.type = contract.rental_type
    if (contract.sign_date) this.signDate = DateTime.fromISO(contract.sign_date)
    if (contract.end_date) this.endDate = DateTime.fromISO(contract.end_date)
    if (contract.maintenance_commission)
      this.maintenanceCommission = Number(contract.maintenance_commission)
    if (contract.initial_commission)
      this.initialCommission = Number(contract.initial_commission)
    if (contract.utility_payers)
      this.utilityPayers =
        contract.utility_payers?.map((utilityPayer) =>
          getUtilitiesPayerParams(utilityPayer)
        ) || []
    if (contract.advertising_period)
      this.advertisingPeriod = Number(contract.advertising_period)
    if (contract.inspection_interval_month)
      this.inspectionIntervalMonth = contract.inspection_interval_month
    if (contract.notify_period_month)
      this.notifyPeriodMonth = contract.notify_period_month
    if (contract.penalty_with_rental)
      this.penaltyWithRental = Number(contract.penalty_with_rental)
    if (contract.penalty_in_preparation)
      this.penaltyInPreparation = Number(contract.penalty_in_preparation)
    if (contract.min_rent_price) this.minRentPrice = contract.min_rent_price
    if (contract.max_rent_price) this.maxRentPrice = contract.max_rent_price
    if (contract.taxes_type) this.agentType = contract.taxes_type
    if (typeof contract.auto_prolongate === "boolean")
      this.autoProlongate = contract.auto_prolongate
    if (contract.renter_payment_status)
      this.paidStatus = contract.renter_payment_status
    if (typeof contract.end_date !== "undefined")
      this.contractPeriodType = contract.end_date ? "fixed" : "openEnded"
    if (typeof contract.allowed_children !== "undefined")
      this.childrenAllowed = !!contract.allowed_children
    if (typeof contract.allowed_pets !== "undefined")
      this.petsAllowed = !!contract.allowed_pets
    if (contract.additional_terms)
      this.additionalTerms = contract.additional_terms || ""
    if (contract.allowable_price_deviation)
      this.allowablePriceDeviation = contract.allowable_price_deviation || null
    if (contract.tax_residence) this.taxResidence = contract.tax_residence
    if (contract.fixed_costs && contract.fixed_costs instanceof Array)
      this.fixedCosts =
        contract.fixed_costs?.map((expense) => ({
          name: expense.name,
          cost: Number(expense.value),
          payer: matchesSubjectRoles[expense.payer] as SubjectRoles,
        })) || []
    if (contract.long_term_type)
      this.rentPeriodType = contract.long_term_type || []
    if (contract.rental_contracts)
      this.rentalContracts = contract.rental_contracts?.map(
        (rentalContract) => new RentalContractLiteStore(rentalContract)
      )
    if (contract.first_appraisal_interval_month)
      this.firstAppraisalPeriod = Number(
        contract.first_appraisal_interval_month
      )

    this.isPending = !(Number(this.startDate?.diffNow("days").days) < 0)

    this.status =
      this.endDate && this.endDate.diffNow("days").days < 0 ? "Closed" : "Open"
    if (contract.closing_conditions)
      this.closingProgress = ServiceContractInfoStore.getClosingProgress(
        contract.closing_conditions
      )
    if (contract.planned_end_date)
      this.tentativeCloseDate = DateTime.fromISO(contract.planned_end_date)
    if (contract.close_date)
      this.closeDate = DateTime.fromISO(contract.close_date)

    this.contractInfoValues = _.omitBy(
      this,
      _.isFunction
    ) as ServiceContractInfoValues
  }

  updateAutoprolongate = (value: boolean) => {
    this.autoProlongate = value
  }

  get isClosed() {
    return this.status === "Closed"
  }

  get canBeClosed() {
    return this.closingProgress.filter((step) => !step.checked).length === 0
  }

  static getBodyForPatchContract = (
    data: Partial<SCInfoFormFields>,
    file: FileBodyRequest | null,
    contractInfo: ServiceContractInfoStore
  ) => ({
    apply_from: data.changesDate ? data.changesDate.toISODate() : null,
    reason_document: file,
    changes: ServiceContractInfoStore.getPatchContractFields(
      data,
      contractInfo
    ),
  })

  static getPatchContractFields = (
    data: Partial<ServiceContractInfoStore>,
    contractInfo: ServiceContractInfoStore
  ) =>
    clearNotValidFields({
      number: data.number,
      sign_date: data.signDate?.toISODate(),
      start_date: data.startDate?.toISODate(),
      end_date: data.endDate ? data.endDate.toISODate() : null,
      // advertising_period: data.advertising_period,
      maintenance_commission: data.maintenanceCommission,
      initial_commission: data.initialCommission,
      inspection_interval_month: data.inspectionIntervalMonth,
      notify_period_month: data.notifyPeriodMonth,
      penalty_with_rental: data.penaltyWithRental,
      penalty_in_preparation: toNumber(data.penaltyInPreparation),
      min_rent_price: toNumber(data.minRentPrice),
      max_rent_price: toNumber(data.maxRentPrice),
      auto_prolongate: data.autoProlongate,
      taxes_type: data.agentType,
      long_term_type: data.rentPeriodType?.includes("any")
        ? (["short", "medium", "long"] as RentPeriodTypes[])
        : data.rentPeriodType,
      allowed_pets: data.petsAllowed,
      allowed_children: data.childrenAllowed,
      additional_terms: data.additionalTerms,
      allowable_price_deviation: data.allowablePriceDeviation,
      fixed_costs: data.fixedCosts?.map((expense) => ({
        name: expense.name,
        value: toNumber(expense.cost),
        payer: matchesAPISubjectRoles[expense.payer],
      })),
      utility_payers: getChangedValue(
        data.utilityPayers,
        normalizePayers(contractInfo.utilityPayers),
        data.utilityPayers?.map((payer) => getApiUtilitiesPayerParams(payer)) ||
          []
      ),
      first_appraisal_interval_month: data.firstAppraisalPeriod,
    }) as PatchServiceContractParams

  static getCloseContractBody = (
    data: ContractClosingFormFields,
    file: FileBodyRequest | null
  ) => ({
    end_date: data.closeDate?.toISODate(),
    termination_agreement: file,
  })

  static getClosingProgress = (fields: ClosingServiceContractFields) => [
    {
      label: "Closed rental contract",
      checked: !!fields.have_rental_contracts_closed,
    },
    {
      label: "Transfer inspection to Landlord",
      checked: !!fields.have_closing_transfer_inspection,
    },
    {
      label: "Impairments inspection",
      checked: !!fields.have_closing_impairments,
    },
    {
      label: "Date of termination",
      checked: !!fields.have_closing_inspection_date_eq_contract_end_date,
    },
    { label: "Closed last period", checked: !!fields.have_closed_last_period },
  ]
}

export default ServiceContractInfoStore
