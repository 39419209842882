import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Modal } from "kui-crm"
import InspectionCreationForm from "../../../../../forms/inspections/InspectionCreationForm"
import useApartmentStore, { apartmentStore } from "../../../../../store"
import { InspectionCreationModalProps } from "./types"
import { ApartmentLiteContext } from "../../../../../../../store/shared/apartment"

const InspectionCreationModal = (props: InspectionCreationModalProps) => {
  const { open, handleClose, variant, basedOn } = props
  const { inspectionsStore } = useApartmentStore()
  const inspectionType = inspectionsStore.creationForm.fields?.type
  const label =
    inspectionType === "appraisal" || inspectionType === "impairments"
      ? inspectionType
      : `${inspectionType || ""} inspection`

  return (
    <StyledModal
      title={`New ${variant || label}`}
      open={open}
      handleClose={handleClose}
    >
      <ApartmentLiteContext.Provider value={apartmentStore}>
        <InspectionCreationForm
          type={variant}
          basedOn={basedOn}
          handleClose={handleClose}
        />
      </ApartmentLiteContext.Provider>
    </StyledModal>
  )
}

export default observer(InspectionCreationModal)

const StyledModal = styled(Modal)`
  h2 {
    padding-bottom: 8px;
  }
`
