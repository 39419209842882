import React from "react"
import { observer } from "mobx-react"
import {
  ModalProps,
  Modal,
  MeterFeaturesFormFields,
  ChangeReasonFormFields,
} from "kui-crm"
import ChangingMeterReasonForm from "../../../../../../../../components/forms/meters/ChangingMeterReasonForm"
import GasMeterForm from "../../../../forms/meters/GasMeterForm"
import useExpensesStore from "../../../../store"
import MultistepForm from "../../../../../../../../components/common/MultistepForm"

function CreatingGasMeterModal({ open, handleClose }: ModalProps) {
  const { metersStore, overviewStore } = useExpensesStore()
  const { gasMetersStore } = metersStore
  const isNewResource = gasMetersStore?.activeGasMeters?.length === 0
  const form = gasMetersStore?.creationForm

  const handleSubmit = async (
    data: MeterFeaturesFormFields | ChangeReasonFormFields
  ) => {
    const fullData = { ...form?.fields, ...data } as MeterFeaturesFormFields
    if (overviewStore.id) {
      handleClose()
      await gasMetersStore?.postMeter(fullData, overviewStore.id)
      if (fullData.replaceableCounter) {
        gasMetersStore?.archiveById(Number(fullData.replaceableCounter))
      }
      form?.resetForm()
    }
  }

  return (
    <Modal
      title="New gas meter"
      data-testid="gas_modal"
      open={open}
      handleClose={handleClose}
    >
      <MultistepForm form={form}>
        <GasMeterForm handleClose={handleClose} handleSubmit={handleSubmit} />
        {!isNewResource && (
          <ChangingMeterReasonForm handleSubmit={handleSubmit} />
        )}
      </MultistepForm>
    </Modal>
  )
}

export default observer(CreatingGasMeterModal)
