/* global google */
import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { GeolocationParams } from "kui-crm/types"
import { MetroStationParams } from "../../types/api/metro"
import MetroAgent from "../../agent/Metro"
import filterItemsByQuery from "../../utils/service/filter"

class MetroStore {
  metroStations: MetroStationParams[]

  err: Error | string | null

  searchQuery: string

  constructor() {
    makeAutoObservable(this)
    this.metroStations = []
    this.err = null
    this.searchQuery = ""
  }

  setSearchQuery = (query: string) => {
    this.searchQuery = query
  }

  getAllMetroStations = async () => {
    this.err = null
    if (this.metroStations.length === 0) {
      const [err, res] = await to<MetroStationParams[]>(MetroAgent.getAll())
      runInAction(() => {
        if (!err && res) {
          this.metroStations = res
        } else {
          this.err = err
        }
      })
    }
  }

  getDistanceAndDuration = async (
    origin: google.maps.LatLng | "",
    destination: google.maps.LatLng,
    travelMode: google.maps.TravelMode
  ) => {
    let distance
    let duration
    const directionService = new google.maps.DirectionsService()
    await directionService.route(
      {
        origin,
        destination,
        travelMode,
      },
      (results) => {
        const foundRoute = results?.routes[0]?.legs[0]
        distance = foundRoute?.distance?.value
        duration = foundRoute?.duration?.value
          ? +Number(Number(foundRoute?.duration?.value) / 60).toFixed(0)
          : 0
      }
    )
    return { distance, duration }
  }

  getStationDistance = async (
    origin: google.maps.LatLng | "",
    destination: google.maps.LatLng
  ) => {
    const { distance: walkDistance, duration: walkTime } =
      await this.getDistanceAndDuration(
        origin,
        destination,
        google.maps.TravelMode.WALKING
      )
    const { distance: carDistance, duration: carTime } =
      await this.getDistanceAndDuration(
        origin,
        destination,
        google.maps.TravelMode.DRIVING
      )
    return {
      walkDistance,
      walkTime,
      carDistance,
      carTime,
    }
  }

  getMetroStationNameById = (id: number) => {
    const foundStations = this.metroStations.filter(
      (item: MetroStationParams) => item.id === id
    )
    return foundStations.length > 0 ? foundStations[0].name : ""
  }

  getMetroIdByStationName = (name: string) => {
    const foundStations = this.metroStations.filter(
      (item: MetroStationParams) =>
        item.name.toLowerCase() === name.toLowerCase()
    )
    return foundStations.length > 0 ? foundStations[0].id : null
  }

  getMetroCoordsById = (id: number) => {
    const foundStations = this.metroStations.filter(
      (item: MetroStationParams) => item.id === id
    )
    return foundStations.length > 0
      ? {
          lat: Number(foundStations[0].geolocation.split(",")[0]),
          lng: Number(foundStations[0].geolocation.split(",")[1]),
        }
      : {
          lat: 0,
          lng: 0,
        }
  }

  getMetroParams = async (
    location: google.maps.LatLngLiteral,
    name: string | null,
    id?: number | null
  ) => {
    if (!id && name) {
      id = this.getMetroIdByStationName(name)
    }
    if (id && !name) {
      name = this.getMetroStationNameById(id)
    }
    const metroCoords = this.getMetroCoordsById(id!)

    const { walkDistance, walkTime, carDistance, carTime } =
      await this.getStationDistance(
        new google.maps.LatLng(location),
        new google.maps.LatLng(metroCoords)
      )

    return {
      id,
      name,
      walkDistance,
      walkTime,
      carDistance,
      carTime,
      lat: metroCoords.lat,
      lng: metroCoords.lng,
    }
  }

  findNearbyStations = async (
    map: google.maps.Map | null,
    location: GeolocationParams,
    limit?: number
  ) => {
    if (map) {
      return new Promise((resolve) => {
        let nearbyStations: any[] = []
        const coords = new google.maps.LatLng(location as GeolocationParams)
        const request = {
          location: coords,
          type: "subway_station",
          rankBy: google.maps.places.RankBy.DISTANCE,
        }
        const service = new google.maps.places.PlacesService(map!)
        service.nearbySearch(
          request,
          async (results: google.maps.places.PlaceResult[] | null) => {
            nearbyStations =
              results && results.length > 0
                ? await Promise.allSettled(
                    results
                      ?.slice(0, limit)
                      .map((metro) =>
                        this.getMetroParams(location, metro.name || "")
                      )
                  )
                : []
            resolve(nearbyStations.map((station) => station.value))
          }
        )
      })
    }
    return []
  }

  setError = (error: string) => {
    this.err = error
  }

  get filteredByQueryMetro() {
    return filterItemsByQuery(this.metroStations, this.searchQuery, ["name"])
  }

  reset = () => {
    this.metroStations = []
    this.err = null
    this.searchQuery = ""
  }
}

export default MetroStore
