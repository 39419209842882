import requests from "./index"
import { PatchInspectionBody } from "../pages/ApartmentPage/pages/InspectionPage/types/api/common"

const ApartmentInspectionAgent = {
  getById: (apartmentId: number, inspectionId: number) =>
    requests.get(`/apartments/${apartmentId}/inspections/${inspectionId}`),
  patch: (apartmentId: number, id: number, data: PatchInspectionBody) =>
    requests.patch(`/apartments/${apartmentId}/inspections/${id}`, data),
  delete: (apartmentId: number, id: number) =>
    requests.delete(`/apartments/${apartmentId}/inspections/${id}`),
  deletePhoto: (apartmentId: number, id: number, imageId: number) =>
    requests.delete(
      `/apartments/${apartmentId}/inspections/${id}/images/${imageId}`
    ),
  resign: (apartmentId: number, id: number) =>
    requests.post(`/apartments/${apartmentId}/inspections/${id}/resign`),
  resendOffer: (apartmentId: number, id: number) =>
    requests.post(`/apartments/${apartmentId}/inspections/${id}/resign-offer`),
  getRoomImagesGroup: (
    apartmentId: number,
    inspectionId: number,
    imagesGroupId: number
  ) =>
    requests.get(
      `/apartments/${apartmentId}/inspections/${inspectionId}/photo-groups/${imagesGroupId}`
    ),
}

export default ApartmentInspectionAgent
