import { InputParams } from "../../../../../../common/InputsGroupWithTitle/types"
import { TenantOfferStepFields } from "./types"

const tenantValuationFields: InputParams<TenantOfferStepFields>[] = [
  {
    label: "Min price",
    name: "minPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 6,
  },
  {
    label: "Max price",
    name: "maxPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 6,
  },
]

const tenantOfferFields: InputParams<TenantOfferStepFields>[] = [
  {
    label: "Now price",
    name: "nowPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 6,
  },
  {
    label: "Later price",
    name: "laterPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 6,
  },
]

export { tenantOfferFields, tenantValuationFields }
