import React from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { MetroOptionProps } from "./types"

const MetroOption = ({ metro }: MetroOptionProps) => (
  <StyledWrapper>
    <Caption size="s" weight={500}>
      {metro.name}
    </Caption>
    <Grid container alignItems="center">
      <StyledPoint color={metro.line.hex_color} />
      <Caption size="xs" color="fiftyP">
        {metro.line.name}
      </Caption>
    </Grid>
  </StyledWrapper>
)

export default MetroOption

const StyledWrapper = styled.div`
  padding: 8px;
  border-radius: 8px;
  transition: all ease-out 0.3s;
  &:hover {
    background: ${({ theme }) => theme.palette.background.light1};
  }
`

const StyledPoint = styled.div`
  margin-right: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #${({ color }) => color};
`
