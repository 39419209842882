import React from "react"
import { observer } from "mobx-react"
import ButtonSelect from "../../../../../../../components/ui/ButtonSelect"
import useExpensesStore from "../../../store"

function ExpensesContracts() {
  const { availableContractsOptions, setSelectedContract, selectedContract } =
    useExpensesStore()

  return (
    <ButtonSelect
      selectedValue={selectedContract?.id}
      options={availableContractsOptions}
      handleChange={setSelectedContract}
    />
  )
}

export default observer(ExpensesContracts)
