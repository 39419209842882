export const dashboardLinksFields = [
  { name: "overdueProxies", label: "Overdue proxies" },
]

export const dashboardCardsFields = [
  { name: "inspectionsImpairments", label: "Inspections require impairments" },
  { name: "unsignedInspections", label: "Unsigned inspections" },
  { name: "metersVerifications", label: "Meters require verification" },
  { name: "closingServiceContracts", label: "Closing rental contracts" },
  { name: "closingRentalContracts", label: "Closing service contracts" },
]
