import React, { useEffect } from "react"
import { LoaderState, ModalProps } from "kui-crm"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import useDashboardFilter from "../../../hooks/useDashboardFilter"
import { DashboardPageProps } from "./types"
import useDashboardStore from "../../../store"
import DashboardHeader from "../DashboardHeader"
import DashboardSettingsModal from "../DashboardSettingsModal"

const DashboardPageWrapper = (props: DashboardPageProps) => {
  const { periodFilter, clearFilters } = useDashboardStore()
  const { dashboardStore, children } = props

  useDashboardFilter(periodFilter, dashboardStore?.loadCards)

  useEffect(() => {
    dashboardStore?.initDashboard()
    return () => {
      dashboardStore?.resetDashboard()
      clearFilters()
    }
  }, [dashboardStore])

  if (!dashboardStore) return null

  return (
    <>
      <DashboardHeader
        settings={dashboardStore.settings}
        renderModal={(modalProps: ModalProps) => (
          <DashboardSettingsModal
            userRole={dashboardStore.role}
            {...modalProps}
          />
        )}
      />
      <StyledContentWrapper>{children}</StyledContentWrapper>
      <LoaderState loader={dashboardStore?.loader} onlyError />
    </>
  )
}

export default observer(DashboardPageWrapper)

const StyledContentWrapper = styled.div`
  border-bottom: 32px;
`
