import to from "await-to-js"
import { makeAutoObservable } from "mobx"
import { Loader, toNumber } from "kui-utils"
import ApartmentPaymentStore from "./ApartmentPayment"
import ApartmentExpensesAgent from "../../../../../../agent/ApartmentExpenses"
import { ApartmentPaymentModel } from "../../types/api/expensesSummaryAPI"
import { SubjectRoles } from "../../../../../../types/common"
import { NewPaymentFormInputs } from "../../forms/summary/PaymentCreationForm/types"
import ApartmentExpensesStore from "../ApartmentExpenses"

class ApartmentPaymentsStore {
  expensesStore: ApartmentExpensesStore

  paymentsLines: ApartmentPaymentStore[] = []

  loader: Loader

  actionLoader: Loader

  constructor(expensesStore: ApartmentExpensesStore) {
    this.expensesStore = expensesStore
    this.loader = new Loader()
    this.actionLoader = new Loader()
    makeAutoObservable(this, { expensesStore: false })
  }

  updatePaymentsLines = (res: ApartmentPaymentModel[]) => {
    this.paymentsLines = res.map(
      (payment) => new ApartmentPaymentStore(this, payment)
    )
  }

  addPayment = async (
    data: NewPaymentFormInputs,
    apartmentId: number,
    contractId: number | undefined
  ) => {
    if (this.expensesStore.periodStore.id) {
      this.actionLoader.startLoading()

      const body = ApartmentPaymentsStore.getPostPaymentBody(
        data,
        this.expensesStore.periodStore.id
      )

      const [err, res] = await to<ApartmentPaymentModel[]>(
        ApartmentExpensesAgent.createPayment(apartmentId, body)
      )

      if (res && !err) {
        this.updatePayments(res)
      } else {
        this.actionLoader.setError("payment creation", err)
      }
      this.actionLoader.endLoading()

      await this.expensesStore.summaryStore.payoutsStore.fetchPayouts(
        apartmentId,
        contractId,
        this.expensesStore.periodStore.id
      )
    }
  }

  updatePayments = (payments: ApartmentPaymentModel[]) => {
    payments.forEach((payment) => {
      this.addPaymentToList(payment)
    })
  }

  addPaymentToList = (payment: ApartmentPaymentModel) => {
    this.paymentsLines.unshift(new ApartmentPaymentStore(this, payment))
    this.updateSummaryValue()
  }

  updateSummaryValue = () => {
    const { overviewStore, periodStore, selectedContract } = this.expensesStore
    if (overviewStore.id && periodStore.id) {
      this.expensesStore.summaryStore.paymentSummaryStore.getPaymentSummary(
        overviewStore.id,
        selectedContract?.id,
        periodStore.id
      )
    }
  }

  deletePayment = (paymentId: number, payerRole: SubjectRoles | null) => {
    const deletedPayment = this.paymentsLines.find(
      (payment) => payment.id === paymentId
    )
    this.paymentsLines = this.paymentsLines.filter(
      (payment) => payment.id !== paymentId
    )
    if (deletedPayment) {
      this.updateSummaryValue()
    }
  }

  get withoutLandlordPayOut() {
    return !this.paymentsLines.filter(
      (payment) =>
        payment.payer.role === "landlord" &&
        payment.transactionType === "pay-out"
    ).length
  }

  get withoutTenantPayIn() {
    return !this.paymentsLines.filter(
      (payment) =>
        payment.payer.role === "tenant" && payment.transactionType === "pay-in"
    ).length
  }

  static getPostPaymentBody = (
    data: NewPaymentFormInputs,
    periodId: number
  ) => ({
    payer: data.payerId,
    value: toNumber(data.amount)?.toString(),
    payment_date: data.date.toISODate() || "",
    period_id: periodId,
    bill: data.invoiceId,
    payment_type: data.transactionType,
  })
}

export default ApartmentPaymentsStore
