import { InputPhoneWithForm } from "kui-complex"
import { ApartmentDescriptionFields } from "../../../../../pages/ApartmentPage/forms/description/ApartmentDescriptionForm/types"
import {
  BooleanValues,
  GasTypesValues,
  getNumbersValues,
  HouseTypesValues,
  ParkingTypesValues,
  SlabsTypesValues,
  TVTypesValues,
  WallMaterialsValues,
} from "../../../../../utils/content/listsOptions"
import { InputParams } from "../../../../common/InputsGroupWithTitle/types"
import DependentInput from "../../../../ui/DependentInput"

const ApartmentSecurityInputs: InputParams<ApartmentDescriptionFields>[] = [
  {
    name: "hasIntercom",
    label: "Intercom",
    size: 2,
    variant: "select",
    options: BooleanValues,
  },
  {
    name: "intercomCode",
    label: "Intercom code",
    size: 2,
    variant: "custom",
    CustomInput: DependentInput,
    dependentInputName: "hasIntercom",
  },
  {
    name: "hasGate",
    label: "Gate",
    size: 2,
    variant: "select",
    options: BooleanValues,
  },
  {
    name: "gateCode",
    label: "Gate code",
    size: 2,
    variant: "custom",
    CustomInput: DependentInput,
    dependentInputName: "hasGate",
  },
  {
    name: "hasBarrier",
    label: "Barrier",
    size: 2,
    variant: "select",
    options: BooleanValues,
  },
  {
    name: "barrierPhone",
    label: "Barrier code",
    size: 2,
    variant: "custom",
    CustomInput: DependentInput,
    dependentInputName: "hasBarrier",
    Input: InputPhoneWithForm,
  },
  {
    name: "hasConcierge",
    label: "Concierge",
    size: 2,
    variant: "select",
    options: BooleanValues,
  },
  {
    name: "conciergePhone",
    label: "Concierge",
    size: 2,
    variant: "custom",
    CustomInput: DependentInput,
    dependentInputName: "hasConcierge",
    Input: InputPhoneWithForm,
  },
  {
    name: "hasSecurity",
    label: "Security",
    size: 2,
    variant: "select",
    options: BooleanValues,
  },
  {
    name: "securityPhone",
    label: "Security",
    size: 2,
    variant: "custom",
    CustomInput: DependentInput,
    dependentInputName: "hasSecurity",
    Input: InputPhoneWithForm,
  },
  {
    name: "hasVideoControl",
    label: "Video surveillance",
    size: 2,
    variant: "select",
    options: BooleanValues,
  },
  {
    name: "videoControlPhone",
    label: "Video surveillance",
    size: 2,
    variant: "custom",
    CustomInput: DependentInput,
    dependentInputName: "hasVideoControl",
    Input: InputPhoneWithForm,
  },
]
const ApartmentTelecommunicationsInputs: InputParams<ApartmentDescriptionFields>[] =
  [
    { name: "wifi.name", label: "Name of network", size: 2 },
    { name: "wifi.password", label: "WiFi password", size: 2 },
    { name: "internetProvider.link", label: "Link to provider", size: 4 },
    { name: "internetProvider.login", label: "Login", size: 2 },
    { name: "internetProvider.password", label: "Password", size: 2 },
    {
      name: "tvType",
      label: "Television",
      size: 2,
      variant: "select",
      options: TVTypesValues,
    },
    {
      name: "homePhone",
      label: "Home phone",
      size: 2,
      variant: "phone",
    },
  ]

const ApartmentHouseInputs: InputParams<ApartmentDescriptionFields>[] = [
  {
    name: "typeOfHouse",
    label: "Type houses",
    size: 2,
    variant: "select",
    options: HouseTypesValues,
  },
  { name: "buildingYear", label: "Builded", size: 2 },
  { name: "houseNumber", label: "House series", size: 2 },
  {
    name: "wallMaterial",
    label: "Wall material",
    size: 2,
    variant: "select",
    options: WallMaterialsValues,
  },
  {
    name: "overlapType",
    label: "Overlap type",
    size: 2,
    variant: "select",
    options: SlabsTypesValues,
  },
  {
    name: "parkingType",
    label: "Parking",
    size: 2,
    variant: "select",
    options: ParkingTypesValues,
  },
  {
    name: "parkingNumber",
    label: "Parking number",
    size: 2,
    variant: "custom",
    CustomInput: DependentInput,
    dependentInputName: "parkingType",
  },
  {
    name: "passengerElevatorsCount",
    label: "Passenger elevators",
    size: 2,
    type: "number",
  },
  {
    name: "serviceElevatorsCount",
    label: "Service elevators",
    size: 2,
    type: "number",
  },
  {
    name: "gasLeads",
    label: "Gas",
    size: 2,
    variant: "select",
    options: GasTypesValues,
  },
  {
    name: "withHotWater",
    label: "Hot water",
    size: 2,
    variant: "select",
    options: BooleanValues,
  },
]

const metersOptions = getNumbersValues(7)

const ApartmentMetersInputs: InputParams<ApartmentDescriptionFields>[] = [
  {
    name: "maxMeters.water",
    label: "Water",
    size: 2,
    variant: "select",
    options: metersOptions,
  },
  {
    name: "maxMeters.electricity",
    label: "Electricity",
    size: 2,
    variant: "select",
    options: metersOptions,
  },
  {
    name: "maxMeters.gas",
    label: "Gas",
    size: 2,
    variant: "select",
    options: metersOptions,
  },
  {
    name: "maxMeters.heating",
    label: "Heating",
    size: 2,
    variant: "select",
    options: metersOptions,
  },
]

export {
  ApartmentSecurityInputs,
  ApartmentTelecommunicationsInputs,
  ApartmentHouseInputs,
  ApartmentMetersInputs,
}
