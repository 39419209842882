import React from "react"
import { useForm } from "react-hook-form"
import { Box, Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormBottom, ChangeReasonFields } from "kui-crm"
import {
  CompanyObjectConnectionFormProps,
  CompanyObjectConnectionFormFields,
} from "./types"
import ApartmentAutocomplete from "../../../../components/entityInputs/ApartmentAutocomplete"
import CompanyObjectConnectionSchema from "./schema"

function CompanyObjectConnectionForm({
  handleSubmit,
}: CompanyObjectConnectionFormProps) {
  const form = useForm<CompanyObjectConnectionFormFields>({
    resolver: yupResolver(CompanyObjectConnectionSchema),
  })

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box mb={3} mr={5} ml={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ApartmentAutocomplete form={form} label="New object" />
          </Grid>
          <Grid item xs={12}>
            <ChangeReasonFields form={form} />
          </Grid>
        </Grid>
      </Box>
      <FormBottom label="Add" />
    </form>
  )
}

export default CompanyObjectConnectionForm
