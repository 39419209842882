import React, { useEffect, useState } from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { InputWithMask, InputWithController } from "kui-complex"
import { useWatch } from "react-hook-form"
import {
  CalendarIcon,
  InvoiceIcon,
  RubleIcon,
  CardIcon,
  PersonIcon,
} from "kui-icon"
import InputSelect from "../../../../../../../../components/ui/InputSelect"
import { ApartmentPaymentFieldsProps, PaymentFormFields } from "./types"
import useExpensesStore from "../../../../store"
import InputWithDatePicker from "../../../../../../../../components/ui/InputWithDatepicker"
import { getInvoiceOptions, getPayerOptions } from "./fields"
import { TransactionTypeValues } from "../../../../../../../../utils/content/listsOptions"
import TwoCaptionsBlock from "../../../../../../../../components/common/TwoCaptionsBlock"

function ApartmentPaymentFields({
  form,
  withDefaultInvoice,
}: ApartmentPaymentFieldsProps<PaymentFormFields>) {
  const { overviewStore, summaryStore, selectedContract } = useExpensesStore()
  const { tenant, landlord } = overviewStore
  const { payouts } = summaryStore.payoutsStore
  const [defaultInvoice, setDefaultInvoice] = useState<number | null>(null)
  const payerOptions = getPayerOptions(tenant, landlord)
  const { invoiceOptions, payoutsOptions } = getInvoiceOptions(payouts)
  const transactionType = useWatch({
    control: form.control,
    name: "transactionType",
  })
  const payerId = useWatch({
    control: form.control,
    name: "payerId",
  })
  const payerRole = [tenant, landlord].find(
    (client) => client?.id === payerId
  )?.role

  useEffect(() => {
    if (payoutsOptions.length === 1 && withDefaultInvoice) {
      const invoiceId = payoutsOptions[0].value
      form.setValue("invoiceId", invoiceId)
      setDefaultInvoice(invoiceId)
    }
  }, [payoutsOptions])

  return (
    <Grid container wrap="nowrap">
      <StyledTransactionWrapper>
        <StyledInputSelect
          startIcon={<CardIcon />}
          form={form}
          name="transactionType"
          label="Transaction type"
          options={TransactionTypeValues}
          className="TransactionSelect"
        />
      </StyledTransactionWrapper>
      <StyledPayerWrapper>
        <StyledInputSelect
          className="payer_select"
          startIcon={<PersonIcon />}
          form={form}
          name="payerId"
          label={transactionType === "pay-in" ? "Payer" : "Payee"}
          data-testid="payer"
          options={payerOptions}
          isRequired
        />
      </StyledPayerWrapper>
      <StyledDocumentWrapper>
        {payerRole === "landlord" ? (
          <StyledContractNumber
            top="Contract number"
            bottom={selectedContract ? `№ ${selectedContract.number}` : "-"}
            topTextColor="fiftyP"
            bottomColor="seventy"
            topSize="xs"
            topWeight={400}
            spacing={4}
          />
        ) : (
          <StyledInputSelect
            startIcon={<InvoiceIcon width={16} height={18} />}
            form={form}
            name="invoiceId"
            label="Invoice"
            options={invoiceOptions}
            disabled={!!(invoiceOptions.length < 2 && defaultInvoice)}
          />
        )}
      </StyledDocumentWrapper>
      <StyledDateWrapper>
        <StyledInputCalendar
          startIcon={<CalendarIcon width={16} height={17} />}
          form={form}
          name="date"
          label="Date"
          data-testid="date"
          isRequired
        />
      </StyledDateWrapper>
      <StyledLastInput
        startIcon={<RubleIcon width={18} height={18} />}
        label="Amount"
        name="amount"
        data-testid="amount"
        form={form}
        mask={Number}
        thousandsSeparator=" "
        isRequired
      />
    </Grid>
  )
}

export default observer(ApartmentPaymentFields)

const StyledInput = styled(InputWithController)`
  border-right: 1px solid ${({ theme }) => theme.palette.grey.fifteenB} !important;
  input {
    border-color: transparent !important;
    border-radius: 0 !important;
    background: white !important;
  }
  path {
    fill: ${({ theme }) => theme.palette.grey.thirty};
  }
`

const StyledInputCalendar = StyledInput.withComponent(InputWithDatePicker)
const StyledInputSelect = StyledInput.withComponent(InputSelect)
const StyledLastInput = styled(InputWithMask)`
  width: 100%;
  input {
    border-color: transparent !important;
    border-radius: 0 !important;
  }
  path {
    fill: ${({ theme }) => theme.palette.grey.thirty};
  }
`
const StyledPayerWrapper = styled.div`
  min-width: 280px;
`

const StyledDocumentWrapper = styled.div`
  min-width: 200px;
  padding-left: 8px;
`

const StyledTransactionWrapper = styled.div`
  min-width: 190px;
`

const StyledDateWrapper = styled.div`
  min-width: 158px;
`

const StyledContractNumber = styled(TwoCaptionsBlock)`
  padding-left: 8px;
  border-right: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  height: 100%;
  justify-content: center;
`
