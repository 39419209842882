import React from "react"
import { Grid } from "kui-basic"
import _ from "lodash"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import ClientPersonalDataCell from "../../../../../../../../components/common/ClientPersonalDataCell"
import useExpensesStore from "../../../../store"
import ExpensesPaymentSummary from "../ExpensesPaymentSummary"

function ExpensesPayoutsHeader() {
  const { summaryStore, periodStore } = useExpensesStore()
  const { tenant, landlord } = periodStore
  const { tenantSummary, landlordSummary } = summaryStore.paymentSummaryStore

  return (
    <Grid container>
      <StyledTenantWrapper item xs={6}>
        <ClientPersonalDataCell
          label={tenant?.role ? `${_.capitalize(String(tenant?.role))}` : ""}
          type="left"
          personalData={tenant}
          withLine={false}
        />
        <ExpensesPaymentSummary summaryStore={tenantSummary} variant="left" />
      </StyledTenantWrapper>
      <Grid item xs={6}>
        <ClientPersonalDataCell
          label={
            landlord?.role ? `${_.capitalize(String(landlord?.role))}` : ""
          }
          type="right"
          personalData={landlord}
          withLine={false}
        />
        <ExpensesPaymentSummary
          summaryStore={landlordSummary}
          variant="right"
        />
      </Grid>
    </Grid>
  )
}

export default observer(ExpensesPayoutsHeader)

const StyledTenantWrapper = styled(Grid)`
  border-right: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
`
