import React from "react"
import styled from "@emotion/styled"
import { DeepPartial, Path, useForm } from "react-hook-form"
import { Button } from "kui-basic"
import { PrevArrowIcon } from "kui-icon"
import { UploadFilesFormProps } from "./types"
import UploadFiles from "../../../common/UploadFiles"

function UploadFilesForm<T extends object>(props: UploadFilesFormProps<T>) {
  const { handleSubmit, defaultValues, onPrev, name, testId, ...other } = props

  const form = useForm<T>({
    // @ts-ignore
    defaultValues: {
      [name as Path<T>]: defaultValues,
    } as DeepPartial<T>,
  })

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)} data-testid={testId}>
      <StyledUploadFiles
        name={name}
        form={form as any}
        leftButton={
          <Button isCircle size="s" variant="whiteWithGray" onClick={onPrev}>
            <PrevArrowIcon width={12} height={10} />
          </Button>
        }
        {...other}
      />
    </form>
  )
}

UploadFilesForm.defaultProps = {
  name: "files",
}

export default UploadFilesForm

const StyledUploadFiles = styled(UploadFiles)`
  padding-top: 0;
`
