import React from "react"
import { HomeIcon } from "kui-icon"
import { addToArrayByCondition } from "kui-utils"
import { Routes } from "../../../../../types/route"
import { LinkCardProps } from "../../../components/common/DashboardLinkCard/types"
import ClosingContractsCard from "../../../components/cards/ClosingContractsCard"
import HousingManagerDashboardStore from "../../../store/variants/HousingManagerDashboardStore"
import InspectionImpairmentsCard from "../../../components/cards/InspectionImpairmentsCard"
import MetersVerificationsCard from "../../../components/cards/MetersVerificationsCard"
import UnsignedInspectionsCard from "../../../components/cards/UnsignedInspectionsCard"

export const getLinkCards = (
  housingManagerDashboard: HousingManagerDashboardStore
): LinkCardProps[] => [
  ...addToArrayByCondition(housingManagerDashboard.overdueProxies !== null, {
    label: "Overdue proxies",
    value: housingManagerDashboard.overdueProxies,
    icon: <HomeIcon />,
    link: `${Routes.proxiesRegistry}?sort=expiration_date`,
  }),
]

export const getListCards = (
  housingManagerDashboard: HousingManagerDashboardStore
) => [
  ...addToArrayByCondition(
    housingManagerDashboard.inspectionsImpairments?.value !== null,
    {
      key: "inspectionsImpairments",
      card: (
        <InspectionImpairmentsCard
          inspectionsStore={housingManagerDashboard.inspectionsImpairments!}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    housingManagerDashboard.unsignedInspections.value !== null,
    {
      key: "unsignedInspections",
      card: (
        <UnsignedInspectionsCard
          inspectionsStore={housingManagerDashboard.unsignedInspections}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    housingManagerDashboard.metersVerifications.value !== null,
    {
      key: "metersVerifications",
      card: (
        <MetersVerificationsCard
          metersStore={housingManagerDashboard.metersVerifications}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    housingManagerDashboard.closingRentalContracts.value !== null,
    {
      key: "closingRentalContracts",
      card: (
        <ClosingContractsCard
          contractsStore={housingManagerDashboard.closingRentalContracts}
          {...housingManagerDashboard.closingRentalContracts!}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    housingManagerDashboard.closingServiceContracts.value !== null,
    {
      key: "closingServiceContracts",
      card: (
        <ClosingContractsCard
          contractsStore={housingManagerDashboard.closingServiceContracts}
        />
      ),
    }
  ),
]
