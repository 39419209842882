import * as React from "react"
import { AppBar, ToolBar } from "kui-basic"
import styled from "@emotion/styled"
import { MaroomMiniIcon } from "kui-icon"
import HeaderNavLink from "../HeaderNavLink"
import AvatarDropdown from "../AvatarDropdown"
import routes from "../../../routes"
import { RouteParams } from "../../../types/route"
import Container from "../../ui/Container"

function Header() {
  const headerRoutes = routes.filter((route) => route.title)

  return (
    <StyledAppBar id="header">
      <Container>
        <StyledToolbar>
          <StyledMenuPanel>
            <StyledIcon width={26} height={20} />
            {headerRoutes.map((route: RouteParams) => (
              <HeaderNavLink
                route={route.path}
                title={route.title!}
                key={route.path}
              >
                {route.title!}
              </HeaderNavLink>
            ))}
          </StyledMenuPanel>
          <StyledAvatarWrapper>
            <AvatarDropdown />
          </StyledAvatarWrapper>
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  )
}

export default Header

const StyledAppBar = styled(AppBar)`
  background: #191919;
  position: sticky;
`

const StyledToolbar = styled(ToolBar)`
  padding: 0 !important;
`

const StyledMenuPanel = styled.div`
  display: flex;
  width: 80%;
`

const StyledAvatarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`

const StyledIcon = styled(MaroomMiniIcon)`
  margin-right: 20px;
`
