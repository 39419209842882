import React from "react"
import { observer } from "mobx-react"
import _ from "lodash"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { LoaderState, Link } from "kui-crm"

import EditUtilityValueForm from "../../../../forms/services/EditUtilityValueForm"
import ServiceMenuCell from "../ServiceMenuCell"
import TextOverflowWithComment from "../../../../../../../../components/common/TextOverflowWithComment"
import TextOverflow from "../../../../../../../../components/common/TextOverflow"
import UserBadge from "../../../../../../../../components/common/UserBadge"
import { ServiceRowProps } from "./types"
import { Routes } from "../../../../../../../../types/route"
import GridTableRow from "../../../../../../../../components/common/GridTableRow"
import { serviceTableCellsWidth } from "../ServiceTableHeader/content"
import ServiceDocumentCell from "../ServiceDocumentCell"

function ServiceRow({ service, isPending }: ServiceRowProps) {
  return (
    <>
      <StyledGridTableRow
        cellsWidth={serviceTableCellsWidth}
        data-testid="service_row"
      >
        <TextOverflowWithComment
          name={service.commentAuthor?.fullName}
          comment={service.comment}
          data-testid="service_title"
          weight={400}
        >
          {service.title}
        </TextOverflowWithComment>

        <StyledTextOverflow
          content={service.purpose}
          weight={400}
          data-testid="service_purpose"
          placement="bottom"
        >
          ...
        </StyledTextOverflow>

        <Link href={`${Routes.companies}/${service.company?.id}`}>
          <TextOverflow data-testid="service_company" weight={400}>
            {service.company?.name || "-"}
          </TextOverflow>
        </Link>

        <UserBadge
          data-testid="service_payer"
          user={service.paymentMadeBy}
          name={service.paymentMadeBy.partialName}
          role={service.paymentMadeBy.role!}
        />

        <UserBadge
          data-testid="service_buyer"
          user={service.refundFrom}
          name={service.refundFrom.partialName}
          role={service.refundFrom.role!}
        />

        <Caption weight={400} size="s" data-testid="service_payment_type">
          {_.capitalize(String(service.paymentType))}
        </Caption>

        {service.type === "open_ended" && !isPending ? (
          <EditUtilityValueForm utility={service} />
        ) : (
          <Caption size="s" data-testid="service_value">
            {service.value.toLocaleString()}
          </Caption>
        )}

        <TextOverflow weight={400} data-testid="service_number">
          {service.documentNumber || "-"}
        </TextOverflow>

        <ServiceDocumentCell service={service} />

        <ServiceMenuCell service={service} />
      </StyledGridTableRow>

      <LoaderState loader={service.loader} />
    </>
  )
}

export default observer(ServiceRow)

const StyledGridTableRow = styled(GridTableRow)`
  padding: 4px 0;
  min-height: 56px;
  &:first-of-type {
    padding-top: 13px;
    min-height: 65px;
  }
  > div > p {
    font-weight: 400;
  }
`

const StyledTextOverflow = styled(TextOverflow)`
  .TextOverflow_content {
    width: 100%;
    cursor: pointer;
  }
`
