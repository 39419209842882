import React from "react"
import { useToggle } from "kui-utils"
import { Button, Grid } from "kui-basic"
import _ from "lodash"
import { PlusIcon } from "kui-icon"
import { observer } from "mobx-react"
import { InspectionLinkButtonProps } from "./types"
import InspectionCreationModal from "../../../../components/tabs/ApartmentInspectionsTab/components/InspectionCreationModal"
import useInspectionStore from "../../store"
import LinkButton from "../../../../../../components/common/LinkButton"
import { DynamicRoutes } from "../../../../../../types/route"
import useApartmentStore from "../../../../store"

const InspectionLinkButton = (props: InspectionLinkButtonProps) => {
  const { inspectionInfo, apartmentStore } = useInspectionStore()
  const { inspectionsStore } = useApartmentStore()
  const formStore = inspectionsStore.creationForm
  const { type, id } = props
  const [isOpen, open, close] = useToggle()

  if (!inspectionInfo || !apartmentStore.overviewStore.id) return null

  const label = _.capitalize(type)
  const link =
    !!id && DynamicRoutes.inspection(apartmentStore.overviewStore.id, id)

  const handleClick = () => {
    if (!id) {
      formStore.resetForm()
      open()
    }
  }

  return (
    <>
      <Grid item>
        {link ? (
          <LinkButton href={link}>{label}</LinkButton>
        ) : (
          <Button onClick={handleClick} endIcon={<PlusIcon />} size="xs">
            {label}
          </Button>
        )}
      </Grid>
      <InspectionCreationModal
        key={type}
        variant={type}
        basedOn={inspectionInfo.id}
        open={isOpen}
        handleClose={close}
      />
    </>
  )
}

export default observer(InspectionLinkButton)
