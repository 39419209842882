import React from "react"
import styled from "@emotion/styled"
import InspectionsTab from "../../tabs/InspectionsTab"
import AppraisalsTab from "../../tabs/AppraisalsTab"
import ImpairmentsTab from "../../tabs/ImpairmentsTab"
import PageTabsPanel from "../../../../../../../components/ui/PageTabsPanel"
import useTabIndex from "../../../../../../../hooks/useTabIndex"

const tabs = ["Inspections", "Impairments", "Appraisals"]

const paths = [
  `?tab=inspections&subtab=inspections`,
  `?tab=inspections&subtab=impairments`,
  `?tab=inspections&subtab=appraisals`,
]

const tabPanels = [<InspectionsTab />, <ImpairmentsTab />, <AppraisalsTab />]

const InspectionsTabs = () => {
  const activeTab = useTabIndex(paths, "subtab")

  return (
    <StyledTabsPanel
      activeTab={Number(activeTab)}
      paths={paths}
      tabs={tabs}
      tabPanels={tabPanels}
    />
  )
}

export default InspectionsTabs

const StyledTabsPanel = styled(PageTabsPanel)`
  .KUI-TabsContainer {
    background: white;
    top: 121px;
    border: none;
  }
`
