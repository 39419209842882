import React from "react"
import { Box } from "kui-basic"
import { useForm } from "react-hook-form"
import { RadioGroupWithLabel } from "kui-complex"
import { Modal, FormBottom } from "kui-crm"
import { EditDocumentFields, EditDocumentModalProps } from "./types"
import FileItem from "../UploadFiles/FileItem"
import CheckboxGroupWithTitle from "../../ui/CheckboxGroupWithTitle"
import {
  getVisibility,
  getVisibilitySettings,
} from "../../../utils/service/getVisibility"

const contractOptions = [
  { value: "service_contract", label: "Service" },
  { value: "renter_contract", label: "Rental" },
]

const visibilityOptions = [
  { name: "landlord", label: "Landlord" },
  { name: "tenant", label: "Tenant" },
]

function EditDocumentModal({
  document,
  contractType,
  handleClose,
  handleSubmit,
  index,
  requestParams,
  ...otherProps
}: EditDocumentModalProps) {
  const visibility = getVisibilitySettings(document.visibility)

  const form = useForm<EditDocumentFields>({
    defaultValues: {
      name: document.name,
      landlord: visibility.landlord,
      tenant: visibility.tenant,
      contractType,
    },
  })

  const { onChange } = form.register("name")

  const handleChange = (title: string) => {
    const extension = document.name.split(".")?.pop()
    onChange({
      target: { value: `${title}.${extension}`, name: "name" },
      type: "change",
    })
  }

  const handleFormSubmit = (data: EditDocumentFields) => {
    if (handleSubmit)
      handleSubmit(
        {
          ...data,
          visibility: getVisibility(data),
        },
        index
      )
    // @ts-ignore
    else if (typeof handleSubmit === "undefined")
      document.patchDocument(
        {
          ...data,
          visibility: getVisibility(data),
          url: document.url,
        },
        requestParams
      )
    handleClose()
  }

  return (
    <Modal title="Edit document" handleClose={handleClose} {...otherProps}>
      <form onSubmit={form.handleSubmit(handleFormSubmit)}>
        <Box pr={5} pl={5} pb={3}>
          <Box mb={2}>
            <FileItem file={document} onChange={handleChange} />
          </Box>
          {contractType && (
            <Box mb={2}>
              <RadioGroupWithLabel
                name="contractType"
                form={form}
                title="Contract"
                options={contractOptions}
              />
            </Box>
          )}
          <CheckboxGroupWithTitle
            title="Visibility"
            options={visibilityOptions}
            form={form}
          />
        </Box>
        <FormBottom label="Save" />
      </form>
    </Modal>
  )
}

export default EditDocumentModal
