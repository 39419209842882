import React from "react"
import { observer } from "mobx-react"
import { UploadedFilesGroupProps } from "./types"
import UploadedFileRow from "../UploadedFileRow"

const UploadedFilesGroup = (props: UploadedFilesGroupProps) => {
  const { files, ...other } = props

  return (
    <div data-testid="files_wrapper">
      {files.map((file) => (
        <UploadedFileRow
          key={`${file.file?.name}_${file.index}`}
          file={file}
          {...other}
        />
      ))}
    </div>
  )
}

export default observer(UploadedFilesGroup)
