import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader, Paginator } from "kui-utils"
import ApartmentInspectionStore from "./ApartmentInspectionStore"
import {
  ApartmentInspectionListModel,
  GetApartmentInspectionsResponse,
} from "../../types/api/apartmentInspectionsAPI"
import ApartmentInspectionsAgent from "../../../../agent/ApartmentInspections"
import RentalContractLiteStore from "../../../../store/templates/RentalContractLite"
import { InspectionListType } from "../../types/store/apartmentInspections"

class ContractInspectionsStore {
  loader: Loader

  paginator: Paginator

  inspections: ApartmentInspectionStore[]

  contract: RentalContractLiteStore

  type: InspectionListType

  constructor(contract: RentalContractLiteStore, type: InspectionListType) {
    this.loader = new Loader()
    this.paginator = new Paginator(5, false)
    this.inspections = []
    this.contract = contract
    this.type = type
    makeAutoObservable(this)
  }

  fetchContractInspections = async (apartmentId: number) => {
    this.loader.startLoading()

    const [err, res] = await to<GetApartmentInspectionsResponse>(
      ApartmentInspectionsAgent.getAll(
        this.type,
        apartmentId,
        this.paginator.limit,
        this.paginator.offset,
        this.contract.id
      )
    )

    if (!err && res) {
      this.updateInspectionsByContract(res)
    } else {
      this.loader.setError("fetch inspections", err)
    }

    this.loader.endLoading()
  }

  addNewInspection = (res: ApartmentInspectionListModel) => {
    this.inspections = [new ApartmentInspectionStore(res), ...this.inspections]
  }

  deleteInspectionFromList = (id: number) => {
    this.inspections = this.inspections.filter(
      (inspection) => inspection.id !== id
    )
  }

  updateInspectionsByContract = (res: GetApartmentInspectionsResponse) => {
    const mapper = (inspection: ApartmentInspectionListModel) =>
      new ApartmentInspectionStore(inspection)

    const result = this.paginator.getPageResponse<
      ApartmentInspectionListModel,
      ApartmentInspectionStore
    >(res, this.inspections, mapper)

    this.inspections = result
  }
}

export default ContractInspectionsStore
