import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Button } from "kui-basic"
import { useToggle } from "kui-utils"
import { MapIcon, TableIcon } from "kui-icon"
import SearchBlock from "../../../../components/common/SearchBlock"
import ApartmentCreationModal from "../../../../components/modals/ApartmentCreationModal"
import useApartmentsStore from "../../store"
import { ApartmentsSearchPanelProps } from "./types"

function ApartmentsSearchPanel(props: ApartmentsSearchPanelProps) {
  const { view, setView } = props
  const { filter, paginator, createApartment } = useApartmentsStore()
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()

  const toggleView = () => {
    setView((prev) => (prev === "map" ? "table" : "map"))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filter.updateSearchQuery(e.currentTarget.value)
    if (e.target.value.length === 0) {
      paginator.clearOffset()
      paginator.startFetching()
    }
  }

  return (
    <>
      <SearchBlock
        label="Objects"
        buttonHandler={handleModalOpen}
        onChange={handleChange}
        value={filter.searchQuery}
        startComponent={
          <StyledButton
            variant="orange"
            size="s"
            onClick={toggleView}
            startIcon={view === "map" ? <MapIcon /> : <TableIcon />}
          >
            {view === "map" ? "Show by table" : "Show on map"}
          </StyledButton>
        }
      />
      <ApartmentCreationModal
        open={isModalOpen}
        handleClose={handleModalClose}
        handleSubmit={createApartment}
      />
    </>
  )
}

export default observer(ApartmentsSearchPanel)

const StyledButton = styled(Button)`
  margin-right: 16px;
`
