import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import {
  Loader,
  Paginator,
  SortingFilter,
  MultistepForm,
  resHandler,
} from "kui-utils"
import { ServiceCreationFields } from "../../../../components/forms/apartmentExpenses/ServiceCreationForm/types"
import { ServiceEditingFields } from "../../../../components/forms/apartmentExpenses/ServiceEditingForm/types"
import ApartmentServiceStore, {
  ApartmentServiceFormStages,
} from "../../../../store/shared/apartmentService/ApartmentService"
import ApartmentServicesAgent from "../../../../agent/ApartmentServices"
import { ServiceModel } from "../../../../types/api/apartmentExpenses"
import RegistriesAgent from "../../../../agent/Registries"
import { ServiceRegistryResponse } from "../../../../types/api/registries"
import ApartmentLiteServicesStore from "../../../../store/shared/apartmentService/ApartmentServicesStore"
import { uploadNewFile } from "../../../../utils/agent/uploadFiles"

class ServicesRegistryStore {
  services: ApartmentServiceStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  creationForm: MultistepForm<
    ServiceEditingFields,
    typeof ApartmentServiceFormStages
  >

  constructor() {
    this.services = []
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id", "", "desc")
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.creationForm = new MultistepForm()
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<ServiceRegistryResponse>(
      RegistriesAgent.getServicesRegistry(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    if (res && !err) {
      const mapper = (service: ServiceModel) =>
        new ApartmentServiceStore(
          { ...this, serviceLoader: this.creationLoader },
          service
        )

      this.services = this.paginator.getPageResponse<
        ServiceModel,
        ApartmentServiceStore
      >(res, this.services, mapper)
    } else {
      this.loader.setError("fetch services", err)
    }
    this.loader.endLoading()
  }

  createService = async (data: ServiceCreationFields) => {
    const apartmentId = data.apartment?.id

    if (apartmentId) {
      this.creationLoader.startLoading("service creation")

      const file = await uploadNewFile(this.creationLoader, data.file)
      const body = ApartmentLiteServicesStore.getPostServiceBody(data, file)

      const response = await to<ServiceModel>(
        ApartmentServicesAgent.create(apartmentId, body)
      )

      resHandler(response, this.creationLoader, this.paginator.refresh)
    }
  }

  duplicateService = async (apartmentId: number, serviceId: number) => {
    this.creationLoader.startLoading("service duplication")

    const response = await to<ServiceModel>(
      ApartmentServicesAgent.duplicateService(apartmentId, serviceId)
    )

    resHandler(response, this.creationLoader, this.paginator.refresh)
  }

  deleteService = (id: number) => {
    this.services = this.services.filter((service) => service.id !== id)
  }
}

export default ServicesRegistryStore
