import React from "react"
import { observer } from "mobx-react"
import useApartmentStore from "../../../../../store"
import usePaginationPage from "../../../../../../../hooks/usePaginationPage"
import Loading from "../../../../../../../components/common/Loading"
import ApartmentInspectionsTable from "../../components/ApartmentInspectionsTable"

const InspectionsTab = () => {
  const { inspectionsStore, overviewStore } = useApartmentStore()
  const store = inspectionsStore.inspectionsStore

  const initInspections = async () => {
    if (overviewStore.id) {
      store.fetchEvaluationInspections(overviewStore.id)
    }
  }

  usePaginationPage(inspectionsStore.paginator, initInspections)

  if (store.loader.isLoading) {
    return <Loading height="50vh" />
  }

  return <ApartmentInspectionsTable inspectionsStore={store} />
}

export default observer(InspectionsTab)
