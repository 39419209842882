import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import ApartmentSummaryStore from "./summary/ApartmentSummary"
import ApartmentServicesStore from "./services/ApartmentServices"
import ApartmentMetersStore from "./meters/ApartmentMeters"
import ApartmentExpensesPeriod from "./ApartmentExpensesPeriod"
import HostStore from "../../../../../store/Root"
import ApartmentPageStore from "../../../store/ApartmentPageStore"
import { ContractLinkParams } from "../../../../../types/common"
import RentalContractsAgent from "../../../../../agent/RentalContracts"
import { RentalContractLiteModel } from "../../../../../types/api/rental_contract"
import DocumentsWithPaginatorStore from "../../../../../store/templates/DocumentsWithPaginatorStore"
import {
  ClientAttachmentsAgent,
  InternalAttachmentsAgent,
} from "../../../../../agent/ApartmentAttachments"
import ApartmentLiteServicesStore from "../../../../../store/shared/apartmentService/ApartmentServicesStore"
import { LastOpenPeriodParams } from "../../../../../types/store/apartmentExpenses"

class ApartmentExpensesStore {
  date: DateTime | null

  lastOpenPeriod: DateTime | null

  availableContracts: ContractLinkParams[]

  selectedContract: ContractLinkParams | null

  summaryStore: ApartmentSummaryStore

  servicesStore: ApartmentServicesStore

  metersStore: ApartmentMetersStore

  clientsAttachmentsStore: DocumentsWithPaginatorStore

  internalAttachmentsStore: DocumentsWithPaginatorStore

  periodStore: ApartmentExpensesPeriod

  apartmentStore: ApartmentPageStore

  loader: Loader

  isServiceContractPeriod: boolean

  private hostStore: HostStore

  constructor(hostStore: HostStore, apartmentStore: ApartmentPageStore) {
    this.hostStore = hostStore
    this.apartmentStore = apartmentStore
    this.date = null
    this.lastOpenPeriod = null
    this.summaryStore = new ApartmentSummaryStore(this)
    this.servicesStore = new ApartmentServicesStore(this)
    this.metersStore = new ApartmentMetersStore(apartmentStore, this)
    this.clientsAttachmentsStore = new DocumentsWithPaginatorStore(
      ClientAttachmentsAgent
    )
    this.internalAttachmentsStore = new DocumentsWithPaginatorStore(
      InternalAttachmentsAgent
    )
    this.periodStore = new ApartmentExpensesPeriod(this)
    this.loader = new Loader(true)
    this.availableContracts = []
    this.selectedContract = null
    this.isServiceContractPeriod = false
    makeAutoObservable(this, { apartmentStore: false })
  }

  fetchAvailableContracts = async (apartmentId: number, date: DateTime) => {
    this.loader.startLoading()

    const [err, res] = await to<RentalContractLiteModel[]>(
      RentalContractsAgent.getApartmentContracts(
        apartmentId,
        date.year,
        date.month
      )
    )

    if (!err && res) {
      this.updateApartmentContracts(res)
    } else {
      this.loader.setError("fetch contracts", err)
    }
  }

  fetchLastOpenPeriod = async (apartmentId: number) => {
    this.loader.startLoading()

    const response = await ApartmentLiteServicesStore.getLastOpenedPeriod(
      apartmentId
    )
    resHandler(
      response,
      this.loader,
      ({ period, id, withRenter }: LastOpenPeriodParams) => {
        if (period) {
          this.lastOpenPeriod = period
          this.date = period
        }
        this.setIsServiceContractPeriod(!!(id && !withRenter))
      },
      "getting an open period",
      {
        withEndLoading: false,
      }
    )
  }

  setDate = (date: DateTime) => {
    this.date = date
  }

  setIsServiceContractPeriod = (value: boolean) => {
    this.isServiceContractPeriod = value
  }

  setSelectedContract = (id: number) => {
    this.selectedContract =
      this.availableContractsOptions.find((contract) => contract.id === id) ||
      null
  }

  cleanAvailableContracts = () => {
    this.availableContracts = []
    this.selectedContract = null
  }

  updateApartmentContracts = (res: RentalContractLiteModel[]) => {
    if (res.length === 0) this.loader.endLoading()
    this.availableContracts = res.map((contract) => ({
      id: contract.id,
      number: contract.number,
      date: contract.start_date ? DateTime.fromISO(contract.start_date) : null,
    }))

    this.selectedContract = this.availableContracts[0] || null
    if (this.availableContracts.length) {
      this.periodStore = new ApartmentExpensesPeriod(this)
    }
  }

  updateExpensesPage = () => {
    this.date = null
    this.summaryStore = new ApartmentSummaryStore(this)
    this.servicesStore = new ApartmentServicesStore(this)
    this.metersStore = new ApartmentMetersStore(this.apartmentStore, this)
    this.clientsAttachmentsStore = new DocumentsWithPaginatorStore(
      ClientAttachmentsAgent
    )
    this.internalAttachmentsStore = new DocumentsWithPaginatorStore(
      InternalAttachmentsAgent
    )
    this.periodStore = new ApartmentExpensesPeriod(this)
    this.loader = new Loader(true)
    this.availableContracts = []
    this.selectedContract = null
  }

  get overviewStore() {
    return this.apartmentStore.overviewStore
  }

  get availableContractsOptions() {
    const contracts = this.availableContracts.map((contract) => ({
      ...contract,
      label: `${contract.number} of ${contract.date?.toFormat("dd.MM.yy")}`,
      value: contract.id,
    }))

    const serviceContract = this.overviewStore.actualServiceContract

    if (contracts.length && this.isServiceContractPeriod && serviceContract) {
      contracts.unshift({
        id: serviceContract.id,
        number: serviceContract.number,
        label: `Service ${
          serviceContract.number
        } of ${serviceContract.startDate?.toFormat("dd.MM.yy")}`,
        value: 0,
      })
    }
    return contracts
  }
}

export default ApartmentExpensesStore
