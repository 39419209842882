import React from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import { CreateModalProps } from "../../../../types/ui/modal"
import CreateServiceContractsForm from "../../forms/CreateServiceContractForm"
import { Routes } from "../../../../types/route"
import useSCsStore from "../../store"
import { ServiceContractCreationFields } from "../../forms/CreateServiceContractForm/types"

function ServiceContractCreationModal({
  isOpen,
  handleClose,
}: CreateModalProps) {
  const { createServiceContract } = useSCsStore()
  const navigate = useNavigate()

  const handleSubmit = async (data: ServiceContractCreationFields) => {
    handleClose()
    const scId = await createServiceContract(data)

    if (scId) {
      navigate(`${Routes.service_contracts}/${scId}`)
    }
  }

  return (
    <Modal open={isOpen} handleClose={handleClose} title="New service contract">
      <CreateServiceContractsForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default observer(ServiceContractCreationModal)
