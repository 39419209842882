import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import ClientPersonalInfoSchema from "./schema"
import ClientContactsFields from "./ClientContactsFields"
import ClientCarsFields from "./ClientCarsFields"
import { UserFullPersonalInfoFields } from "./types"
import DocumentsBlockWithForm from "../../../../components/common/DocumentsBlockWithForm"
import ClientPassportFields from "./ClientPassportFields"
import useClientStore from "../../store"
import useEditor from "../../../../hooks/useEditor"

function ClientPersonalInfoForm() {
  const { editor, personalInfoStore, overviewStore } = useClientStore()
  const personalInfo = personalInfoStore.fields
  const passportEditor = useEditor()

  const form = useForm<Partial<UserFullPersonalInfoFields>>({
    defaultValues: {
      emails: personalInfo?.emails ?? [],
      phoneNumbers: personalInfo?.phoneNumbers ?? [],
      documents: personalInfoStore?.documentsStore.documents ?? [],
      actualAddress: personalInfo?.address ?? "",
      countryOfStay: personalInfo?.countryOfStay,
      cars: personalInfo?.cars || [],
      passportInfo: personalInfo?.passportInfo,
    },
    resolver: yupResolver(ClientPersonalInfoSchema),
  })

  const handleSubmit = (data: Partial<UserFullPersonalInfoFields>) => {
    if (overviewStore?.id) {
      personalInfoStore?.patchClientPersonalInfo(overviewStore.id, data)
    }
    editor?.endEditing()
    passportEditor.endEditing()
  }

  useEffect(() => {
    if (editor?.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor?.blockToSendForm)()
    }
  }, [editor?.isAllowedToSendForm])

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={8}>
        <ClientContactsFields form={form} />
        <ClientPassportFields editor={passportEditor} form={form} />
        <ClientCarsFields form={form} />
      </Grid>
      <Grid item xs={4}>
        <DocumentsBlockWithForm
          form={form}
          disabled={!editor?.isEditing}
          documents={personalInfoStore?.documentsStore.documents}
          loader={personalInfoStore?.documentsStore.loader}
        />
      </Grid>
    </Grid>
  )
}

export default observer(ClientPersonalInfoForm)
