import to from "await-to-js"
import { Loader, promisesWithCallback } from "kui-utils"
import {
  FileWithVisibility,
  InputFileWithVisibilityFields,
  DocumentVisibilityAPIVariants,
  FileBodyRequest,
  ImageBodyRequest,
  UploadFileResponse,
  DocumentVisibilityVariants,
  FileParams,
} from "kui-crm"

import UploadAgent from "../../agent/Upload"
import { setFileFromFileWithVisibility } from "./reqBodyFormat"
import { matchesAPIVisibilityVariants } from "../content/matches"
import { UploadFileParams } from "../../components/common/UploadFiles/FileItem/types"
import { UploadDocumentParams } from "../../components/common/DocumentsBlockWithForm/types"

const getBodyForPostImage = (data: Partial<FileParams>) => {
  const formData = new FormData()
  if (data.file) {
    formData.append("upload", data.file, data.name ? data.name : data.file.name)
  }

  return formData
}

export const getBodyForPostFile = (
  file: InputFileWithVisibilityFields | File
) => {
  const formData = new FormData()
  if (file instanceof File) {
    formData.append("upload", file)
  } else {
    setFileFromFileWithVisibility(formData, file, "upload")
  }
  return formData
}

const getBodyForPostDocument = (file: UploadDocumentParams) => {
  const formData = new FormData()
  if (file) {
    formData.append("upload", file.file as File)
  }
  return formData
}

const getPatchFileParams = (file: InputFileWithVisibilityFields) => {
  if (file && "name" in file && "file" in file && file.file) {
    return {
      name: file.name,
      visibility: matchesAPIVisibilityVariants[
        file.visibility as DocumentVisibilityVariants
      ] as DocumentVisibilityAPIVariants,
    }
  }
  return null
}

export const getUpdatedFileParams = async (
  loader: Loader,
  document?: InputFileWithVisibilityFields | null
) => {
  const file = getPatchFileParams(document)
  const newFile = await uploadNewFile(loader, document)

  return newFile || file
}

const getBodyForFileRequest = (
  file: FileWithVisibility | UploadDocumentParams,
  res?: UploadFileResponse
) =>
  res
    ? {
        temp_file_id: res.id,
        name: file?.file?.name || `file_${res.id}`,
        visibility: matchesAPIVisibilityVariants[
          file.visibility as DocumentVisibilityVariants
        ] as DocumentVisibilityAPIVariants,
      }
    : null

const getBodyForCleanFileRequest = (file: File, res?: UploadFileResponse) =>
  res ? { temp_file_id: res.id, name: file.name } : null

const createImage = async (loader: Loader, data: Partial<FileParams>) => {
  loader.cleanError()

  const body = getBodyForPostImage(data)

  const [err, res] = await to<UploadFileResponse>(UploadAgent.uploadImage(body))

  if (err) loader.setError("load image", err)

  return res
}

const createFile = async (loader: Loader, file: FileWithVisibility | File) => {
  loader.cleanError()

  const body = getBodyForPostFile(file)

  const [err, res] = await to<UploadFileResponse>(
    UploadAgent.uploadDocument(body)
  )

  if (err) loader.setError("load document", err)

  return res
}

const createDocument = async (loader: Loader, file: UploadDocumentParams) => {
  loader.cleanError()

  const body = getBodyForPostDocument(file)

  const [err, res] = await to<UploadFileResponse>(
    UploadAgent.uploadDocument(body)
  )

  if (err) loader.setError("load document", err)

  return res
}

const uploadImages = async (
  loader: Loader,
  images: UploadFileParams[],
  callback?: Function
) => {
  const results = await promisesWithCallback(
    images.map((file) => createImage(loader, file as FileParams)),
    callback
  )
  return results
    .map((result, index) =>
      result.status === "fulfilled"
        ? {
            temp_file_id: result.value.id,
            image_name: images[index].name?.replace(/\.[^/.]+$/, ""),
          }
        : null
    )
    .filter((result) => result) as ImageBodyRequest[]
}

const uploadFile = async (
  loader: Loader,
  file: FileWithVisibility | File
): Promise<FileBodyRequest | null> => {
  let res
  if (file) {
    res = await createFile(loader, file)
  }

  return file instanceof File
    ? getBodyForCleanFileRequest(file, res)
    : getBodyForFileRequest(file, res)
}

const uploadNewFile = async (
  loader: Loader,
  document: InputFileWithVisibilityFields | File
) => {
  if (
    document &&
    (("file" in document && document.file instanceof File) ||
      document instanceof File)
  ) {
    const res = await uploadFile(loader, document)
    return res
  }
  return null
}

const uploadFiles = async (
  loader: Loader,
  files: (InputFileWithVisibilityFields | File)[]
): Promise<FileBodyRequest[]> => {
  const res = await Promise.allSettled(
    files.map((file) => uploadNewFile(loader, file))
  )

  return res
    .map((result) => (result.status === "fulfilled" ? result.value : null))
    .filter((result) => result) as FileBodyRequest[]
}

const uploadDocuments = async (
  loader: Loader,
  documents: UploadDocumentParams[]
) => {
  const results = await promisesWithCallback(
    documents.map((file) => createDocument(loader, file))
  )
  return results
    .map((result, index) =>
      result.status === "fulfilled"
        ? getBodyForFileRequest(documents[index], result.value)
        : null
    )
    .filter((result) => result) as FileBodyRequest[]
}

const uploadDocument = async (
  loader: Loader,
  file: UploadDocumentParams
): Promise<FileBodyRequest | null> => {
  const res = await createDocument(loader, file)

  return getBodyForFileRequest(file, res)
}

export {
  uploadImages,
  uploadFile,
  uploadFiles,
  uploadDocuments,
  uploadDocument,
  getPatchFileParams,
  uploadNewFile,
}
