import { addToArrayByCondition } from "kui-utils"
import { InputParams, LinkField, InspectionTypes } from "kui-crm"
import {
  ClientRoleValues,
  InspectionAndValuationTypeValues,
  InspectionTypeValues,
  TransferInspectionTypeValues,
} from "../../../../../utils/content/listsOptions"

const getInspectionCommonStepFields = (
  withType?: boolean,
  type?: InspectionTypes,
  onlyInspections?: boolean,
  fields?: any,
  withoutTenant?: boolean
): InputParams<any>[] => [
  { label: "Inspection date", name: "date", variant: "date", isRequired: true },
  ...addToArrayByCondition(withType, {
    label: "Inspection type",
    name: "type",
    variant: "select",
    options: onlyInspections
      ? InspectionTypeValues
      : InspectionAndValuationTypeValues,
    isRequired: true,
  }),
  ...addToArrayByCondition(type === "transfer", {
    label: "Transfer type",
    name: "transferType",
    variant: "select",
    options: TransferInspectionTypeValues,
    isRequired: true,
  }),
  ...addToArrayByCondition(type === "appraisal" && fields?.basedOn, {
    label: "Client",
    name: "clientRole",
    variant: "select",
    options: ClientRoleValues,
    disabled: withoutTenant,
    value: withoutTenant ? "landlord" : undefined,
    isRequired: true,
  }),
  {
    label: "Dropbox link",
    name: "dropboxLink",
    variant: "custom",
    CustomInput: LinkField,
  },
]

export default getInspectionCommonStepFields
