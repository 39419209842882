import { createContext, useContext } from "react"
import hostStore from "../../../store"
import MeterPageStore from "./MeterPageStore"

const meterStore = new MeterPageStore(hostStore)
const MeterContext = createContext(meterStore)

const useMeterStore = () => useContext(MeterContext)

export default useMeterStore
