import { DateTime } from "luxon"
import to from "await-to-js"
import { makeAutoObservable } from "mobx"
import { Loader } from "kui-utils"
import UserLiteStore from "../../../../../store/templates/UserLite"
import {
  ApartmentExpensesPeriodModel,
  ExpensesPeriodClosingConditions,
} from "../types/api/expensesPeriodAPI"
import ApartmentExpensesAgent from "../../../../../agent/ApartmentExpenses"
import ApartmentExpensesStore from "./ApartmentExpenses"
import { ClosePeriodFormFields } from "../forms/summary/ClosePeriodForm/types"
import { uploadNewFile } from "../../../../../utils/agent/uploadFiles"
import { CheckParams } from "../../../../../types/common"
import { closingConditionLabel } from "../../../../../utils/content/values"

class ApartmentExpensesPeriod {
  id: number | null

  closingDate: DateTime | null

  closedBy: UserLiteStore | null

  isClosed: boolean

  actionLoader: Loader

  expensesStore: ApartmentExpensesStore

  isPrevPeriodsClosed: boolean

  isLastMonthOfContract: boolean

  isDepositCalculated: boolean

  checkoutDate: DateTime | null

  landlord: UserLiteStore | null

  tenant: UserLiteStore | null

  closingProgress: CheckParams[]

  constructor(expensesStore: ApartmentExpensesStore) {
    this.id = null
    this.closingDate = null
    this.closedBy = null
    this.isClosed = false
    this.actionLoader = new Loader()
    this.expensesStore = expensesStore
    this.isPrevPeriodsClosed = true
    this.isLastMonthOfContract = false
    this.isDepositCalculated = false
    this.checkoutDate = null
    this.landlord = null
    this.tenant = null
    this.closingProgress = []
    makeAutoObservable(this)
  }

  getPeriodInfo = async (
    apartmentId: number,
    contractId: number | undefined,
    date: DateTime
  ) => {
    this.expensesStore.loader.startLoading()

    const [err, res] = await to<ApartmentExpensesPeriodModel>(
      ApartmentExpensesAgent.getPeriodStatus(
        apartmentId,
        contractId,
        date.year,
        date.month
      )
    )

    if (res && !err) {
      this.updatePeriodInfo(res)
    } else {
      this.cleanPeriodId()
      this.expensesStore.loader.setError("fetch period status", err)
    }
    this.expensesStore.loader.endLoading()
  }

  cleanPeriodId = () => {
    this.id = null
  }

  closePeriod = async (apartmentId: number, data: ClosePeriodFormFields) => {
    if (this.id) {
      this.actionLoader.startLoading("period closing")

      const file = await uploadNewFile(this.actionLoader, data.paymentOrder)
      const body = { payout_order_document: file }

      const [err, res] = await to<ApartmentExpensesPeriodModel>(
        ApartmentExpensesAgent.closePeriod(apartmentId, this.id, body)
      )

      if (res && !err) {
        this.updatePeriodInfo(res)
      } else {
        this.actionLoader.setError("period closing", err)
      }
      this.actionLoader.endLoading()
    }
  }

  updatePeriodInfo = (periodInfo: ApartmentExpensesPeriodModel) => {
    this.id = periodInfo.id
    this.closingDate = periodInfo.close_datetime
      ? DateTime.fromISO(periodInfo.close_datetime)
      : null
    this.closedBy = periodInfo.who_close
      ? UserLiteStore.initFromLiteUserModel(periodInfo.who_close)
      : null
    this.isClosed = periodInfo.status === "closed"
    this.isPrevPeriodsClosed = !!periodInfo.id
    this.isLastMonthOfContract = !!periodInfo.is_last_for_rental_contract
    this.checkoutDate = periodInfo.rental_contract_end_at
      ? DateTime.fromISO(periodInfo.rental_contract_end_at)
      : null
    this.landlord = periodInfo.owner
      ? UserLiteStore.initFromLiteUserModel(periodInfo.owner)
      : null
    this.tenant = periodInfo.renter
      ? UserLiteStore.initFromLiteUserModel(periodInfo.renter)
      : null
    this.isDepositCalculated = periodInfo.is_deposit_calculated
    if (periodInfo.closing_conditions) {
      this.closingProgress = ApartmentExpensesPeriod.getClosingProgress(
        periodInfo.closing_conditions
      )
    }
  }

  setDepositCalculated = () => {
    this.isDepositCalculated = true
  }

  get formattedClosingDate() {
    const isPeriodClosedToday =
      Math.abs(Number(this.closingDate?.diffNow(["hours"]).toObject().hours)) <
      24

    return isPeriodClosedToday
      ? this.closingDate?.setLocale("en").toRelative()
      : this.closingDate?.toFormat("dd.MM.yyyy")
  }

  get canBeEdited() {
    return this.isPrevPeriodsClosed && !this.isClosed
  }

  get canBeClosed() {
    return (
      this.closingProgress.filter((step) => !step.checked).length === 0 ||
      !this.isLastMonthOfContract
    )
  }

  static getClosingProgress = (fields: ExpensesPeriodClosingConditions) =>
    Object.keys(fields).map((condition) => ({
      label:
        closingConditionLabel[condition as keyof typeof closingConditionLabel],
      checked: !!fields[condition as keyof typeof fields],
    }))
}

export default ApartmentExpensesPeriod
