import * as React from "react"
import { useMemo, useState } from "react"
import { observer } from "mobx-react"
import InputWithAutocomplete from "../../ui/InputWithAutocomplete"
import { MetroStationParams } from "../../../types/api/metro"
import useStore from "../../../hooks/useStore"
import { MetroAutocompleteProps } from "./types"
import filterItemsByQuery from "../../../utils/service/filter"
import MetroOption from "./MetroOption"

function MetroAutocomplete<TFormValues extends object>({
  form,
  name,
  label,
  hideName,
  defaultValue,
  handleChange,
  ...otherProps
}: MetroAutocompleteProps<TFormValues>) {
  const [searchQuery, setSearchQuery] = useState("")
  const {
    metroStore: { metroStations },
  } = useStore()
  const filteredMetroStations = useMemo(
    () => filterItemsByQuery(metroStations, searchQuery, ["name"]),
    [metroStations, searchQuery]
  )

  const onSelectItem = (item: MetroStationParams | null) => {
    form.setValue(hideName, item?.id)
    form.setValue(name, item?.name)
    if (handleChange) {
      handleChange(item?.name, item?.id)
    }
  }

  const onSearchItem = (value: string) => {
    setSearchQuery(value)
  }

  return (
    <InputWithAutocomplete
      renderOption={(option: MetroStationParams) => (
        <MetroOption metro={option} />
      )}
      options={filteredMetroStations}
      label={label}
      form={form}
      name={name}
      hideName={hideName}
      onSelectItem={onSelectItem}
      onSearchItem={onSearchItem}
      {...otherProps}
    />
  )
}

MetroAutocomplete.defaultProps = {
  label: "Metro",
  hideName: "metro_id",
  name: "metro",
  handleChange: () => null,
}

export default observer(MetroAutocomplete)
