export const dashboardLinksFields = [
  { name: "tenantOverduePayments", label: "Overdue tenant payments" },
  { name: "landlordOverduePayments", label: "Overdue landlord payments" },
  { name: "notClosedPeriods", label: "Not closed periods" },
  { name: "overdueInsurances", label: "Overdue insurances" },
]

export const dashboardCardsFields = [
  { name: "metersWithDeviating", label: "Meters with deviating readings" },
  { name: "companiesRequisites", label: "Objects without services" },
  { name: "autoMeters", label: "Auto metering devices" },
  { name: "metersVerifications", label: "Meters require verification" },
  { name: "apartmentsServices", label: "Companies with wrong requisites" },
  { name: "closingServiceContracts", label: "Closing service contracts" },
  { name: "closingRentalContracts", label: "Closing rental contracts" },
]
