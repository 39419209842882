import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import {
  Loader,
  getPhoneNumberFromPhoneParams,
  getPhoneParamsFromString,
  clearNotValidFields,
} from "kui-utils"
import DocumentsStore from "../../../store/templates/DocumentsStore"
import { ClientCompanyInfoParams } from "../types/store/clientInfo"
import ClientPageStore from "./ClientPageStore"
import { UserCompanyInfoAgent } from "../../../agent/Users"
import { ClientCompanyModel } from "../types/api/clientAPI"
import { ClientCompanyInfoFormFields } from "../forms/ClientCompanyInfoForm/types"

class ClientCompanyInfoStore {
  loader: Loader

  actionLoader: Loader

  documentsStore: DocumentsStore

  fields: ClientCompanyInfoParams | null = null

  clientStore: ClientPageStore

  constructor(clientStore: ClientPageStore) {
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.documentsStore = new DocumentsStore(UserCompanyInfoAgent)
    this.clientStore = clientStore
    makeAutoObservable(this)
  }

  fetchClientCompanyInfo = async (userId: number) => {
    this.loader.startLoading()

    const [err, res] = await to<ClientCompanyModel>(
      UserCompanyInfoAgent.getById(userId)
    )

    if (res && !err) {
      this.updateInfoFields(res)
    } else {
      this.loader.setError("fetch client company info", err)
    }
    this.loader.endLoading()
  }

  patchClientCompanyInfo = async (
    userId: number,
    data: Partial<ClientCompanyInfoFormFields>
  ) => {
    this.actionLoader.startLoading("client info changes")

    await Promise.allSettled([
      this.editClientPersonalInfo(userId, data),
      this.documentsStore.patchDocuments(data.documents!, userId),
    ])

    this.actionLoader.endLoading()
  }

  editClientPersonalInfo = async (
    userId: number,
    data: Partial<ClientCompanyInfoFormFields>
  ) => {
    const body = ClientCompanyInfoStore.getPatchCompanyInfoBody(data)

    const [err, res] = await to(
      UserCompanyInfoAgent.patchCompanyInfo(userId, body)
    )
    if (res && !err) {
      this.updateInfoFields(res)
      this.clientStore.overviewStore?.updateClientOverviewInfo(res)
    } else {
      this.actionLoader.setError("patch client company info", err)
    }
  }

  updateInfoFields = (client: Partial<ClientCompanyModel>) => {
    this.fields = ClientCompanyInfoStore.getCompanyInfo(client)
  }

  static getCompanyInfo = ({
    representative,
    legal_entity,
  }: Partial<ClientCompanyModel>) => ({
    representative: {
      firstName: representative?.first_name || "",
      lastName: representative?.last_name || "",
      middleName: representative?.middle_name || "",
      jobTitle: representative?.job_title || "",
      phone: representative?.phone
        ? getPhoneParamsFromString(representative.phone)
        : null,
      email: representative?.email || "",
    },
    company: {
      name: legal_entity?.name || "",
      site: legal_entity?.site || "",
      phone: legal_entity?.phone
        ? getPhoneParamsFromString(legal_entity.phone)
        : null,
      email: legal_entity?.email || "",
      physicalAddress: legal_entity?.address || "",
      mailingAddress: legal_entity?.mailing_address || "",
      legalAddress: legal_entity?.legal_address || "",
      TIN: legal_entity?.INN || "",
      PSRN: legal_entity?.OGRN || "",
      TRRC: legal_entity?.KPP || "",
    },
  })

  static getPatchCompanyInfoBody = ({
    company,
    representative,
  }: Partial<ClientCompanyInfoFormFields>) => ({
    legal_entity: clearNotValidFields({
      name: company?.name || null,
      site: company?.site || null,
      phone: company?.phone?.phoneNumber
        ? getPhoneNumberFromPhoneParams(company.phone)
        : null,
      email: company?.email || null,
      address: company?.physicalAddress || null,
      mailing_address: company?.mailingAddress || null,
      legal_address: company?.legalAddress || null,
      INN: company?.TIN || null,
      OGRN: company?.PSRN || null,
      KPP: company?.TRRC || null,
    }),
    representative: clearNotValidFields({
      first_name: representative?.firstName || null,
      middle_name: representative?.middleName || null,
      last_name: representative?.lastName || null,
      email: representative?.email || null,
      phone: representative?.phone?.phoneNumber
        ? getPhoneNumberFromPhoneParams(representative.phone)
        : null,
      job_title: representative?.jobTitle || null,
    }),
  })
}

export default ClientCompanyInfoStore
