import React from "react"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import { copyInfo, useToggle } from "kui-utils"
import PageHeader from "../../../../../components/common/PageHeader"
import { Routes } from "../../../../../types/route"
import useApartmentStore from "../../../store"
import ConfirmationModal from "../../../../../components/common/ConfirmationModal"

const APP_URL = process.env.REACT_APP_URL
const MAIN_URL = "https://maroom.ru"

function ApartmentHeader() {
  const { overviewStore, archiveApartment } = useApartmentStore()
  const [isOpenModal, openModal, closeModal] = useToggle()

  const navigate = useNavigate()

  const handleClick = () => {
    navigate(Routes.apartments)
  }

  const handleArchive = async () => {
    await archiveApartment(overviewStore.id!)
    navigate(Routes.apartments)
    handleClick()
  }

  const copyLink = () => {
    if (overviewStore.id) {
      copyInfo(`${APP_URL}${Routes.apartments}/${overviewStore.id}`)
    }
  }

  const openOnMainSite = () => {
    if (overviewStore.id) {
      window.open(`${MAIN_URL}/apartments/${overviewStore.id}`)
    }
  }

  const routes = [
    { link: `${Routes.apartments}`, title: "Objects" },
    { link: null, title: overviewStore.address },
  ]

  const menuOptions = [
    { label: "Copy object link", handleClick: copyLink },
    { label: "View object on the site", handleClick: openOnMainSite },
    { label: "Archive object", handleClick: openModal },
  ]

  return (
    <>
      <PageHeader
        handleClick={handleClick}
        options={menuOptions}
        breadcrumbs={routes}
      />

      <ConfirmationModal
        label="Are you sure you want to archive the apartment?"
        name="apartmentArchiving"
        open={isOpenModal}
        handleClose={closeModal}
        handleSubmit={handleArchive}
      />
    </>
  )
}

export default observer(ApartmentHeader)
