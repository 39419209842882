import React, { DragEvent, useRef, useState } from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import _ from "lodash"
import Loading from "../../../../../../../../../components/common/Loading"
import { ApartmentPhotoDragCardProps } from "./types"
import ApartmentPhotoCard from "../../../../../../../../../components/common/PhotoCard"
import usePromotionStore from "../../../../../store"

function ApartmentPhotoDragCard(props: ApartmentPhotoDragCardProps) {
  const { image, index, isMain, isPreview, ...imageCardProps } = props
  const [grab, setGrab] = useState(true)
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()
  const imageRef = useRef<HTMLImageElement>(null)

  const setCursorGrab = () => setGrab(true)

  const dragStart = (e: DragEvent<HTMLDivElement>) => {
    setCursorGrab()
    if (imageRef.current)
      e.dataTransfer.setDragImage(imageRef.current, 100, 100)
    imagesStore?.setDragImage(image.id, index + 1)
  }

  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setGrab(false)
  }

  const drop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setCursorGrab()
    if (image.dragImage) {
      const dragImage = imagesStore?.getImageById(image.dragImage.id)

      if (dragImage) {
        if (isPreview) {
          await imagesStore.changePreviewPriority(dragImage, image)
        } else {
          await imagesStore.changePublicPriority(dragImage, image)
        }
      }
    }
  }

  return (
    <StyledWrapper
      data-testid={`image_card_${image.id}`}
      item
      draggable
      onDragStart={dragStart}
      onDragOver={dragOver}
      onDragLeave={setCursorGrab}
      onDrop={drop}
    >
      <StyledDragImage
        ref={imageRef}
        src={image.mediumImageUrl}
        alt={image.name}
      />
      <ApartmentPhotoCard
        image={image}
        index={index}
        isGrab={grab}
        {...imageCardProps}
      >
        {((isPreview && image.isPreviewLoading) ||
          (!isPreview && image.isPublicLoading)) && (
          <StyledLoadingWrapper>
            <Loading height="100%" />
          </StyledLoadingWrapper>
        )}
        <StyledPriority>
          {isMain
            ? "Main"
            : _.padStart(
                `${isPreview ? image.previewPriority : image.priority}`,
                2,
                "0"
              )}
        </StyledPriority>
      </ApartmentPhotoCard>
    </StyledWrapper>
  )
}

export default observer(ApartmentPhotoDragCard)

const StyledPriority = styled.span`
  position: absolute;
  //width: 37px;
  height: 16px;
  border-radius: 100px;
  background: ${({ theme }) => theme.palette.grey.zero};
  bottom: 8px;
  left: 8px;
  color: ${({ theme }) => theme.palette.grey.fiftyP};
  text-align: center;
  font-size: 12px;
  padding: 0px 10px;
  min-width: 40px;
`

const StyledWrapper = styled(Grid)`
  position: relative;
`

const StyledLoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
`

const StyledDragImage = styled.img`
  position: absolute;
  z-index: -1;
  border-radius: 12px;
  overflow: hidden;
  height: 136px;
  width: 136px;
  object-fit: cover;
`
