import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import {
  PartialPayerFields,
  TariffGroupAutocompleteFields,
  CompanyAutocompleteFields,
  MeterFeaturesFormFields,
  ElectricityMeterTypes,
} from "kui-crm"

import { ElectricityMeterParams } from "kui-crm/types"
import { PatchElectricityMeterParams } from "../../types/api/expensesMetersAPI"
import ApartmentMetersAgent from "../../../../../../agent/ApartmentMeters"
import {
  CounterInterface,
  CounterTypes,
} from "../../types/store/expensesMeters"
import ApartmentMeterTariffValues from "./ApartmentMeterTariffValues"
import { SubjectRoles } from "../../../../../../types/common"
import { matchesSubjectRoles } from "../../../../../../utils/content/matches"
import UserLiteStore from "../../../../../../store/templates/UserLite"
import MeterAgent from "../../../../../../agent/Meter"
import {
  PutMeterRequest,
  PutMeterResponse,
} from "../../../../../../types/api/meters"
import { clearNotValidFields } from "../../../../../../utils/service/mapper"
import ApartmentElectricityMetersStore from "./ApartmentElectricityMeters"
import {
  getCommonMeterParams,
  getUtilitiesPayerParams,
} from "../../../../../../utils/service/apiMapper"

class ApartmentElectricityMeterStore implements CounterInterface {
  id: number

  isArchived: boolean

  type: ElectricityMeterTypes

  number: string

  tariff: TariffGroupAutocompleteFields | null

  company: CompanyAutocompleteFields | null

  nextCheck: DateTime | null

  startDate: DateTime | null

  t1: ApartmentMeterTariffValues

  t2?: ApartmentMeterTariffValues | null

  t3?: ApartmentMeterTariffValues | null

  resource: CounterTypes

  payer: UserLiteStore | null

  paymentsInfo: PartialPayerFields | null

  payerRole: SubjectRoles | null

  loader: Loader

  operationalAccountNumber: string

  metersStore: ApartmentElectricityMetersStore

  companyIsAdministrative: boolean

  withAutoSending: boolean

  noTariff: boolean

  price: number | null

  hasAverageValue: boolean

  constructor(
    counter: ElectricityMeterParams,
    metersStore: ApartmentElectricityMetersStore
  ) {
    const role = counter.payer?.payer_type
      ? (matchesSubjectRoles[counter.payer.payer_type] as SubjectRoles)
      : null

    this.isArchived = !!counter.archived
    this.id = counter.id
    this.type = counter.type as ElectricityMeterTypes
    this.number = counter.passport_number
    this.tariff = counter.tariff
      ? {
          id: counter.tariff.id,
          name: counter.tariff.code_name,
        }
      : null
    this.company = counter.company
      ? {
          id: counter.company.id,
          name: counter.company.name,
        }
      : null
    this.nextCheck = counter.check_date
      ? DateTime.fromISO(counter.check_date)
      : null
    this.startDate = counter.activation_date
      ? DateTime.fromISO(counter.activation_date)
      : null
    this.t1 = new ApartmentMeterTariffValues(
      counter.values.T1,
      counter.tariff?.T1,
      this,
      "T1"
    )
    if (counter.values.T2 && counter.tariff?.T2)
      this.t2 = new ApartmentMeterTariffValues(
        counter.values.T2,
        counter.tariff.T2,
        this,
        "T2"
      )
    if (counter.values.T3 && counter.tariff?.T3)
      this.t3 = new ApartmentMeterTariffValues(
        counter.values.T3,
        counter.tariff.T3,
        this,
        "T3"
      )
    this.resource = "electricity"
    this.payer = counter.payer
      ? UserLiteStore.initFromLiteUserModel(counter.payer, role)
      : null
    this.paymentsInfo = counter.payer?.payments_info
      ? getUtilitiesPayerParams(counter.payer.payments_info)
      : null
    this.payerRole = counter.payer?.payer_type
      ? (matchesSubjectRoles[counter.payer.payer_type] as SubjectRoles)
      : null
    this.loader = new Loader()
    this.operationalAccountNumber = counter.operating_account_number || ""
    this.metersStore = metersStore
    this.companyIsAdministrative = counter.company_is_administrative
    this.withAutoSending = counter.auto_sending
    this.noTariff = counter.no_tariff
    this.price = counter.cost ? Number(counter.cost) : null
    this.hasAverageValue = counter.is_avg
    makeAutoObservable(this)
  }

  patchCounter = async (
    data: Partial<MeterFeaturesFormFields>,
    type?: string
  ) => {
    const { date } = this.metersStore.expensesStore

    if (date) {
      this.loader.startLoading("meter changes")
      const formattedData = type ? { [type]: data } : data
      const body: PatchElectricityMeterParams =
        this.getPatchCounterRequestBody(formattedData)

      const [err, res] = await to<ElectricityMeterParams>(
        ApartmentMetersAgent.patchElectricityMeter(
          this.id,
          body,
          date.year,
          date.month
        )
      )

      if (!err && res) {
        this.updateMeter(res)
      } else {
        this.loader.setError(`patch meter`, err)
      }
      this.loader.endLoading()

      return !!err
    }
    return false
  }

  postMeterValue = async (
    value: string,
    periodId: number,
    type?: string
  ): Promise<[Error | null, PutMeterResponse | undefined]> => {
    const body = {
      [type!]: value,
      period_id: periodId,
    } as PutMeterRequest
    return to(MeterAgent.putValue(this.id, body, "electricity"))
  }

  postMeterCost = async (apartmentId: number, value: number) => {
    const body = { value }
    const [err] = await to(
      ApartmentMetersAgent.postCost(apartmentId, this.id, body, this.resource)
    )

    if (err) {
      this.loader.setError("edit meter cost", err)
    }
  }

  deactivate = async () => {
    await this.patchCounter({ archived: true })
  }

  activate = async () => {
    await this.patchCounter({ archived: false })
  }

  setArchived = () => {
    this.isArchived = true
  }

  updateMeter = (counter: Partial<ElectricityMeterParams>) => {
    if (
      counter.operating_account_number &&
      this.operationalAccountNumber !== counter.operating_account_number
    ) {
      this.metersStore.updateOperatingNumber(counter.operating_account_number)
    }
    if (typeof counter.archived !== "undefined")
      this.isArchived = counter.archived
    if (counter.type) this.type = counter.type
    if (counter.passport_number) this.number = counter.passport_number
    if (counter.tariff) {
      this.tariff = {
        id: counter.tariff.id,
        name: counter.tariff.code_name,
      }
    }
    if (counter.company) {
      this.company = {
        id: counter.company.id,
        name: counter.company.name,
      }
    }
    if (counter.check_date)
      this.nextCheck = DateTime.fromISO(counter.check_date)
    if (counter.activation_date)
      this.startDate = DateTime.fromISO(counter.activation_date)
    if (counter.values) {
      this.t1.updateMeter(counter.values.T1, counter.tariff?.T1)
      if (counter.values.T2?.counter_value)
        this.t2?.updateMeter(counter.values.T2, counter.tariff?.T2)
      if (counter.values.T3?.counter_value)
        this.t3?.updateMeter(counter.values.T3, counter.tariff?.T3)
    }
    if (typeof counter.company_is_administrative === "boolean")
      this.companyIsAdministrative = counter.company_is_administrative
  }

  updateOperatingNumber = (operatingNumber: string) => {
    this.operationalAccountNumber = operatingNumber
  }

  get title() {
    return this.type
  }

  get archivedTitle() {
    return `Electricity - ${this.title}`
  }

  get totalPrice() {
    if (this.noTariff) {
      return this.price
    }
    return (
      (this.t1.totalPrice ?? 0) +
      (this.t2?.totalPrice ?? 0) +
      (this.t3?.totalPrice ?? 0)
    )
  }

  get totalConsumption() {
    return (
      this.t1.consumption +
      (this.t2?.consumption ?? 0) +
      (this.t3?.consumption ?? 0)
    )
  }

  getPatchCounterRequestBody = (data: Partial<MeterFeaturesFormFields>) =>
    clearNotValidFields({
      ...getCommonMeterParams(data),
      values:
        data.initialValueT1 || data.initialValueT2 || data.initialValueT3
          ? {
              T1: data.initialValueT1,
              T2: data.initialValueT2,
              T3: data.initialValueT3,
            }
          : undefined,
      ...(String(data.archived) && {
        archived: data.archived,
      }),
    })
}

export default ApartmentElectricityMeterStore
