import React from "react"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import { ServiceRegistryRowProps } from "./types"
import TableRow from "../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../components/ui/TableCell"
import ApartmentLinkCell from "../../../../../../components/common/ApartmentLinkCell"
import TypographyMoney from "../../../../../../components/common/TypographyMoney"
import TextOverflowWithComment from "../../../../../../components/common/TextOverflowWithComment"
import EditServiceReportForm from "../../../forms/EditServiceReportForm"
import SquareFileBillet from "../../../../../../components/common/SquareFileBillet"
import ServiceMenuCell from "../ServiceMenuCell"
import ServiceEditingModal from "../../../../../../components/modals/ServiceEditingModal"
import ServicePeriodCell from "../ServicePeriodCell"

const ServiceRegistryRow = (props: ServiceRegistryRowProps) => {
  const [isEditModalOpen, handleEditModalOpen, handleEditModalClose] =
    useToggle()
  const { service, isDark } = props

  return (
    <>
      <TableRow isDark={isDark}>
        <TableCell isFirst textColor="gray" onClick={handleEditModalOpen}>
          {service.id}
        </TableCell>
        <TableCell onClick={handleEditModalOpen}>
          {service.apartment?.folderNumber}
        </TableCell>
        <TableCell onClick={handleEditModalOpen}>
          <ApartmentLinkCell apartment={service.apartment} />
        </TableCell>
        <TableCell onClick={handleEditModalOpen}>
          <ServicePeriodCell service={service} />
        </TableCell>
        <TableCell onClick={handleEditModalOpen}>
          {service.documentNumber ? `№ ${service.documentNumber}` : "-"}
        </TableCell>
        <TableCell onClick={handleEditModalOpen}>
          <TypographyMoney value={service.value} weight={500} size="s" />
        </TableCell>
        <TableCell width="17%" onClick={handleEditModalOpen}>
          <TextOverflowWithComment
            name={service.commentAuthor?.fullName}
            comment={service.comment}
            data-testid="service_title"
            weight={500}
            color="seventy"
            size="s"
          >
            {service.title}
          </TextOverflowWithComment>
        </TableCell>
        <TableCell>
          <EditServiceReportForm service={service} />
        </TableCell>
        <TableCell>
          {service.invoice ? (
            <SquareFileBillet
              name={service.invoice.name}
              link={service.invoice.url}
            />
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell isLast>
          <ServiceMenuCell service={service} />
        </TableCell>
      </TableRow>

      <ServiceEditingModal
        withApartment
        open={isEditModalOpen}
        handleClose={handleEditModalClose}
        service={service}
        isPeriodClosed={false}
        hasRentalContract={service.apartment?.hasRentalContract}
      />
    </>
  )
}

export default observer(ServiceRegistryRow)
