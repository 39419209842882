import React, { useRef } from "react"
import { Loader } from "kui-utils"
import { InputFileWithVisibility, LoaderState } from "kui-crm"
import { ServiceDocumentFieldProps } from "./types"
import { ParsedServiceModel } from "../../../../types/api/apartmentExpenses"
import { matchesSubjectRoles } from "../../../../utils/content/matches"
import { SubjectRoles } from "../../../../types/common"
import { parseFile } from "./service"
import { ServiceMainInfoFormFields } from "../ServiceMainInfoForm/types"

const ServiceDocumentField = (props: ServiceDocumentFieldProps) => {
  const { form, formMaker, withApartment, setCompanyDisabled, apartmentId } =
    props
  const loaderRef = useRef(new Loader())
  const loader = loaderRef.current

  const updateField = (
    name: keyof ServiceMainInfoFormFields,
    service: ParsedServiceModel
  ) => {
    if (!form.getValues(name) && service[name as keyof ParsedServiceModel]) {
      form.setValue(name, service[name as keyof ParsedServiceModel] as any)
    }
  }

  const updateForm = (service: ParsedServiceModel | null) => {
    if (service) {
      loader.cleanError()
      updateField("name", service)
      updateField("purpose", service)
      form.setValue("price", Number(service.value))
      formMaker.updateFormFields({
        paymentMadeBy: service.payment_made_by
          ? (matchesSubjectRoles[service.payment_made_by] as SubjectRoles)
          : null,
        refundFrom: service.payment_made_by
          ? (matchesSubjectRoles[service.refund_from] as SubjectRoles)
          : null,
      })

      if (withApartment && !form.getValues("apartment") && service.apartment) {
        form.setValue("apartment.id", service.apartment.id)
        form.setValue("apartment.address", service.apartment.address)
      }
      if (service.company) {
        form.setValue("company.id", service.company.id)
        form.setValue("company.name", service.company.name)
        form.setValue("company.logo", service.company.logo)
        setCompanyDisabled(true)
      }
      if (service.has_new_requisites) {
        form.setError("file.file", {
          message:
            "Attention! The receipt contains new requisites for the company!",
        })
      }

      if (service.wrong_object_requisites) {
        loader.setError(
          "parsing receipt: \nWrong Payer code or Operational account number"
        )
      }
    }
  }

  const handleChange = (file: File | null) => {
    if (file) parseFile(apartmentId, loader, file, updateForm)
    else {
      setCompanyDisabled(false)
      form.clearErrors("file.file")
    }
  }

  return (
    <>
      <InputFileWithVisibility
        withEdit
        label="Invoice"
        name="file"
        form={form}
        onChange={handleChange}
      />
      <LoaderState loader={loader} />
    </>
  )
}

export default ServiceDocumentField
