import React, { useMemo } from "react"
import { observer } from "mobx-react"
import ApartmentPhotoSliderHeader from "../ApartmentPhotoSliderHeader"
import { ApartmentPhotoSliderProps } from "./types"
import FullSliderModal from "../../../../../../../../../components/common/FullSliderModal"

function ApartmentPhotoSlider({ images, ...props }: ApartmentPhotoSliderProps) {
  const formattedImages = useMemo(
    () => images.map((image) => image.largeImageUrl),
    [images]
  )

  return (
    <FullSliderModal
      images={formattedImages}
      renderHeaderComponent={(index, slider, handleClose) => (
        <ApartmentPhotoSliderHeader
          currentImage={images[index]}
          slider={slider}
          handleClose={handleClose}
          images={formattedImages}
        />
      )}
      {...props}
    />
  )
}

export default observer(ApartmentPhotoSlider)
