import React from "react"
import { Box, Heading } from "kui-basic"
import styled from "@emotion/styled"
import _ from "lodash"
import { ChangesTableProps } from "./types"
import ChangeLine from "./ChangeLine"

const ChangesTable = (props: ChangesTableProps) => {
  const { changes, variant, onDelete } = props

  if (changes.length === 0) return null

  return (
    <>
      <Box pt={3} mb={2}>
        <Heading size="h4">{_.capitalize(variant)} changes</Heading>
      </Box>
      <StyledWrapper>
        {changes.map((change) => (
          <ChangeLine
            onDelete={onDelete}
            variant={variant}
            key={change.id}
            changesGroup={change}
          />
        ))}
      </StyledWrapper>
    </>
  )
}

export default ChangesTable

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.palette.background.light1};
  border-radius: 16px;
  padding: 16px;
`
