/* global google */
import { makeAutoObservable } from "mobx"
import RootStore from "../Root"

class MapStore {
  rootStore: RootStore

  map: google.maps.Map | null

  marker: google.maps.Marker | null

  constructor(rootStore: RootStore) {
    this.map = null
    this.rootStore = rootStore
    this.marker = null
    makeAutoObservable(this, { rootStore: false })
  }

  setMap = (map: google.maps.Map) => {
    this.map = map
  }

  setMarker = (marker: google.maps.Marker) => {
    this.marker = marker
  }

  reset = () => {
    this.map = null
  }
}

export default MapStore
