import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { useToggle } from "kui-utils"
import EditButton from "../../../../components/ui/EditButton"
import useRCStore from "../../store"
import RCClosingButtons from "../RCClosingButtons"
import EditContractModal from "../../../../components/modals/EditContractModal"
import { RCFieldsHeaderProps } from "./types"
import { RCInfoFormFields } from "../RCInfoFields/types"

const RCFieldsHeader = ({ form }: RCFieldsHeaderProps) => {
  const {
    editor,
    contractInfoStore,
    patchRentalContract,
    usedPendingDates,
    lastOpenPeriod,
  } = useRCStore()
  const [isModalOpen, handleOpen, handleClose] = useToggle()

  const handleSubmit = (data: RCInfoFormFields) => {
    const scStartDate = contractInfoStore.serviceContract?.startDate
    const isOlderStartDate =
      scStartDate &&
      data.signDate?.diff(scStartDate, "days").toObject().days! >= 0

    if (!contractInfoStore.isPending || isOlderStartDate) {
      form.clearErrors("signDate")
      patchRentalContract(data)
      editor.endEditing()
    } else {
      form.setError("signDate", {
        message: "Must be more than the start date of the service contract",
      })
    }
    form.reset()
  }

  if (contractInfoStore.isClosed) {
    return null
  }

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <RCClosingButtons />

        <StyledEditButtonWrapper item>
          <EditButton
            isEditing={editor.isEditing}
            onSaveHandler={form.handleSubmit(handleSubmit)}
            onEditHandler={handleOpen}
          />
        </StyledEditButtonWrapper>
      </Grid>

      <EditContractModal
        open={isModalOpen}
        handleClose={handleClose}
        editor={editor}
        form={form}
        usedDates={usedPendingDates}
        lastOpenPeriod={lastOpenPeriod}
      />
    </>
  )
}

export default observer(RCFieldsHeader)

const StyledEditButtonWrapper = styled(Grid)`
  margin-left: auto;
`
