import * as React from "react"
import { observer } from "mobx-react"
import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Avatar } from "kui-complex"
import styled from "@emotion/styled"
import { useToggle } from "kui-utils"
import useStore from "../../../hooks/useStore"
import DropdownMenu from "../../ui/DropdownMenu"

function AvatarDropdown() {
  const [isMenuOpen, handleMenuOpen, handleMenuClose] = useToggle()
  const buttonRef = useRef(null)
  const navigate = useNavigate()
  const {
    authStore: { logout, isAuthed },
    cabinetStore: { fullName, getUser },
  } = useStore()

  useEffect(() => {
    if (isAuthed && !fullName) {
      getUser()
    }
  }, [isAuthed, fullName])

  const logoutHandle = async () => {
    await logout()
    navigate("/")
  }

  const menuOptions = [{ label: "Logout", handleClick: logoutHandle }]

  return (
    <div>
      <Avatar
        ref={buttonRef}
        size="s"
        fullName={fullName}
        onClick={handleMenuOpen}
        data-testid="avatar"
      />
      <StyledDropdownMenu
        isOpen={isMenuOpen}
        handleClose={handleMenuClose}
        buttonRef={buttonRef}
        options={menuOptions}
      />
    </div>
  )
}

export default observer(AvatarDropdown)

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 200px;
  margin-top: 8px;
`
