import React from "react"
import { useForm } from "react-hook-form"
import styled from "@emotion/styled"
import { useToggle } from "kui-utils"
import { Modal } from "kui-crm"
import { UploadFilesModalProps } from "./types"
import UploadFiles from "../../common/UploadFiles"
import { UploadFileFields } from "../../../types/common"
import FileLoader from "../../common/FileLoader"

function UploadFilesModal({
  handleSubmit,
  submitTitle,
  handleClose,
  required,
  name,
  filesLeftCount,
  reserveMessage,
  variant,
  ...otherProps
}: UploadFilesModalProps) {
  const [isLoading, startLoading, endLoading] = useToggle()
  const form = useForm<UploadFileFields>({
    defaultValues: {
      files: [],
    },
  })
  const filesLength = form.getValues("files").length
  const filesCount =
    typeof filesLeftCount === "number" ? filesLeftCount : filesLength

  const onSubmit = async (data: UploadFileFields) => {
    handleClose()
    if (reserveMessage) {
      startLoading()
      await handleSubmit(data)
      endLoading()
    } else {
      await handleSubmit(data)
    }
    form.reset()
  }

  return (
    <>
      <StyledModal
        data-testid="upload_files_modal"
        isScrollable
        handleClose={handleClose}
        {...otherProps}
      >
        <form
          data-testid="upload_files_form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <UploadFiles
            required={required}
            submitTitle={submitTitle}
            form={form}
            name={name}
            variant={variant}
          />
        </form>
      </StyledModal>
      {isLoading && (
        <FileLoader
          filesCount={Number(filesCount)}
          reserveMessage={reserveMessage}
        />
      )}
    </>
  )
}

UploadFilesModal.defaultProps = {
  title: "Upload documents",
  required: true,
}

export default UploadFilesModal

const StyledModal = styled(Modal)`
  max-height: 80vh !important;
`
