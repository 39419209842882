import * as React from "react"
import { ReactNode } from "react"
import styled from "@emotion/styled"
import { Caption, Button, Grid } from "kui-basic"
import { PlusIcon } from "kui-icon"

const Wrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 8px;
`

type CaptionWithButtonProps = {
  title: string
  onClick: () => void
  isEditing?: boolean
  icon?: ReactNode
  children?: ReactNode
}

function CaptionWithButton({
  title,
  onClick,
  isEditing,
  icon,
  children,
}: CaptionWithButtonProps) {
  return (
    <Wrapper>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Caption size="xs" weight={600}>
            {title?.toUpperCase()}
          </Caption>
        </Grid>
        <Grid item>
          <Grid container justify="flex-end" alignContent="center">
            <Grid item>{children}</Grid>
            <Grid item>
              <Button
                isCircle
                data-testid="add_field_button"
                disabled={!isEditing}
                size="xs"
                variant="whiteWithGray"
                onClick={onClick}
              >
                {icon}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

CaptionWithButton.defaultProps = {
  isEditing: true,
  icon: <PlusIcon width={10} height={10} />,
  children: null,
}

export default CaptionWithButton
