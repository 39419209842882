/** @jsxImportSource @emotion/react */
import * as React from "react"
import _ from "lodash"
import styled from "@emotion/styled"
import { Caption, Heading, Box, Grid } from "kui-basic"
import { AboutPageHeaderProps, InfoParams } from "./types"
import TwoCaptionsBlock from "../TwoCaptionsBlock"
import Container from "../../ui/Container"

const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.background.light1};
`

function AboutPageHeader({
  icon,
  title,
  subtitle,
  info,
  status,
  className,
}: AboutPageHeaderProps) {
  return (
    <Wrapper className={className}>
      <Container>
        <Box pt={2} pb={2} className="PageHeaderWrapper">
          <Grid container alignItems="center" justify="space-between">
            <Grid item sm={6} xs={12}>
              <Grid container>
                {icon && (
                  <Grid item xs={2}>
                    <Box mr={3}>{icon}</Box>
                  </Grid>
                )}
                <Grid item xs={icon ? 10 : 12}>
                  <Grid
                    css={{ height: "100%" }}
                    container
                    direction="column"
                    justify="center"
                  >
                    <Grid container wrap="nowrap">
                      <Heading size="h2">{title}</Heading>
                      {status}
                    </Grid>
                    <Caption weight={500} color="fiftyP">
                      {subtitle}
                    </Caption>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container sm={6} xs={12} justify="flex-end">
              {info.map((item: InfoParams) => (
                <Grid item key={_.uniqueId()} className="PageHeaderInfoItem">
                  <Box mr={6}>
                    <TwoCaptionsBlock
                      top={item.title || "-"}
                      bottom={item.subtitle}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Wrapper>
  )
}

export default AboutPageHeader
