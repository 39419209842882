import React from "react"
import { ElectricityArchiveMeterRowProps } from "./types"
import ComplexArchiveMeterRow from "../ComplexArchiveMeterRow"
import ArchiveMeterRow from "../ArchiveMeterRow"
import ArchiveCounterTariffRow from "../ArchiveMeterTariffRow"
import useExpensesStore from "../../../../store"

function ElectricityArchiveMeterRow({
  counter,
}: ElectricityArchiveMeterRowProps) {
  const { periodStore } = useExpensesStore()
  const disabled = !periodStore.canBeEdited

  return counter.type === "T1" ? (
    <ComplexArchiveMeterRow
      counter={counter}
      tariff={counter.t1}
      disabled={disabled}
    />
  ) : (
    <>
      <ArchiveMeterRow counter={counter} disabled={disabled} />
      <ArchiveCounterTariffRow tariff={counter.t1} />
      <ArchiveCounterTariffRow
        isLast={counter.type === "T2"}
        tariff={counter.t2}
      />
      {counter.type === "T3" && (
        <ArchiveCounterTariffRow isLast tariff={counter.t3} />
      )}
    </>
  )
}

export default ElectricityArchiveMeterRow
