import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Button } from "kui-basic"
import { TrashIcon } from "kui-icon"
import { ApartmentPhotoSliderHeaderProps } from "./types"
import SettingCheckbox from "../../../../../../../../../components/ui/SettingCheckbox"
import usePromotionStore from "../../../../../store"

function ApartmentPhotoSliderHeader(props: ApartmentPhotoSliderHeaderProps) {
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()
  const { currentImage, slider, handleClose, images } = props

  useEffect(() => {
    if (images.length === 0) {
      handleClose()
    }
  }, [images])

  const handlePreview = (checked: boolean) => {
    if (checked) {
      currentImage.makePreview()
    } else {
      currentImage.makeNotPreview()
    }
  }

  const handlePublic = (checked: boolean) => {
    if (checked) {
      currentImage.makePublic()
    } else {
      currentImage.makeNotPublic()
    }
  }

  const handleDelete = async () => {
    const swiper = slider.current?.swiper
    await currentImage.delete()
    if (swiper?.realIndex !== 0) {
      swiper?.slidePrev()
    }
  }

  return (
    <React.Fragment key={currentImage?.id}>
      <Button
        size="s"
        variant="transparentWithBorder"
        endIcon={<TrashIcon />}
        onClick={handleDelete}
      >
        Delete
      </Button>
      {(!imagesStore.previewExceededLimit || currentImage?.isPreview) && (
        <StyledCheckbox
          title="Preview"
          checked={currentImage?.isPreview}
          handleChange={handlePreview}
        />
      )}
      <StyledCheckbox
        title="Public"
        checked={currentImage?.isPublic}
        handleChange={handlePublic}
      />
    </React.Fragment>
  )
}

export default observer(ApartmentPhotoSliderHeader)

const StyledCheckbox = styled(SettingCheckbox)`
  margin-right: 14px;
  &:first-of-type {
    margin-left: 14px;
  }
`
