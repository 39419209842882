import React, { useState } from "react"
import { observer } from "mobx-react"
import { useFieldArray } from "react-hook-form"
import styled from "@emotion/styled"
import { Button, Box, Grid } from "kui-basic"
import { HeadingWithButton, LoaderPopup } from "kui-crm"
import { UpdateIcon } from "kui-icon"
import { GeolocationParams } from "kui-crm/types"
import MetroItem from "../MetroItem"
import useStore from "../../../../../hooks/useStore"
import { MetroBlockProps } from "../ApartmentDescriptionForm/types"

function MetroBlock({ form, isEditing, coords }: MetroBlockProps) {
  const [isLoading, setIsLoading] = useState(false)
  const {
    mapStore: { map },
    metroStore: { findNearbyStations, setError },
  } = useStore()
  const { fields, append, remove, update, replace } = useFieldArray({
    control: form.control,
    name: "metroStations",
  })

  const updateMetroStations = async () => {
    if (map && coords) {
      setIsLoading(true)
      const nearbyStations = await findNearbyStations(
        map,
        coords,
        fields.length
      )
      replace(nearbyStations)
      setIsLoading(false)
    } else {
      setError("No map found")
    }
  }

  const addMetro = () => {
    append({
      id: null,
      name: "",
      walkDistance: "",
      walkTime: "",
      carDistance: "",
      carTime: "",
    })
  }

  return (
    <>
      <StyledHeadingWithButton
        title="Metro"
        onClick={addMetro}
        isEditing={isEditing}
      >
        <Box mr={1}>
          <Button
            isCircle
            disabled={!isEditing}
            size="xs"
            variant="whiteWithGray"
            onClick={updateMetroStations}
          >
            <UpdateIcon />
          </Button>
        </Box>
      </StyledHeadingWithButton>
      <Grid container spacing={3}>
        {fields.map((item, index) => (
          <MetroItem
            index={index}
            key={fields[index].id}
            form={form}
            isEditing={isEditing}
            onDelete={remove}
            apartmentCoords={coords as GeolocationParams}
            onChange={update}
          />
        ))}
      </Grid>
      {isLoading && <LoaderPopup label="metro changes" />}
    </>
  )
}

export default observer(MetroBlock)

const StyledHeadingWithButton = styled(HeadingWithButton)`
  padding-top: 0;
`
