import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Modal, FormWrapper, InputFileWithVisibility } from "kui-crm"
import { PaymentOrderFields, PaymentOrderModalProps } from "./types"
import PaymentOrderSchema from "./schema"

const PaymentOrderModal = (props: PaymentOrderModalProps) => {
  const { open, handleClose, service } = props
  const form = useForm<PaymentOrderFields>({
    resolver: yupResolver(PaymentOrderSchema),
  })

  const handleSubmit = (data: PaymentOrderFields) => {
    service.addPaymentOrder(data.paymentOrder)
    handleClose()
  }

  return (
    <Modal title="Add payment order" open={open} handleClose={handleClose}>
      <FormWrapper form={form} onSubmit={handleSubmit} label="Add">
        <InputFileWithVisibility
          label="Payment order"
          name="paymentOrder"
          form={form}
        />
      </FormWrapper>
    </Modal>
  )
}

export default PaymentOrderModal
