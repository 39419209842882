import React from "react"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Grid, GridSizing, Divider } from "kui-basic"
import { InputWithController } from "kui-complex"
import { FormBottom } from "kui-crm"
import RentalContractTermsFormSchema from "./schema"
import FieldsGroupTitle from "../../../../components/common/FieldsGroupTitle"
import { conditionsFields, terminationFields } from "./fields"
import useRCsStore from "../../store"
import SCCommonFields from "../../components/RCCommonFields"
import { CreateRentalContractFormProps } from "../CreateRentalContractForm/types"

function RCTermsForm({ handleSubmit }: CreateRentalContractFormProps) {
  const { creationForm } = useRCsStore()
  const { fields, prevStep } = creationForm

  const form = useForm({
    resolver: yupResolver(RentalContractTermsFormSchema),
    defaultValues: {
      ...fields,
      initialCommission: fields?.initialCommission ?? 50,
      securityPayment: fields?.securityPayment ?? 100,
      installment: fields?.installment ?? 1,
      paymentDate: fields?.paymentDate ?? 15,
      showing: fields?.showing ?? 14,
      penalty: fields?.penalty ?? 50,
      notificationPeriod: fields?.notificationPeriod ?? 30,
    },
  })

  return (
    <form data-testid="form_step_3" onSubmit={form.handleSubmit(handleSubmit)}>
      <SCCommonFields form={form} />

      <Box pl={5} pr={5} mb={2}>
        <FieldsGroupTitle title="Terms and conditions" />

        <Grid container spacing={2}>
          {conditionsFields.map(({ size, ...props }) => (
            <Grid key={props.name} item xs={(size || 6) as GridSizing}>
              <InputWithController
                data-testid={props.name}
                form={form}
                {...props}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Divider />

      <Box pr={5} pl={5} mb={3}>
        <FieldsGroupTitle title="Termination terms" />

        <Grid container spacing={2}>
          {terminationFields.map((props) => (
            <Grid key={props.name} item xs={6}>
              <InputWithController
                data-testid={props.name}
                form={form}
                {...props}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <FormBottom
        withPrevStep
        label="Create contract"
        handlePrevClick={prevStep}
      />
    </form>
  )
}

export default observer(RCTermsForm)
