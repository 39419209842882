import React from "react"
import { useFieldArray } from "react-hook-form"
import { Grid } from "kui-basic"
import { FixedCostsBlockProps } from "./types"
import FixedCostItem from "../FixedCostItem"
import CaptionWithButton from "../../../common/CaptionWithButton"

function FixedCostsBlock({ form, ...otherProps }: FixedCostsBlockProps) {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "fixedCosts" as const,
  })

  const addFixedCost = () => {
    append({ cost: 0, payer: "landlord", name: "" })
  }

  return (
    <>
      <CaptionWithButton title="Fixed costs" onClick={addFixedCost} />
      <Grid container spacing={2} alignItems="center">
        {fields.map((field, key) => (
          <FixedCostItem
            key={field.id}
            index={key}
            form={form}
            onDelete={remove}
            {...otherProps}
          />
        ))}
      </Grid>
    </>
  )
}

export default FixedCostsBlock
