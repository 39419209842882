import React, { useEffect, useState } from "react"
import { Path, PathValue, useFieldArray } from "react-hook-form"
import { FieldValues } from "react-hook-form/dist/types/fields"
import UploadFiles from "../UploadFiles"
import { UploadFilesWithSettingProps } from "./types"
import DocumentSettings from "../DocumentSettings"

function UploadFilesWithSetting<T extends FieldValues>(
  props: UploadFilesWithSettingProps<T>
) {
  const { contractType, form, ...otherProps } = props
  const [selectedFile, setSelectedFile] = useState<number | null>(null)
  const [settingsLength, setSettingsLength] = useState(0)
  const { append, remove } = useFieldArray<any>({
    control: form.control,
    name: "settings",
  })

  const defaultSettings = {
    landlord: false,
    tenant: false,
    contractType,
  }

  const addFiles = (files: any[]) => {
    files.forEach((_, index) => {
      const value = form.getValues(
        `settings.${index + settingsLength}` as Path<T>
      )
      if (value) {
        form.setValue(
          `settings.${index + settingsLength}` as Path<T>,
          defaultSettings as PathValue<T, any>
        )
      } else {
        append(defaultSettings)
      }
      setSettingsLength((prev) => prev + 1)
    })
  }

  const removeFile = (index: number) => {
    setSettingsLength((prev) => prev - 1)
    remove(index)
  }

  useEffect(() => {
    form.setValue(
      "globalSettings" as Path<T>,
      defaultSettings as PathValue<T, any>
    )
  }, [])

  return (
    <UploadFiles
      form={form}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      onAdd={addFiles}
      onRemove={removeFile}
      settings={
        <DocumentSettings
          selected={selectedFile}
          contractType={contractType}
          form={form}
        />
      }
      {...otherProps}
    />
  )
}

export default UploadFilesWithSetting
