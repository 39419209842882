import AuthStore from "./common/Auth"
import MetroStore from "./common/Metro"
import AppStore from "./common/AppStore"
import MapStore from "./common/Map"
import CabinetStore from "./common/Cabinet"

class RootStore {
  authStore: AuthStore

  metroStore: MetroStore

  appStore: AppStore

  mapStore: MapStore

  cabinetStore: CabinetStore

  constructor() {
    this.authStore = new AuthStore(this)
    this.metroStore = new MetroStore()
    this.appStore = new AppStore(this)
    this.mapStore = new MapStore(this)
    this.cabinetStore = new CabinetStore(this)
  }

  reset = () => {
    this.authStore.reset()
    this.metroStore.reset()
    this.appStore.reset()
    this.mapStore.reset()
  }
}

export default RootStore
