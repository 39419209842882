/** @jsxImportSource @emotion/react */
import React from "react"
import { Box } from "kui-basic"
import _ from "lodash"
import styled from "@emotion/styled"
import { css, Theme, useTheme } from "@emotion/react"

const Line = styled.hr`
  height: 1px;
  border: none;
  margin: 0;
  background: ${({ theme }) => theme.palette.grey.fifteenB};
`

const StyledDotsContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  border-radius: 66px;
  padding: 5px;
  background: ${({ theme }) => theme.palette.grey.zero};
  width: fit-content;
  position: absolute;
  display: flex;
  top: -9px;
  left: 45%;
`

const dotStyles = (theme: Theme) => css`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${theme.palette.grey.fifteenB};
  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  },
`

const activeStyles = (theme: Theme) => css`
  background: ${theme.palette.brand.main};
`

type DotsContainerProps = {
  active: number
  dotsCount: number
}

function DotsContainer({ active, dotsCount }: DotsContainerProps) {
  const theme = useTheme()

  return (
    <Box css={{ position: "relative" }} mt={3}>
      <Line />
      <StyledDotsContainer>
        {new Array(dotsCount).fill(1).map((item, key) => (
          <span
            css={[dotStyles(theme), key + 1 === active && activeStyles(theme)]}
            key={_.uniqueId()}
          />
        ))}
      </StyledDotsContainer>
    </Box>
  )
}

export default DotsContainer
