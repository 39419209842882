import React from "react"
import { observer } from "mobx-react"
import { Button, Grid } from "kui-basic"
import useExpensesStore from "../../../../store"
import ApartmentSummaryStore from "../../../../store/summary/ApartmentSummary"

function DownloadReportButton() {
  const { overviewStore, date } = useExpensesStore()

  const handlePDFOpen = async () => {
    if (overviewStore.id && date) {
      const agentReport = ApartmentSummaryStore.getPreviewAgentReportLink(
        overviewStore.id,
        date
      )
      if (agentReport) {
        window.open(agentReport, "_blank")
      }
    }
  }

  return (
    <Grid item>
      <Button variant="white" size="s" onClick={handlePDFOpen}>
        Agent report
      </Button>
    </Grid>
  )
}

export default observer(DownloadReportButton)
