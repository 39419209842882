import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import TableRow from "../../../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../../../components/ui/TableCell"
import EditMetersData from "../../../../forms/meters/EditMetersData"
import { CounterTariffRowProps } from "./types"
import TableRowDivider from "../../../../../../../../components/common/TableRowDivider"
import useExpensesStore from "../../../../store"

function CounterTariffRow(props: CounterTariffRowProps) {
  const { tariff, disabled, isLast } = props
  const { periodStore } = useExpensesStore()

  const handleSubmit = (value: string) => {
    if (periodStore.id) {
      tariff.postMeterValue(value, periodStore.id)
    }
  }

  if (!tariff) {
    return null
  }

  return (
    <>
      <TableRow data-testid="counter_tariff_row">
        <StyledTitleCell data-testid="counter_title" align="right">
          {tariff.title}
        </StyledTitleCell>
        <TableCell colSpan={3} />
        <TableCell data-testid="prev_month_value">
          {tariff.prevMonthValue}
        </TableCell>
        <TableCell colSpan={2}>
          <EditMetersData
            type={tariff.title}
            isLoading={tariff.loader.isLoading}
            handleSubmit={handleSubmit}
            value={Number(tariff.value)}
            date={tariff.editingDate?.toFormat("dd.MM.yy")}
            disabled={disabled}
          />
        </TableCell>
        <TableCell data-testid="consumption">
          {tariff.consumption.toLocaleString()}
        </TableCell>
        <TableCell data-testid="tariff_value">
          {tariff.tariffValue?.toLocaleString()} ₽
        </TableCell>
        <TableCell data-testid="total_price">
          {tariff.totalPrice ? `${tariff.totalPrice.toLocaleString()} ₽` : "-"}
        </TableCell>
        <TableCell />
      </TableRow>
      {isLast && <TableRowDivider />}
    </>
  )
}

export default observer(CounterTariffRow)

const StyledTitleCell = styled(TableCell)`
  padding-right: 42px;
`
