import React, { useEffect, useRef } from "react"
import { observer } from "mobx-react"
import InputWithAutocomplete from "../../../ui/InputWithAutocomplete"
import AutocompleteOption from "../../../common/AutocompleteOption"
import ApartmentServiceStore from "../../../../store/shared/apartmentService/ApartmentService"
import ApartmentServicesListStore from "../../../../store/lites/ApartmentServicesList"
import { PrevServicesListProps } from "./types"

const PrevServicesList = (props: PrevServicesListProps) => {
  const { formStore, apartmentId, periodId, form } = props
  const servicesStoreRef = useRef(new ApartmentServicesListStore())
  const { filteredServices, loader, filter, fetchServices } =
    servicesStoreRef.current

  const onSelectItem = (item: ApartmentServiceStore | null) => {
    if (item) {
      // update first step fields
      form.setValue("name", item.title)
      form.setValue("purpose", item.purpose)
      form.setValue("documentNumber", item.documentNumber)
      form.setValue("durationType", item.durationType)
      form.setValue("prevService.title", item.title)
      if (item.company) {
        form.setValue("company.id", item.company.id)
        form.setValue("company.name", item.company.name)
      }

      // update second step fields
      formStore.updateFormFields({
        paymentMadeBy: item?.paymentMadeBy?.role,
        refundFrom: item?.refundFrom?.role,
        paymentType: item?.paymentType,
        comment: item?.comment,
      })
    }
  }

  const onSearchItem = (value: string) => {
    filter.updateSearchQuery(value)
  }

  useEffect(() => {
    if (apartmentId && periodId) fetchServices(apartmentId, periodId)
  }, [apartmentId, periodId])

  return (
    <InputWithAutocomplete
      renderOption={(option: ApartmentServiceStore) => (
        <AutocompleteOption label={option.title} />
      )}
      options={filteredServices}
      label="Previous period expense name"
      form={form}
      name="prevService.title"
      hideName="prevService.id"
      isLoading={loader.isLoading}
      onSelectItem={onSelectItem}
      onSearchItem={onSearchItem}
    />
  )
}

export default observer(PrevServicesList)
