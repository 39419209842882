import React, { DragEvent, useState } from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { ColorFolderIcon } from "kui-icon"
import { maxWidth } from "../../../../utils/service/theme"
import { LabelStylesProps, UploadFilesLabelProps } from "./types"
import { imageExtensions } from "../../../../utils/content/constants"

const filesLabels = {
  document: {
    desktop: "Drag & drop your files here",
    mobile: "Select a file",
    formats: "files in formats: doc, docx, xls, xlsx, pdf, jpg, png",
  },
  image: {
    desktop: "Drag & drop photos here",
    mobile: "Select a photos",
    formats: `in formats: ${imageExtensions.join(", ")}`,
  },
}

function UploadFilesLabel(props: UploadFilesLabelProps) {
  const { handleChange, variant } = props
  const [isHighlight, setIsHighlight] = useState(false)

  const cleanDefaultActions = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const highlight = (e: DragEvent<HTMLLabelElement>) => {
    cleanDefaultActions(e)
    setIsHighlight(true)
  }

  const unHighlight = (e: DragEvent<HTMLLabelElement>) => {
    cleanDefaultActions(e)
    setIsHighlight(false)
  }

  const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
    unHighlight(e)
    const { files } = e.dataTransfer
    // @ts-ignore
    handleChange({ target: { files } })
  }

  return (
    <StyledLabel
      data-testid="input_file_label"
      htmlFor="file_input"
      onDrop={handleDrop}
      onDragOver={highlight}
      // onDragEnter={cleanDefaultActions}
      onDragLeave={unHighlight}
      isHighlight={isHighlight}
    >
      <IconWrapper>
        <ColorFolderIcon />
      </IconWrapper>
      <DesktopLabel weight={700}>
        {filesLabels[variant || "document"].desktop}
      </DesktopLabel>
      <MobileLabel weight={700}>
        {filesLabels[variant || "document"].mobile}
      </MobileLabel>
      <StyledTitle size="xs" color="fourty">
        {filesLabels[variant || "document"].formats}
      </StyledTitle>
    </StyledLabel>
  )
}

export default UploadFilesLabel

const StyledLabel = styled.label<LabelStylesProps>`
  width: 100%;
  border-radius: 12px;
  border: 1px dashed
    ${({ theme, isHighlight }) =>
      isHighlight ? theme.palette.brand.main : theme.palette.grey.fifteenB};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px 16px;
  text-align: center;
  cursor: pointer;
  background: ${({ theme, isHighlight }) =>
    isHighlight
      ? theme.palette.background.light3
      : theme.palette.background.light1};
  transition: all ease-out 0.3s;
  ${maxWidth("xs")} {
    padding: 27px 16px;
  }
`

const IconWrapper = styled.div`
  margin-bottom: 26px;
  ${maxWidth("xs")} {
    margin-bottom: 16px;
  }
`

const DesktopLabel = styled(Caption)`
  margin-bottom: 4px;
  ${maxWidth("sm")} {
    display: none;
  }
`

const MobileLabel = styled(Caption)`
  margin-bottom: 4px;
  @media (min-width: 901px) {
    display: none;
  }
`

const StyledTitle = styled(Caption)`
  max-width: 202px;
`
