import * as React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { Grid, Button } from "kui-basic"
import { InputWithController } from "kui-complex"
import { useRef } from "react"
import { CheckIcon } from "kui-icon"
import { NoteFields, NoteFormProps } from "./types"
import NoteFormSchema from "./schema"

function NoteForm({ handleSubmit, defaultValues, variant }: NoteFormProps) {
  const form = useForm<NoteFields>({
    defaultValues,
    resolver: yupResolver(NoteFormSchema),
  })
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onSubmit = async (data: NoteFields) => {
    await handleSubmit(data)
    form.reset()
    if (inputRef.current) {
      inputRef.current.value = ""
    }
  }

  return (
    <form data-testid="form_note" onSubmit={form.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputWithController
            ref={inputRef}
            data-testid="note_input"
            isTextArea
            name="text"
            label="Comment"
            form={form}
            autoHeight
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify={variant === "edit" ? "flex-end" : "flex-start"}
        >
          <Button
            data-testid="submit_button_note"
            size={variant === "edit" ? "s" : "xs"}
            type="submit"
            variant={variant === "edit" ? "primary" : "orange"}
            endIcon={variant === "edit" ? <CheckIcon /> : undefined}
          >
            {variant === "edit" ? "Save" : "Create"}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
export default NoteForm
