import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import { DateTime } from "luxon"
import styled from "@emotion/styled"
import EditButton from "../../../../components/ui/EditButton"
import useSCStore from "../../store"
import { SCFieldsHeaderProps } from "./types"
import { SCInfoFormFields } from "../SCInfoFields/types"
import EditContractModal from "../../../../components/modals/EditContractModal"
import SCClosingButtons from "../SCClosingButtons"

const SCFieldsHeader = ({ form }: SCFieldsHeaderProps) => {
  const { editor, patchServiceContract, usedPendingDates, contractInfoStore } =
    useSCStore()
  const [isModalOpen, handleOpen, handleClose] = useToggle()

  const handleSubmit = (data: SCInfoFormFields) => {
    patchServiceContract(data)
    editor.endEditing()
    form.reset()
  }

  if (contractInfoStore.isClosed) {
    return null
  }

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <SCClosingButtons />

        <StyledEditButtonWrapper>
          <EditButton
            isEditing={editor.isEditing}
            onSaveHandler={form.handleSubmit(handleSubmit)}
            onEditHandler={handleOpen}
          />
        </StyledEditButtonWrapper>
      </Grid>

      <EditContractModal
        open={isModalOpen}
        handleClose={handleClose}
        editor={editor}
        form={form}
        usedDates={usedPendingDates}
        lastOpenPeriod={DateTime.now()}
      />
    </>
  )
}

export default observer(SCFieldsHeader)

const StyledEditButtonWrapper = styled(Grid)`
  margin-left: auto;
`
