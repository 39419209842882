import React from "react"
import { useForm } from "react-hook-form"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { FormWrapper, InputByType } from "kui-crm"
import clientPassportFilesFields from "./fields"
import useClientStore from "../../store"
import {
  ClientPassportFilesFieldsProps,
  ClientPassportFilesFormFields,
} from "./types"

const ClientPassportFilesFields = (props: ClientPassportFilesFieldsProps) => {
  const { handleSubmit, handleClose } = props
  const { personalInfoStore } = useClientStore()
  const form = useForm<ClientPassportFilesFormFields>()

  const handleFormSubmit = async (data: ClientPassportFilesFormFields) => {
    await personalInfoStore.parsePassport(data)
    handleSubmit(data)
    handleClose()
  }

  return (
    <FormWrapper form={form} onSubmit={handleFormSubmit} label="Start editing">
      <Grid container spacing={2}>
        {clientPassportFilesFields.map((field) => (
          <Grid item key={field.name} xs={12}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(ClientPassportFilesFields)
