import React from "react"
import { Caption } from "kui-basic"
import styled from "@emotion/styled"
import { InspectionFeedbackCommentProps } from "./types"

const InspectionFeedbackComment = (props: InspectionFeedbackCommentProps) => {
  const { feedback, labels } = props

  if (feedback.status === "rejected") {
    return (
      <div>
        <Caption size="xs">{labels.rejected}</Caption>
        <StyledComment size="xs">
          <StyledAccent>Comment:</StyledAccent> {feedback.comment || "-"}
        </StyledComment>
      </div>
    )
  }

  return <Caption size="xs">{labels[feedback.status]}</Caption>
}

export default InspectionFeedbackComment

const StyledComment = styled(Caption)`
  margin-top: 8px;
`

const StyledAccent = styled.span`
  color: ${({ theme }) => theme.palette.grey.fiftyP};
`
